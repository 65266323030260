import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import CustomDropdown from '../common/customDropdown';
import trashIcon from "../../assets/img/page/trash.svg";
import plusIcon from "../../assets/img/thin-plus-icon.svg";
import ImageCropper from '../croppie-main';
import Socials from './socials';
const Options = ({ handleFormChanges, showSaveChanges, setIframeURL, setIframeIndex }) => {
    const value = useSelector(state => state?.customizeSeoReducer);
    const [seoformState, setSeoFormState] = useState({ ...value });
    const [showCroppieModal, setShowCroppieModal] = useState({ show: false });
    const [localValue, setLocalValue] = useState({
        page_title: value?.page_title || "",
        page_description: value?.page_description || "",
    });

    const dispatch = useDispatch();
    const deleteImage = () => dispatch({ type: "SET_CUSTOMIZE_SEO", payload: { ...value, "image": null } })

    const handleChange = (e) => {
        const { name, value, checked } = e.target;
        const maxLengths = {
            page_title: 50,
            page_description: 225,
        };
        if (maxLengths[name] && value.length > maxLengths[name]) return;
        setLocalValue((prev) => ({ ...prev, [name]: value }));
        if (name === "block_indexing") {
            dispatch({ type: "SET_CUSTOMIZE_SEO", payload: { ...value, [name]: checked ? 1 : 0 } });
        } else {
            dispatch({ type: "SET_CUSTOMIZE_SEO", payload: { ...value, [name]: value } });
        }
    };
    useEffect(() => {
        handleFormChanges('seoValue', value, seoformState)
    }, [value]);
    useEffect(() => {
        if (!showSaveChanges?.show) {
            setSeoFormState({ ...value });
        }
    }, [showSaveChanges?.show])
    const handleDropdownChange = (val, key) => {
        dispatch({ type: "SET_CUSTOMIZE_SEO", payload: { ...value, [key]: val } })
    };
    const handleImageCropped = ({ blob, imageUrl }) => {
        dispatch({ type: "SET_CUSTOMIZE_SEO", payload: { ...value, "image": imageUrl, "imageData": blob } });
    }
    const showCroppie = (event) => {
        setShowCroppieModal({ show: true, event: event });
    };
    return (
        <div className='blocks-content new-page-editor-section scrollbar-on-hover'>
            <div className='page-editor-heading'>
                SEO
            </div>
            <div className='d-flex gap-30px flex-column'>
                <div className='details-form-group'>
                    <div className='first-half'>
                        <div className='details-inp-label'>Title</div>
                        <div className='details-inp-desc'>The title of your page.</div>
                    </div>
                    <div className='second-half'>
                        <div className='project-new-inp'>
                            <textarea
                                className='transparent-textarea p-0'
                                name="page_title"
                                onChange={handleChange}
                                placeholder='Use a title that your audience will instantly recognise.'
                                value={localValue?.page_title}
                                maxLength={50}
                            />
                            <div className='fs-10px text-end'>
                                <span>{localValue?.page_title?.length || 0}</span>
                                <span className='text-grey'>/50</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='details-form-group'>
                    <div className='first-half'>
                        <div className='details-inp-label'>Description</div>
                        <div className='details-inp-desc'>Give a short description for your page.</div>
                    </div>
                    <div className='second-half'>
                        <div className='project-new-inp'>
                            <textarea
                                className='transparent-textarea p-0'
                                name="page_description"
                                onChange={handleChange}
                                placeholder='Use a description that your audience will instantly recognise, like your company description.'
                                value={localValue?.page_description}
                                maxLength={225}
                            />
                            <div className='fs-10px text-end'>
                                <span>{localValue?.page_description?.length || 0}</span>
                                <span className='text-grey'>/225</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='details-form-group'>
                    <div className='first-half'>
                        <div className='details-inp-label'>Search Engine Indexing</div>
                        <div className='details-inp-desc'>Choose if you want search engines to index your page.</div>
                    </div>
                    <div className='second-half'>
                        <CustomDropdown onSelect={handleDropdownChange} valueKey="value" reducerKey="block_indexing" dropdownLabel="label" options={[{ label: 'Enabled', value: 1 }, { label: 'Disabled', value: 0 }]} defaultOption={value?.block_indexing == 1 ? 'Enabled' : 'Disabled'} />
                    </div>
                </div>
                <div>
                    <div className='details-inp-label'>
                        Open Graph Image
                    </div>
                    {value?.image ?
                        <div className='mt-20px d-flex justify-content-start gap-20px align-items-center'>
                            <img className="design-banner-image" src={value?.image} alt="" />
                            <div className='d-flex align-items-center gap-10px'>
                                <label htmlFor="img_logo" className="cursor-pointer upload--profile-btn borderless position-relative">
                                    <img className='filter-invert-1 cursor-pointer' width={12} src={plusIcon} alt="" />
                                    Upload Image
                                    <input id="img_logo" name='img_logo' type="file" onChange={showCroppie} className='d-none' />
                                </label>
                                <button type='button' onClick={deleteImage} className="upload--profile-btn borderless">
                                    <img src={trashIcon} width={15} alt="" />
                                </button>
                            </div>
                        </div>
                        :
                        <label htmlFor='img_logo' className='upload-banner cursor-pointer'>
                            <input id='img_logo' type="file" accept=".png,.jpg,.jpeg" name="img_logo"
                                className={`d-none`} onChange={showCroppie} />
                            <div>
                                Upload Image
                            </div>
                            <button type='button' className="upload--profile-btn borderless">
                                <img className='filter-invert-1' width={12} src={plusIcon} alt="" />
                            </button>
                        </label>
                    }
                </div>

            </div>
            <div className="design-content-divider mb-1"></div>

            <Socials setIframeURL={setIframeURL} setIframeIndex={setIframeIndex} />
            <ImageCropper viewportType={"rectangle"} boundaryWidth={400} boundaryHeight={234} viewportWidth={380} viewportHeight={214} outputQuality={1} onImageCropped={handleImageCropped} showCroppieModal={showCroppieModal} setShowCroppieModal={setShowCroppieModal} />
        </div>
    )
}

export default Options