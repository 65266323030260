import React, { useEffect, useState } from 'react'
import DesignComponents from './design-components';
import { useDispatch, useSelector } from 'react-redux';
import CustomThemeModal from '../custom-theme/custom-theme-modal';
import plainLinkIcon from "../../assets/img/plain-link-icon.svg"
import recommendIcon from "../../assets/img/recommended-icon.svg"
import hideIcon from "../../assets/img/hide-icon.svg"
import checkIcon from "../../assets/img/new-check-icon.svg"
import trashIcon from "../../assets/img/page/trash.svg";
import plusIcon from "../../assets/img/thin-plus-icon.svg";
import { apiUrl } from '../../utils/url';
import { hideLoader, showLoader } from '../loader';
import { audiencePosts } from '../../api/audience';
import ImageCropper from '../croppie-main';
import { useUpgradeModal } from '../layout/dashboard-layout';
import { toast } from 'react-toastify';


const Design = ({ showSaveChanges, setShowSaveChanges, handleFormChanges }) => {
  const value = useSelector(state => state?.customizeThemeReducer);
  const [themeFormState, setThemeFormState] = useState({ ...value });
  const theme = useSelector(state => state?.allThemesReducer);
  const [openCustomTheme, setOpenCustomTheme] = useState(value?.selectedTheme == 'custom_theme' ? true : false)
  const [themeType, setThemeType] = useState(value?.theme == 'custom_theme' ? "custom" : "premade");
  const [showCroppieModal, setShowCroppieModal] = useState({ show: false });
  const { customThemeColorReducer, customizeProfileReducer } = useSelector(state => state)
  const pro = useSelector(state => state?.proReducer);
  const isEligible = useSelector(state => state?.eligibilityReducer);
  const planType = useSelector(state => state?.plantypeReducer);
  const fonts = useSelector(state => state?.allFontsReducer)
  const { slug, basePath } = useSelector(state => state?.slugReducer);
  const dispatch = useDispatch();
  const [images, setImages] = useState([])
  const [countChange, setCountChange] = useState(0);
  const { linkStyleSelected, bannerSelected } = useSelector(state => state);
  const { showUpgradeModal } = useUpgradeModal();
  const upgradeModalData = { from: 'customizePageModal', showModal: true, heading: 'Upgrade to access this Feature.', text: ' This is a Pro Feature<br /> Upgrade to Zaap Pro to unlock this feature.', isUltra: false, }

  const upgradeButton = (plan) => (
    <button type="button" className="theme-upgrade-main-btn" onClick={() => upgradePage(true, plan)}>
      Upgrade
    </button>
  );
  const upgradePage = (show, plan) => {
    if (show) {
      showUpgradeModal({ ...upgradeModalData, isUltra: plan == 'Ultra' })

    }
  };
  const handleThemeChange = (value) => {
    setThemeType(value);
    if (value == 'custom') {
      setOpenCustomTheme(true);
      handleChange(value, null, true);
    } else {
      setOpenCustomTheme(false);
    }
  };
  function handleChange(e, id, isCustom) {
    if (isCustom) {
      const updatedValue = {
        ...value,
        theme: "custom_theme",
        selectedTheme: "custom_theme"
      };

      if (!value?.custom_theme) {
        const defaultCustomTheme = {
          header: "#ffffff",
          background: "#ffffff",
          links: "#ffffff",
          buttons: "#ffffff",
          button_text: "#ffffff"
        };
        updatedValue.custom_theme = JSON.stringify(defaultCustomTheme);
      }

      dispatch({
        type: "SET_CUSTOMIZE_THEME",
        payload: updatedValue
      });
      setOpenCustomTheme(true)
      // changeDelay();
    }
    else {
      if (id) {
        dispatch({ type: "SET_CUSTOMIZE_THEME", payload: { ...value, ["theme"]: e.target.dataset?.value, selectedTheme: id } })
      } else {
        dispatch({ type: "SET_CUSTOMIZE_THEME", payload: { ...value, [e.target.name]: e.target.value } })
      }
      // changeDelay();
    }
  }
  const handleDropdownChange = (val, key) => {
    dispatch({ type: "SET_CUSTOMIZE_THEME", payload: { ...value, [key]: val } })
  };
  const bannerShow = () => dispatch({ type: "SET_BANNER_MODAL", payload: { from: "banners", show: true } })

  const removeBanner = () => {
    dispatch({ type: "SET_BANNER_SELECTED", payload: "regular" })
    dispatch({ type: "SET_CUSTOMIZE_THEME", payload: { ...value, profile_style: "regular" } })
  }
  const getBanners = () => {
    const api = apiUrl + `/project/banners/get/` + slug;
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
    const setOptions = {
      method: "GET",
      headers: myHeaders,
    }
    audiencePosts(null, api, hideLoader, setOptions).then((response) => {
      setImages(response);
      return;
    }).catch((err) => console.log(err))
  };
  useEffect(() => {
    if (slug && images?.length === 0) {
      getBanners();
    }
  }, [])
  const AddedBanner = (banner_id, thumbnail) => {
    dispatch({ type: "SET_CUSTOMIZE_THEME", payload: { ...value, profile_style: "banner", editLink: thumbnail, banner: banner_id, selected: banner_id } })
  };
  const showCroppie = (event) => {
    setShowCroppieModal({ show: true, event: event });
  };
  const handleImageCropped = ({ blob, imageUrl }) => {
      uploadBannerImage(blob)
  };
  useEffect(() => {
    handleFormChanges('themeValue', value, themeFormState)
  }, [value]);
  useEffect(() => {
      if (!showSaveChanges?.show) {
        setThemeFormState({ ...value });
      }
  },[showSaveChanges?.show])
  const uploadBannerImage = (response) => {
    const api = apiUrl + `/project/banners/create`;
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
    const form_data = new FormData();

    form_data.append("project", slug)
    form_data.append("banner", response)
    const setOptions = {
      method: "POST",
      headers: myHeaders,
      body: form_data
    }
    audiencePosts(null, api, showLoader, setOptions,'custom_message').then((response) => {
      if (response?.status_code == 200) {
        AddedBanner(response?.data?.banner?.id,response?.data?.banner?.thumbnail)
        toast.success(response.status_message);
      }else{
        toast.error(response.status_message);
      }
      hideLoader()
    }).catch(() => {
      hideLoader()
    })
  };
  // console.log("theme value", value)
  return (
    <div className='design-section scrollbar-on-hover new-page-editor-section'>
      <DesignComponents dropDownOptions={[
        { label: 'Pre-made Themes', value: 'premade' },
        { label: 'Custom Theme', value: 'custom' },
      ]} defaultOption={value?.selectedTheme == 'custom_theme' ? 'Custom Theme' : 'Pre-made Themes'} handleSelect={handleThemeChange} desc={openCustomTheme ? 'Build your own theme that matches your brand.' : 'Choose a theme that is made by the Zaap team.'} label={'Profile Theme'} />
      {
        !openCustomTheme ?
          <div class="themes-selection">
            <div class="theme-main-right pt-1">
              {theme?.length > 0 &&
                theme?.map((val) => (
                  <div key={val.id} class={`h-100 w-100 mx-0 theme-selection-indiv ${val?.id == value?.selectedTheme ? 'selected-theme' : val.name?.replace(" ", "_")}`} data-value={val.id} onClick={(e) => handleChange(e, val?.id)}>
                    <span data-bg={`group${val.id}`} data-value={val.id}>
                      <img src={val?.image} data-value={val.id} />
                      <small className="text-left" data-value={val.id}>{val.name}</small>
                    </span>
                  </div>
                ))}


            </div>
          </div> :
          <div className=''>
            <CustomThemeModal setOpenCustomTheme={setOpenCustomTheme} openCustomTheme={openCustomTheme} openCustomThemeFrom={"customize"} />
          </div>
      }
      <div className="design-content-divider mb-1"></div>
      {/* Block Color */}
      <div className="design-options">
        <div className="first-half">
          <div className="theme-type-div-heading">Block Color</div>
          <div className="theme-type-div-des mt-10px">Auto detects the color from  thumbnail.</div>
        </div>
        <div className="d-flex align-items-center gap-2">
          <div className={`selected-theme-option ${value?.link_style === "gradient" ? 'active' : ''}`}>
            <label htmlFor="autoInput" className={`theme-type-selection `}>
              <input
                id="autoInput"
                type="radio"
                value="gradient"
                checked={value?.link_style === "gradient"}
                onChange={handleChange}
                className="hidden-radio-input"
                name="link_style"
              />
              <div>
                <img src={recommendIcon} alt="" />
              </div>
              <div>
                <div className="theme-type-div-heading">Auto</div>
                <div className="theme-type-div-des">Recommended</div>
              </div>
            </label>
          </div>
          <div className={`selected-theme-option ${value?.link_style === "plain" ? 'active' : ''}`}>
            <label htmlFor="plainInput" className={`custom-theme-type`}>
              <div className={`theme-type-selection ${value?.link_style === "plain" ? 'active' : ''}`}>
                <input
                  id="plainInput"
                  type="radio"
                  value="plain"
                  checked={value?.link_style === "plain"}
                  onChange={handleChange}
                  className="hidden-radio-input"
                  name="link_style"
                />
                <div>
                  <img src={plainLinkIcon} alt="" />
                </div>
                <div>
                  <div className="theme-type-div-heading">Plain</div>
                </div>
              </div>
            </label>
          </div>

        </div>
      </div>

      {/* Block Shadow */}
      <div className="design-options">
        <div className="first-half">
          <div className="theme-type-div-heading">Block Shadow</div>
          <div className="theme-type-div-des mt-10px">Choose between no shadow, soft shadow or hard shadow for links.</div>
        </div>
        <div className="d-flex align-items-center gap-2">
          <div className={`selected-theme-option ${value?.link_shadow === "none" ? 'active' : ''}`}>
            <label htmlFor="noneInput" className={`theme-type-selection ${value?.link_shadow === "none" ? 'active' : ''}`}>
              <input
                id="noneInput"
                type="radio"
                value="none"
                checked={value?.link_shadow === "none"}
                onChange={handleChange}
                className="hidden-radio-input"
                name="link_shadow"
              />
              <div>
                <div className="blue-bg"></div>
              </div>
              <div>
                <div className="theme-type-div-heading">None</div>
              </div>
            </label>
          </div>
          <div className={`selected-theme-option ${value?.link_shadow === "soft" ? 'active' : ''}`}>
            <label htmlFor="softInput" className={`custom-theme-type`}>
              <div className={`theme-type-selection ${value?.link_shadow === "soft" ? 'active' : ''}`}>
                <input
                  id="softInput"
                  type="radio"
                  value="soft"
                  checked={value?.link_shadow === "soft"}
                  onChange={handleChange}
                  className="hidden-radio-input"
                  name="link_shadow"
                />
                <div>
                  <div className="blue-bg">
                    <div className="soft"></div>
                  </div>
                </div>
                <div>
                  <div className="theme-type-div-heading">Soft</div>
                </div>
              </div>
            </label>
          </div>

          <div className={`selected-theme-option ${value?.link_shadow === "hard" ? 'active' : ''}`}>
            <label htmlFor="hardInput" className={`custom-theme-type`}>
              <div className={`theme-type-selection ${value?.link_shadow === "hard" ? 'active' : ''}`}>
                <input
                  id="hardInput"
                  type="radio"
                  value="hard"
                  checked={value?.link_shadow === "hard"}
                  onChange={handleChange}
                  className="hidden-radio-input"
                  name="link_shadow"
                />
                <div>
                  <div className="blue-bg">
                    <div className="hard"></div>
                  </div>
                </div>
                <div>
                  <div className="theme-type-div-heading">Hard</div>
                </div>
              </div>
            </label>
          </div>
        </div>
      </div>

      {/* Verified Check */}
      <div className="design-options" onClick={() => upgradePage(!pro, 'Pro')}>
        <div className="first-half">
          <div className="theme-type-div-heading">Verified Badge</div>
          <div className="theme-type-div-des mt-10px">Show a verified check next to your name.</div>
        </div>
        <div className="d-flex align-items-center gap-2">
          <div className={`selected-theme-option ${value?.verified_check == "false" ? 'active' : ''}`}>
            <label htmlFor="uncheckedInput" className={`theme-type-selection ${value?.verified_check == "false" || !pro ? 'active' : ''}`}>
              <input
                id="uncheckedInput"
                type="radio"
                value={false}
                checked={value?.verified_check == "false" || !pro}
                onChange={handleChange}
                className="hidden-radio-input"
                name="verified_check"
              />
              <div>
                <div className="blue-bg">
                  <img src={checkIcon} alt="" />
                </div>
              </div>
              <div>
                <div className="theme-type-div-heading">No Check</div>
                <div className="theme-type-div-des">Free</div>
              </div>
            </label>
          </div>
          <div className={`selected-theme-option ${value?.verified_check == "true" ? 'active' : ''}`}>
            <label htmlFor="checkedInput" className={`custom-theme-type ${!pro ? 'disabled' : ''}`} onClick={() => upgradePage(!pro, 'Pro')}>
              <div className={`theme-type-selection ${value?.verified_check == "true" && pro ? 'active' : ''}`}>
                <input
                  id="checkedInput"
                  type="radio"
                  value={true}
                  checked={value?.verified_check == "true" && pro}
                  onChange={handleChange}
                  className="hidden-radio-input"
                  name="verified_check"
                  disabled={!pro}
                />
                <div className="blue-bg">
                  <img src={checkIcon} alt="" />
                </div>
                <div>
                  <div className="theme-type-div-heading">Show Check</div>
                  <div className="theme-type-div-des">Pro or Ultra</div>
                </div>
              </div>
            </label>
          </div>

        </div>
      </div>

      {/* zaap branding section */}
      <div className="design-options">
        <div className="first-half">
          <div className="theme-type-div-heading">Hide Zaap Logo</div>
          <div className="theme-type-div-des mt-10px">Show or hide the Zaap logo.</div>
        </div>
        <div className="d-flex align-items-center gap-2">
          <div className={`selected-theme-option ${value?.zaap_branding === "showBranding" || !value?.zaap_branding ? 'active' : ''}`}>
            <label htmlFor="showBrandingInput" className={`theme-type-selection ${value?.zaap_branding === "showBranding" || !pro || !isEligible || planType == 'partner' ? 'active' : ''}`}>
              <input
                id="showBrandingInput"
                type="radio"
                value="showBranding"
                checked={value?.zaap_branding === "showBranding" || !pro || !isEligible || planType == 'partner'}
                onChange={handleChange}
                className="hidden-radio-input"
                name="zaap_branding"
              />
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="31" height="30" viewBox="0 0 31 30" fill="none">
                  <rect x="0.665527" width="30" height="30" rx="9" fill="#F2F2F2" />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M13.614 7.10333C14.7167 5.46808 17.2702 6.24857 17.2702 8.22082V15.7885C17.2702 16.8924 16.3753 17.7873 15.2713 17.7873H10.1686C8.56732 17.7873 7.61607 15.9986 8.51127 14.671L13.614 7.10333ZM15.1119 15.4697L15.1119 9.26392C15.1119 9.10664 14.9083 9.04439 14.8203 9.1748L10.6359 15.3805C10.5645 15.4864 10.6404 15.6291 10.7681 15.6291H14.9525C15.0405 15.6291 15.1119 15.5577 15.1119 15.4697ZM17.717 22.8969C16.6144 24.5321 14.0609 23.7516 14.0609 21.7794L14.0609 19.7138C14.0609 19.1178 14.544 18.6347 15.14 18.6347C15.736 18.6347 16.2191 19.1178 16.2191 19.7138V20.7363C16.2191 20.8936 16.4228 20.9558 16.5107 20.8254L20.6952 14.6196C20.7666 14.5138 20.6907 14.3711 20.563 14.3711L19.0341 14.3711C18.4382 14.3711 17.955 13.888 17.955 13.292C17.955 12.696 18.4382 12.2129 19.0341 12.2129H21.1625C22.7637 12.2129 23.715 14.0016 22.8198 15.3292L17.717 22.8969Z" fill="#333333" />
                </svg>
              </div>
              <div>
                <div className="theme-type-div-heading">Show Logo</div>
                <div className="theme-type-div-des">Free</div>
              </div>
            </label>
          </div>
          <div className={`selected-theme-option ${value?.zaap_branding === "hideBranding" ? 'active' : ''}`}>
            <label htmlFor="hideBrandingInput" className={`custom-theme-type ${!pro || !isEligible ? 'disabled' : ''}`}>
              <div className={`theme-type-selection ${value?.zaap_branding === "hideBranding" && pro && isEligible ? 'active' : ''}`} onClick={() => upgradePage(!pro || !isEligible, 'Ultra')}>
                <input
                  id="hideBrandingInput"
                  type="radio"
                  value="hideBranding"
                  checked={value?.zaap_branding === "hideBranding" && pro && isEligible && planType !== 'partner'}
                  onChange={handleChange}
                  className="hidden-radio-input"
                  name="zaap_branding"
                  disabled={!pro || !isEligible || planType == 'partner'}
                />
                <div>
                  <img src={hideIcon} alt="" />
                </div>
                <div>
                  <div className="theme-type-div-heading">Hide Logo</div>
                  <div className="theme-type-div-des">Ultra Only</div>
                </div>
              </div>
            </label>
          </div>

        </div>
      </div>

      {/* fonts section */}
      <div>
        <DesignComponents dropdownLabel="title" dropDownOptions={fonts?.all_fonts} handleSelect={handleDropdownChange} reducerKey="font_family" desc={'Choose a font for your Zaap page.'} defaultOption={value?.font_family} valueKey='title' label={'Fonts'} />
      </div>
      <div className="design-content-divider mb-1"></div>
      <div>
        {/* Banner section */}
        <DesignComponents valueKey="value" dropDownOptions={[
          { label: 'Pre-made Images', value: 'premade' },
          { label: 'Custom Image', value: 'custom_image' },
        ]} handleSelect={handleDropdownChange} reducerKey="banner_image_type" desc={'Choose a image that is made by the Zaap team.'} defaultOption={'Pre-made Images'} label={'Banner Image'} />
        {value?.banner_image_type == 'premade' ?
          <div className='banner-cont mt-15px'>
            {images?.length > 0 && images?.map(image =>
              <div onClick={() => AddedBanner(image?.id, image?.thumbnail)} className={`editor-banner-image ${image?.thumbnail == value?.editLink ? "active" : null}`} style={{ backgroundImage: `url(${image?.thumbnail})` }}>
              </div>
            )}
          </div>
          :
          (value?.editLink && value?.profile_style == 'banner' ?
            <div className='mt-20px d-flex justify-content-start gap-20px align-items-center'>
              <img className="design-banner-image" src={value?.editLink} alt="" />
              <div className='d-flex align-items-center gap-10px'>
                <label htmlFor="inputFile" class="cursor-pointer upload--profile-btn borderless position-relative">
                  <img className='filter-invert-1 cursor-pointer' width={12} src={plusIcon} alt="" />
                  Upload Banner
                  <input id="inputFile" type="file" onChange={showCroppie} className='d-none' />
                </label>
                <button type='button' onClick={(e) => removeBanner(value?.selected)} class="upload--profile-btn borderless">
                  <img src={trashIcon} width={15} alt="" />
                </button>
              </div>
            </div>
            :
            <div onClick={bannerShow} className='upload-banner cursor-pointer'>
              <div>
                Upload Image
              </div>
              <button type='button' class="upload--profile-btn borderless">
                <img className='filter-invert-1' width={12} src={plusIcon} alt="" />
              </button>
            </div>)
        }
      </div>
      <ImageCropper maxFileSize={5242880} viewportWidth={250} viewportHeight={250} viewportType="square" outputFormat="jpeg" outputQuality={0.9} onImageCropped={handleImageCropped} showCroppieModal={showCroppieModal} setShowCroppieModal={setShowCroppieModal} />
    </div>
  )
}

export default Design