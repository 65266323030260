import apiClient from './config';

export default {
    createSegment(data) {
        return apiClient.post('/community/subscribers/segments/create', data);
    },
    updateSegment(data) {
        return apiClient.post('/community/subscribers/segments/update', data);
    },
    deleteSegment(id) {
        return apiClient.delete(`/community/subscribers/segments/delete/${id}`);
    },

    getSegments(slug, params) {
        return apiClient.get('/community/subscribers/segments/get/'+slug, { params });
    },
    assignSegment({ slug, subscriberId, tag, isChecked, type }) {
        return apiClient.post('/community/subscribers/segments/assign', {
            slug,
            subscriber_id: subscriberId,
            tag,
            checked: isChecked ? 1 : 0,
            type, // "email" or "phone"
        });
    },
};