import React from 'react';
import Skeleton from 'react-loading-skeleton';
import AudienceTableHeader from './AudienceTableHeader';
import AudienceTableRow from './AudienceTableRow';
import BulkActionsBar from './BulkActionsBar';

const AudienceTable = ({
                           loading,
                           audienceData, // The actual array of data (email or phone)
                           totalAudienceCount,
                           filter,
                           setFilter,
                           selectedRows,
                           handleSelectAll,
                           handleSelectRow,
                           tags, // Available segments/tags for bulk actions/dropdowns
                           onUnsubscribe,
                           onCopyEmail,
                           onCopyPhoneNumber,
                           onShowEditSegmentsModal,
                           onAddNewTag,
                           onDelete,
                           onDeleteAll,
                           onBulkTagToggle,
                           displayConfig = {}
                       }) => {

    const allSelected = totalAudienceCount > 0 && selectedRows.length === totalAudienceCount;

    // Calculate ColSpan dynamically based on visible columns in config
    const calculateColSpan = () => {
        let count = 2; // Checkbox/Name, Actions are always present
        if (displayConfig.primaryIdField) count++;
        if (displayConfig.showStatus !== false) count++;
        if (displayConfig.sortableColumns?.includes('created_at') ?? true) count++;
        if (displayConfig.showRevenue !== false) count++;
        count++;
        return count;
    };
    const colSpan = calculateColSpan();

    // Skeleton rows logic
    const renderSkeletons = () => {
        const skeletonCount = parseInt(filter.count) || 10;
        return Array.from({length: skeletonCount}).map((_, index) => (
            <tr key={`skeleton-${index}`}>
                {/* Use dynamic colSpan */}
                <td colSpan={colSpan}>
                    <Skeleton height={40} borderRadius="10px" style={{margin: '5px 0'}}/>
                </td>
            </tr>
        ));
    };

    // Pass available segments/tags needed by header filter dropdown via displayConfig
    const headerDisplayConfig = {
        ...displayConfig,
        availableSegments: tags, // Add segments needed by header filter
        // Pass status counts if needed by header
        defaultAudienceCounts: displayConfig.defaultAudienceCounts
    };

    return (
        <div className="table-responsive">
            <table className="table" id="audienceTable">
                <AudienceTableHeader
                    filter={filter}
                    setFilter={setFilter}
                    allSelected={allSelected}
                    onSelectAll={handleSelectAll}
                    audienceCount={totalAudienceCount}
                    selectedCount={selectedRows.length}
                    displayConfig={headerDisplayConfig}
                >
                    <BulkActionsBar
                        selectedCount={selectedRows.length}
                        tags={tags}
                        onDeleteAll={onDeleteAll}
                        onBulkTagToggle={onBulkTagToggle}
                        onAddNewTag={onAddNewTag}
                    />
                </AudienceTableHeader>
                <tbody>
                {loading ? (
                    renderSkeletons()
                ) : audienceData && audienceData.length > 0 ? (
                    audienceData.map((member) => (
                        <AudienceTableRow
                            key={member.id}
                            member={member}
                            isSelected={selectedRows.includes(member.id)}
                            onSelectRow={handleSelectRow}
                            onUnsubscribe={onUnsubscribe}
                            onCopyEmail={onCopyEmail}
                            onCopyPhoneNumber={onCopyPhoneNumber}
                            onShowEditSegmentsModal={onShowEditSegmentsModal}
                            onDelete={onDelete}
                            displayConfig={displayConfig}
                        />
                    ))
                ) : (
                    <tr>
                        <td colSpan={colSpan} className="text-center py-5">...</td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    );
};

export default AudienceTable;