import { Modal } from "react-bootstrap"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import ModalErrors from "../../../errors/modal-errors"
import phoneIcon from "../../../../assets/img/modals/email-icon.svg"

const CreatePhoneWidgetModal =
({ show, handleHide, returnIcon, handleSubmit, slug, error, setphoneWidgetModal, phoneWidgetModal }) => {

    const [value, setValue] = useState({
        heading: null, description: null
    });

    const handleChange = (e) => {
        setValue({ ...value, [e.target.name]: e.target.value })
    }
    useEffect(() => {
        setValue({...value, heading: phoneWidgetModal?.name, description: phoneWidgetModal?.details})
    },[phoneWidgetModal])
    const heading = phoneWidgetModal ? "Update SMS Signup" : "Add SMS Signup";

    return (
        <Modal centered show={show} onHide={!phoneWidgetModal ?  handleHide : ()=>{
            setphoneWidgetModal(!phoneWidgetModal)
        }} id="createPhoneWidgetModal">
            <div class="modal-dialog-centered" role="document">
                <div class="modal-content">

                    <div class="modal-body">

                        <form name="create_folder" method="post" role="form" onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit(e, value, phoneWidgetModal?.show ?  phoneWidgetModal?.id : "phone_widget", true, )
                        }}>
                            <div class="notification-container"></div>

                            <div class="form-group">
                                <img src={phoneIcon} width="30px" alt="" />
                                <h4 class="mt-2 mb-1">{heading}</h4>
                                <p class="text-gray font-weight-500">Grow your audience and build your SMS list</p>
                            </div>

                            <div class="form-group">
                                <label><small>Heading</small></label>
                                <input type="text" value={value?.heading} class={`form-control zaap-form-control`} onChange={handleChange} name="heading" required="required" placeholder="Join my newsletter" />
                            </div>

                            <div class="form-group mt-2">
                                <label><small>Description</small></label>
                                <input type="text" value={value?.description} class={`form-control zaap-form-control`} onChange={handleChange} name="description" required="required" placeholder="Weekly value-packed sms." />
                            </div>


                            <div class="text-center mt-4 d-flex">
                                <button type="button" onClick={!phoneWidgetModal?.show ?  handleHide : ()=>{
                                        setphoneWidgetModal({
                                            ...phoneWidgetModal,
                                            show: !phoneWidgetModal?.show,
                                        })
                                }}
                                    class="close btn btn-grey btn-semi-rounded me-2 d-flex align-items-center justify-content-center">
                                    <b class="me-2">Cancel</b>
                                    <small class="badge bg-light text-dark p-1">Esc</small>
                                </button>
                                <button type="submit"
                                    class="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center">
                                    <b class="me-2">Save Changes</b>
                                    <small class="badge bg-primary-dark text-dark px-2 py-1 d-flex align-items-center justify-content-center">
                                        <img src={returnIcon} alt="" />
                                    </small>
                                </button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </Modal>

    )
}
export default CreatePhoneWidgetModal