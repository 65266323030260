import React from 'react';
import {Dropdown} from 'react-bootstrap';
import dotsIcon from "../../assets/img/dots.svg";
import dateTimeFormatter from '../../utils/dateTimeFormatter';
import {getInitials} from "../../utils/utils";

// Custom Toggle
const CustomMenuToggle = React.forwardRef(({children, onClick}, ref) => (
    <div
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
        className="table-action-dropdown noselect d-flex align-items-center justify-content-end dropdown-subscribers"
        style={{cursor: 'pointer'}}
    >
        <img src={dotsIcon} alt="Actions"/>
        {children}
    </div>
));

const AudienceTableRow = ({
                              member,
                              isSelected,
                              onSelectRow,
                              onUnsubscribe,
                              onCopyEmail,
                              onCopyPhoneNumber,
                              onShowEditSegmentsModal,
                              onDelete,
                              displayConfig = {}
                          }) => {
    const val_id = member.id;

    const handleCheckboxChange = (e) => {
        onSelectRow(member.id, e.target.checked);
    };

    // Segment handling
    let assignedSegments = []; // Default to empty array
    const segmentData = member?.segments || member?.tags || member?.tag_list;

    if (Array.isArray(segmentData)) {
        assignedSegments = segmentData;
    } else if (typeof segmentData === 'string') {
        // Attempt to parse if it's a JSON string (common scenario)
        try {
            const parsed = JSON.parse(segmentData);
            if (Array.isArray(parsed)) {
                assignedSegments = parsed;
            }
        } catch (e) {
            console.warn(`Could not parse segment data string for profile ${member.id}:`, segmentData);
        }
    }

    return (
        <tr>
            {/* Checkbox and Name */}
            <td style={{width: displayConfig.checkboxNameWidth || '30%'}}>
                <label className="custom-checkbox checkbox me-2">
                    <input
                        type="checkbox"
                        name="select-one"
                        checked={isSelected}
                        onChange={handleCheckboxChange}
                        data-id={val_id}
                    />
                    <span className="checkmark"></span>
                </label>
                <span className="d-inline-flex align-items-center">
                    <span
                        className="subscriber-name-icon me-2 d-none d-md-inline-flex"
                        style={{
                            width: '30px', height: '30px', borderRadius: '50%',
                            backgroundColor: '#eee', // Example placeholder color
                            display: 'inline-flex', alignItems: 'center', justifyContent: 'center',
                            fontWeight: 'bold', textTransform: 'uppercase'
                        }}
                    >
                        {getInitials(member?.name)}
                    </span>
                    <span>{member?.name || '(No Name)'}</span>
                </span>
            </td>

            {/* Primary ID Cell (Email or Phone) - Conditional */}
            {displayConfig.primaryIdField && (
                <td className="d-none d-md-table-cell" style={{width: displayConfig.primaryIdWidth || '25%'}}>
                    {member[displayConfig.primaryIdField] || 'N/A'}
                </td>
            )}

            {/* Status Cell - Conditional */}
            {displayConfig.showStatus !== false && (
                <td className="d-none d-md-table-cell" style={{width: displayConfig.statusWidth || '10%'}}>
                     <span className={`badge ${member?.subscribed ? 'bg-primary' : 'bg-secondary'}`}>
                        {member?.subscribed ? 'Subscribed' : 'Unsubscribed'}
                     </span>
                </td>
            )}

            {/* Date Joined Cell - Conditional */}
            {(displayConfig.sortableColumns?.includes('created_at') ?? true) && (
                <td className="d-none d-md-table-cell" style={{width: displayConfig.dateJoinedWidth || '10%'}}>
                    {dateTimeFormatter(member?.created_at, true)}
                </td>
            )}

            {/* Revenue Cell - Conditional */}
            {displayConfig.showRevenue !== false && (
                <td className="d-none d-md-table-cell" style={{width: displayConfig.revenueWidth || '5%'}}>
                    ${member?.revenue ?? 0}
                </td>
            )}

            {/* Tags/Segments Cell (Display Only) - Now uses guaranteed 'assignedSegments' array */}
            <td style={{width: displayConfig.segmentWidth || '15%'}}>
                <div className="d-flex flex-wrap gap-1">
                    {/* Now safe to map 'assignedSegments' */}
                    {assignedSegments.length > 0 ? (
                        assignedSegments.map((tag, index) => (
                            // Ensure accessing correct property within the tag object (e.g., tag.name, tag.segment, tag.tag)
                            <span key={index} className="badge text-dark" style={{
                                backgroundColor: tag.color || '#e0e0e0',
                                fontSize: '0.75rem',
                                padding: '0.3em 0.5em'
                            }}>
                                {tag.segment || tag.tag || tag.name || 'Unknown Tag'} {/* Handle potential variations */}
                            </span>
                        ))
                    ) : (
                        <span className="text-muted small">No Segments</span>
                    )}
                </div>
            </td>

            {/* Actions Cell */}
            <td style={{width: displayConfig.actionsWidth || '5%'}}>
                <Dropdown>
                    <Dropdown.Toggle as={CustomMenuToggle} id={`dropdown-actions-${val_id}`}/>
                    <Dropdown.Menu>
                        {/* Unsubscribe (Conditional) */}
                        {displayConfig.showStatus !== false && member?.subscribed === 1 && onUnsubscribe && (
                            <Dropdown.Item onClick={() => onUnsubscribe(val_id)}>Unsubscribe</Dropdown.Item>
                        )}

                        {/* Copy Email (Conditional) */}
                        {displayConfig.primaryIdField === 'email' && onCopyEmail && (
                            <Dropdown.Item onClick={() => onCopyEmail(member?.email)}>Copy Email</Dropdown.Item>
                        )}

                        {/* Copy Phone Number (Conditional) */}
                        {displayConfig.primaryIdField === 'phone_number' && onCopyPhoneNumber && (
                            <Dropdown.Item onClick={() => onCopyPhoneNumber(member?.phone_number)}>Copy
                                Phone</Dropdown.Item>
                        )}

                        {/* --- EDIT SEGMENTS --- */}
                        {onShowEditSegmentsModal && (
                            // Pass the guaranteed 'assignedSegments' array to the modal trigger
                            <Dropdown.Item
                                onClick={() => onShowEditSegmentsModal(val_id, member?.name, assignedSegments)}>
                                Edit Segments
                            </Dropdown.Item>
                        )}

                        <Dropdown.Divider/>

                        {/* Delete */}
                        {onDelete && (
                            <Dropdown.Item onClick={() => onDelete(val_id)}
                                           className="text-danger">Delete</Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                </Dropdown>
            </td>
        </tr>
    );
};

export default AudienceTableRow;