import React, { useEffect, useState } from 'react'
import { Modal } from "react-bootstrap";
import leftArrow from "../../assets/img/plain-left-arrow.svg"
import link_clicked from "../../assets/img/events/link_clicked.svg";
import form_submitted from "../../assets/img/events/form_submitted.svg";
import button_clicked from "../../assets/img/events/button_clicked.svg";
import page_visited from "../../assets/img/events/page_visited.svg";
import mobile from "../../assets/img/mobile-icon.svg"
import desktop from "../../assets/img/desktop-icon.svg"
import tablet from "../../assets/img/tablet-icon.svg"
import infoIcon from "../../assets/img/info-black.svg"
import CustomTooltip from '../common/CustomTooltip';
import { useSelector } from 'react-redux';
import { param } from 'jquery';
import PaginationFooter from '../table/PaginationFooter';
import { apiUrl } from '../../utils/url';
import { getOsIcon } from '../../pages/analytics';

const ViewMoreModal = ({ from, show, setShow, }) => {
    let params = [];
    const images = {
        'link_clicked': link_clicked,
        'form_submitted': form_submitted,
        'button_clicked': button_clicked,
        'page_visited' : page_visited,
    }
    const { basePath, slug } = useSelector(state => state?.slugReducer);
    const [filter, setFilter] = useState({
        page:1,
        count: localStorage?.last_activities_count ?? 10
    });
    const [loading, setLoading] = useState(false);
    const callActivites = async () => {
        setLoading(true);
        
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const res = await fetch(`${apiUrl}/project/statistics/getProjectInteractions/get/${slug}/${show?.val?.id}?${params?.join('&')}`, {
            headers: myHeaders
        })
        const response = await res.json();
        setLoading(false);
        if (response?.status_code === 200) {
            setShow({...show,val:response?.data})
        }else {
            console.log(response)
        }
    };
    const removeModal = () => {
        setShow({ show: false })
    }
    const getAnalyticsImage = (val) => {
        try {
            return require(`../../assets/img/${val}`);
        } catch (error) {
            return require(`../../assets/img/flags/Unknown.png`);
        }
    };
    return (
        <Modal id="showDetailedAnalytics" show={show?.show} onHide={removeModal} placement="end" className="w-auto margin-0 right marketing-section-modal" >
            <div class="modal-dialog modal-dialog-centered h-100 m-0" role="document">
                <div class="modal-content p-0 zaap-border-straight h-100">

                    <div class="modal-header border-0 pb-0">
                        <button type="button" class="p-0 close-new bg-transparent fs-16px" onClick={removeModal}>
                            <span aria-hidden="true">
                                <img src={leftArrow} width={12} alt="" srcset="" />
                            </span>
                            <span className='fs-16px text-grey-u'>
                                Back
                            </span>
                        </button>
                    </div>

                    <div class="modal-body body-u analytics-modal-body mt-1">
                        <div className='more-analytics-sections info'>
                            <div className='more-analytics-icon'>
                                <img src="https://imagedelivery.net/tqC70bVt8T6GtQUXNsa2-g/743efd57-1c7a-4ea5-402b-ec349ceae400/public" alt="" />
                            </div>
                            <div className='more-analytics-details mt-3'>
                                <div className='heading'>Visit Time</div>
                                <div className='desc'>{new Date(show?.val?.created_at).toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })}</div>
                            </div>
                            <div className='more-analytics-details'>
                                <div className='heading'>Visit Duration</div>
                                <div className='desc'>{show?.val?.time_on_page}</div>
                            </div>
                            <div className='more-analytics-details'>
                                <div className='heading'>Country</div>
                                <div className='desc d-flex align-items-center gap-1'>
                                    <span>
                                        <img src={getAnalyticsImage(`flags/${show?.val?.country_code?.toLowerCase() ?? 'Unknown'}.png`)} width="22px" alt="" />
                                    </span>
                                    <span>
                                        {show?.val?.country_name ?? 'Unknown'}
                                    </span>
                                </div>
                            </div>
                            <div className='more-analytics-details'>
                                <div className='heading'>Device</div>
                                <div className='desc d-flex align-items-center gap-1 text-capitalize'>
                                    {show?.val?.device_type && 
                                        <span>
                                            <img src={getAnalyticsImage(`${show?.val?.device_type?.toLowerCase()}-icon.svg`) ?? ''} width="22px" alt="" srcset="" />
                                        </span>
                                    }
                                    <span>
                                        {show?.val?.device_type}
                                    </span>
                                </div>
                            </div>
                            <div className='more-analytics-details'>
                                <div className='heading'>Operating System</div>
                                <div className='desc d-flex align-items-center gap-1'>
                                    <span>
                                    <i className={`fa-brands fa-${getOsIcon(show?.val?.os_name)}`} style={{fontSize:"22px"}}></i>
                                    </span>
                                   <span>
                                        {show?.val?.os_name}
                                    </span>
                                </div>
                            </div>
                            <div className='more-analytics-details'>
                                <div className='heading'>Browser</div>
                                <div className='desc d-flex align-items-center gap-1'>
                                    {show?.val?.browser_name && <span>
                                        <img src={getAnalyticsImage(`${show?.val?.browser_name?.toLowerCase()}-icon.svg`)} width="22px" alt="" />
                                    </span>}
                                    <span>
                                        {show?.val?.browser_name ?? 'Unknown'}
                                    </span>
                                </div>
                            </div>
                            <div className='more-analytics-details'>
                                <div className='heading'>Referrer</div>
                                <div className='desc'>{show?.val?.referrer ?? 'Direct'}</div>
                            </div>

                        </div>
                        <div className='more-analytics-sections more-analytics-sections-table'>
                            <table className='new-analytics-table'>
                                <thead className='new-analytics-table-header'>
                                    <tr className='new-analytics-table-tr'>
                                        <th className="col-4 font-weight-500" scope="col">Time</th>
                                        <th className="col-8 font-weight-500" scope="col">Action</th>
                                        <th>
                                            <CustomTooltip message={[
                                                {icon:page_visited,msg:"Visited a page"},
                                                {icon:link_clicked,msg:"Clicked a Hyperlink"},
                                                {icon:button_clicked,msg:"Clicked a Hyperlink"},
                                                {icon:form_submitted,msg:"Form Submit"}]}>
                                                <img src={infoIcon} alt="" className='cursor-pointer'/>
                                            </CustomTooltip>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className='new-analytics-table-body'>
                                    {show?.val?.project_interaction?.map((val, index) => (
                                        <tr className='new-analytics-table-tr'>
                                            <td>{val?.created_at}</td>
                                            <td className='analytics-action'>
                                                <span>
                                                    <img src={images[val?.event] || ''} alt="" srcset="" />
                                                </span>
                                                <span>
                                                    {val?.value}
                                                </span>
                                            </td>
                                        </tr>
                                    ))
                                    }
                                </tbody>
                            </table>
                            {/* <div class="new-analytics-section-footer  section-footer d-flex align-items-center justify-content-between">
                                <PaginationFooter loading={loading} from={"last_activities"} filter={filter} setfilter={setFilter} total={show?.val?.total} last_page={show?.val?.last_page} current_page={show?.val?.current_page}/>
                            </div> */}
                        </div>
                    </div>

                </div>
            </div>
        </Modal>
    )
}

export default ViewMoreModal