

import { Modal } from "react-bootstrap"
import linkIcon from "../../../../assets/img/modals/link-icon.svg"
import { useState } from "react"
import { useEffect } from "react"
import ErrorMessage from "../../../errors/errorMessage"
import { useHistory } from "react-router-dom"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"

const CreateLinkModal = ({ show, handleHide, returnIcon, handleSubmit, basePath, mainPath, error, parent_id, slug, from, isSubmitting = false }) => {

// console.log("from", from);
        const [value, setValue] = useState({
            link_title: null,
            destination_url: null,
        });
        const history = useHistory();
        const location = useLocation()
        const { parent_block_id } = location?.state ?? {};
        const handleChange = (e) => {
            if (parent_id || parent_block_id) {
                setValue({ ...value, [e.target.name]: e.target.value, parent_id: parent_id ?? parent_block_id});
                return
            }
            setValue({ ...value, [e.target.name]: e.target.value });
        }

        useEffect(() => {
            setValue({
                link_title: null,
                destination_url: null,
            })
        }, [show]);


        const submit = (e) => {
            e.preventDefault();
            // if(from !== "main"){
            // if (value.destination_url.includes("https://www.youtube.com/watch?v=") || value.destination_url.includes("https://youtu.be/")) {
            //     history.push(`${basePath}/${mainPath}`)
            //     setshowConfirmation({show:true, embed_type: "youtube", url: value?.destination_url, project: slug, block: "embed", ...value })
            // }
            // else if (value.destination_url.includes("https://www.twitter.com/")) {
            //     history.push(`${basePath}/${mainPath}`)
            //     setshowConfirmation({show:true, embed_type: "twitter", url: value?.destination_url, project: slug, block: "embed", ...value })
            // }
            // else handleSubmit(e, value, "link")
            // }
            // else
            handleSubmit(e, value, "link")
        }
        // useEffect(() => {
        //     if (parent_block_id) {
        //         setValue({ ...value, parent_id:parent_block_id  })
        //     }
        // }, [parent_block_id])
        return (
            <>
                <Modal centered show={show} onHide={handleHide} id="createLinkModal">
                    <div className="modal-dialog-centered" role="document">
                        <div className="modal-content">

                            <div class="modal-body">

                                <form name="create_folder" method="post" role="form" onSubmit={submit}>
                                    <div class="notification-container"></div>
                                    <div class="form-group">
                                        <img src={linkIcon} width="30px" alt="" />
                                        <h4 class="mt-2 mb-1">Add a link</h4>
                                        <p class="text-gray font-weight-500">Organise your related links into folders.</p>
                                    </div>

                                    <ErrorMessage error={error} />
                                    <div class="form-group">
                                        <label><small>Link Title</small></label>
                                        <input type="text" class={`form-control zaap-form-control`} value={value?.link_title} onChange={handleChange} name="link_title" required="required" placeholder="My Links" />
                                    </div>

                                    <div class="form-group mt-2">
                                        <label><small>Destination URL</small></label>
                                        <input type="url" class={`form-control zaap-form-control`} value={value?.destination_url} onChange={handleChange} name="destination_url" required="required" placeholder="https://mywebsite.com" />
                                    </div>

                                    <div class="text-center mt-4 d-flex">
                                        <button type="button" onClick={handleHide}
                                            class="close btn btn-grey btn-semi-rounded me-2 d-flex align-items-center justify-content-center">
                                            <b class="me-2">Cancel</b>
                                            <small class="badge bg-light text-dark p-1">Esc</small>
                                        </button>
                                        <button type="submit"
                                            // onClick={() => showModal("newBlockModal/createLinkModal/editLinkModal")}

                                            className="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center">
                                            {isSubmitting ? <b className="me-2 loader-parent"><div className="lds-ripple small-white-loader"></div></b> : <b className="me-2">Save Changes</b> }
                                            <small className="badge bg-primary-dark text-dark px-2 py-1 d-flex align-items-center justify-content-center">
                                                <img src={returnIcon} alt="" />
                                            </small>
                                        </button>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </Modal>

            </>
        )
    }
export default CreateLinkModal