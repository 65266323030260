import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import Clarity from '@microsoft/clarity';
import { Route, Switch, useHistory } from "react-router-dom";
import NotFound from "../pages/not-found";
import StripeCheckout from "../auth/stripe-checkout";
import Walkthrough from "../pages/walkthrough";
import Unpaid from "../pages/unpaid";
import StripeAddOnCheckout from "../auth/stripe-addon-checkout";
import mainRoutes from "./mainRoutes";
import Logout from "../pages/logout";
import { AcceptInvite } from "../pages/accept-invite";
import CallbackLogin from "../auth/callback-login";
import DashboardLayout from "../components/layout/dashboard-layout";

const Routes = () => {
    const state = useSelector(state => state.Login);
    const history = useHistory();
    const walkthroughState = useSelector(state => state.walkthroughSteps);
    
    // Memoize this value to prevent unnecessary re-renders
    const walkthroughCompleted = useMemo(() => {
        return localStorage.getItem("walkthrough") === "1" || walkthroughState.walkthrough === 1;
    }, [walkthroughState.walkthrough]);

    useEffect(() => {
        localStorage.removeItem("sendRequest");
        
        // User identification for Clarity
        try {
            const user_id = localStorage.getItem("user_id");
            const name = localStorage.getItem("user");
            const email = localStorage.getItem("email");

            if (name && email && user_id) {
                Clarity.identify(`${name}`);
            }
            
            // Handle redirect to walkthrough if needed
            if (!state.user) {
                if (localStorage.getItem("user") && 
                    (window.location.pathname === "/login" || window.location.pathname === "/create-url") && 
                    localStorage.walkthrough === "1") {
                    history.replace("/dashboard");
                }
                else if (localStorage.getItem("user") && localStorage.walkthrough === "0") {
                    history.replace("/walkthrough?step=" + localStorage.currentStep);
                }
            }
        } catch (error) {
            console.error("Error in Routes initialization:", error);
        }
    }, [state.user, history]);

    // Special handling routes that are available regardless of walkthrough status
    const sharedRoutes = [
        { path: "/stripe-addon-checkout", component: StripeAddOnCheckout },
        { path: "/stripe-checkout", component: StripeCheckout },
        { path: "/logout", component: Logout },
        { path: "/accept-invite", component: AcceptInvite },
        { path: "/callback-login", component: CallbackLogin },
        { path: "/_404", component: NotFound }
    ];

    return (
        <Switch>
            {/* First render shared routes that should be available in all scenarios */}
            {sharedRoutes.map((route, index) => (
                <Route key={`shared-${index}`} path={route.path} component={route.component} />
            ))}
            
            {/* Then handle walkthrough vs main app conditional rendering */}
            {!walkthroughCompleted ? (
                <Route path="/walkthrough" component={Walkthrough} />
            ) : (
                <>
                {/* // Main application routes when walkthrough is completed */}
                {mainRoutes.map((route, index) => (
                    <Route
                        key={`main-${index}`}
                        exact={route.exact}
                        path={route.path}
                        component={route.component}
                    />
                ))}
                <DashboardLayout/>
                </>
            )}
            
            {/* Catchall route for paid/unpaid status */}
            <Route path="*" component={walkthroughCompleted ? NotFound : Unpaid} />
        </Switch>
    );
}

export default Routes;