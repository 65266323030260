import React, { useEffect, useState } from 'react'
import chevron_down from "../../assets/img/modals/chevron-down.svg"
import { useSelector } from 'react-redux';
import { months } from '../../utils/analytics/line';
import "../../assets/calendar-plugin/css/mobiscroll.jquery.min.css"
import "../../assets/calendar-plugin/js/mobiscroll.jquery.min.js"
import * as $ from "jquery";
import dateTimeFormatter from '../../utils/dateTimeFormatter.js';
import { calculateDateRange } from '../../pages/analytics.jsx';


var today = new Date();
var today_text = months[today.getMonth()] + ' ' + today.getDate() + ' ' + today.getFullYear();

var week_before = new Date();
week_before.setDate(week_before.getDate() - 7);
var week_before_text = months[week_before.getMonth()] + ' ' + week_before.getDate() + ' ' + week_before.getFullYear();
const FilterDropdowns = ({ isGraphFilter = true, dateFilters, statsData, analyticsGraphFilterClick, getStats, loading }) => {
    const [first_date, setFirstDate] = useState(week_before);
    const [last_date, setLastDate] = useState(today);
    const [first_date_text, setFirstDateText] = useState(today_text);
    const [last_date_text, setLastDateText] = useState(week_before_text);
    const pro = useSelector(state => state?.proReducer);
    const [isCustomOpen, setIsCustomOpen] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState(today_text + ' - ' + week_before_text);
    const analyticsPageName = window.location.pathname.split("/").pop();

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        const newDate = new Date(value);
        if (newDate <= today) {
            setTimeout(() => {
                if (!isNaN(newDate)) {
                    if (id === "start-date") {
                        setFirstDate(newDate);
                    } else if (id === "end-date") {
                        setLastDate(newDate);
                    }

                    updateCalendarDates(newDate, id);
                }
            }, 1000);
        } else {
            updateInputs(first_date, last_date)
        }
    };

    const updateCalendarDates = (updatedDate, inputType) => {
        let updatedFirstDate = first_date;
        let updatedLastDate = last_date;

        // Update specific date
        if (inputType === "start-date") {
            updatedFirstDate = updatedDate;
        } else if (inputType === "end-date") {
            updatedLastDate = updatedDate;
        }

        // Update state and calendar
        setFirstDate(updatedFirstDate);
        setLastDate(updatedLastDate);

        const myCalendar = $('#calendar-picker').mobiscroll('getInst');
        myCalendar?.setVal([updatedFirstDate, updatedLastDate]);

        updateInputs(updatedFirstDate, updatedLastDate);
    };
    const handleFilterSelect = (filter, value) => {
        if (value === 'custom') {
            setIsCustomOpen((prev) => !prev);
        } else {
            setIsCustomOpen(false);
            if (value) {
                const { from_date, to_date } = calculateDateRange(value);
                getStats(from_date.toISOString(), to_date.toISOString());
                setSelectedFilter(filter);
            }
        }

    };
    const applyDates = () => {
        if (pro && first_date && last_date) {
            let updatedFirstDate = first_date;
            let updatedLastDate = last_date;
            if (updatedFirstDate > updatedLastDate) {
                [updatedFirstDate, updatedLastDate] = [updatedLastDate, updatedFirstDate];
                setFirstDate(updatedFirstDate);
                setLastDate(updatedLastDate);
                const myCalendar = $('#calendar-picker').mobiscroll('getInst');
                myCalendar?.setVal([updatedFirstDate, updatedLastDate]);

                updateInputs(updatedFirstDate, updatedLastDate);
            }
            getStats(dateTimeFormatter(updatedFirstDate, false, true), dateTimeFormatter(updatedLastDate, false, true));
            const dropdownMenu = document.querySelector('#date-filter-dropdown + .dropdown-menu');
            const dropdownToggle = document.getElementById('date-filter-dropdown');
            
            if (dropdownMenu) {
                dropdownMenu.classList.remove('show');
            }
            if (dropdownToggle) {
                dropdownToggle.setAttribute('aria-expanded', 'false');
            }
        }
    };
    const updateInputs = (start, end) => {
        const startInput = document.getElementById("start-date");
        const endInput = document.getElementById("end-date");
        const FDT = `${months[start.getMonth()]} ${start.getDate()} ${start.getFullYear()}`;
        const LDT = `${months[end.getMonth()]} ${end.getDate()} ${end.getFullYear()}`;
        setFirstDateText(FDT)
        setLastDateText(LDT)
        if (startInput) {
            startInput.value = start
                ? FDT
                : '';
        }

        if (endInput) {
            endInput.value = end
                ? LDT
                : '';
        }
        setSelectedFilter(FDT + ' - ' + LDT);

    };

    useEffect(() => {
        if (!loading) {

            $('.date-filter-dropdown-menu').on('click', function (e) {
                e.stopPropagation();
            });
            $(".date-filter-dropdown-menu-selected").text(week_before_text + ' - ' + today_text);
            updateInputs(week_before, today);
            renderCalendar();
            applyDates();

        }
    }, [loading])
    const resetInputs = () => {
        setFirstDate(week_before);
        setLastDate(today);
        updateInputs(week_before, today);
    };

    const renderCalendar = () => {
        var myCalendar = $('#calendar-picker').mobiscroll().datepicker({
            controls: ['calendar'],
            display: 'inline',
            calendarSize: 1,
            selectRange: true,
            selectMultiple: true,
            selectMin: 1,
            selectMax: 2,
            returnFormat: 'locale',
            themeVariant: 'light',
            theme: 'ios',
            max: new Date(),
            onChange: function (event) {
                if (event.value && event.value.length === 2) {
                    let firstD = new Date(event.value[0]);
                    let lastD = new Date(event.value[1]);
                    setFirstDate(firstD);
                    setLastDate(lastD);
                    updateInputs(firstD, lastD);
                }
            }
        }).mobiscroll('getInst');

        setTimeout(() => {
            myCalendar?.setVal([week_before, today]);
        }, 1500);
    };
    return (
        <>
            {
                isGraphFilter &&
                <>
                    <div className="filter-dropdown date-filter-dropdown dropdown-toggle noselect" id="filter-dropdown"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        <span className="">Filter:</span>
                        <span className="filter-dropdown-menu-selected ms-2 font-weight-500">All</span>
                        <img src={chevron_down} width="10px" alt="" className="ms-1" />
                    </div>

                    <ul className="dropdown-menu filter-dropdown-menu analytics-filter-dropdown-menu"
                        aria-labelledby="filter-dropdown" onClick={e => e.stopPropagation()}>
                        {
                            analyticsPageName !== 'links' &&
                            <>
                                <li>
                                    <a className="dropdown-item active" href="#" data-value="All">
                                        <span className="me-5 d-flex align-items-center justify-content-betwen">
                                            <input type="checkbox" value="unique_visitors" name="analytics-graph-filter[]" id="graph-label-1" defaultChecked={true} onClick={analyticsGraphFilterClick} />
                                            <label htmlFor="graph-label-1" className="unique_visitors">Unique Visitors</label>
                                        </span>
                                        <small className="text-muted">{statsData?.unique_visitors?.count}</small>
                                    </a>
                                </li>

                                <li>
                                    <a className="dropdown-item active" href="#" data-value="All">
                                        <span className="me-5 d-flex align-items-center justify-content-betwen">
                                            <input type="checkbox" value="impressions" name="analytics-graph-filter[]" id="graph-label-2" defaultChecked={true} onClick={analyticsGraphFilterClick} />
                                            <label htmlFor="graph-label-2" className="impressions">Impressions</label>
                                        </span>
                                        <small className="text-muted">{statsData?.impressions?.count}</small>
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item active" href="#" data-value="All">
                                        <span className="me-5 d-flex align-items-center justify-content-betwen">
                                            <input type="checkbox" value="time_on_page" name="analytics-graph-filter[]" id="graph-label-3" defaultChecked={true} onClick={analyticsGraphFilterClick} />
                                            <label htmlFor="graph-label-3" className="time_on_page">Visit Duration</label>
                                        </span>
                                        <small className="text-muted">${statsData?.time_on_page?.count}</small>
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item active" href="#" data-value="All">
                                        <span className="me-5 d-flex align-items-center justify-content-betwen">
                                            <input type="checkbox" value="click_rate" name="analytics-graph-filter[]" id="graph-label-5" defaultChecked={true} onClick={analyticsGraphFilterClick} />
                                            <label htmlFor="graph-label-5" className="click_rate">Click Rate</label>
                                        </span>
                                        <small className="text-muted">{statsData?.click_rate?.count}</small>
                                    </a>
                                </li>
                            </>
                        }

                        <li>
                            <a className="dropdown-item active" href="#" data-value="All">
                                <span className="me-5 d-flex align-items-center justify-content-betwen">
                                    <input type="checkbox" value="link_clicks" name="analytics-graph-filter[]" id="graph-label-4" defaultChecked={true} onClick={analyticsGraphFilterClick} />
                                    <label htmlFor="graph-label-4" className="link_clicks">Link Clicks</label>
                                </span>
                                <small className="text-muted">{statsData?.link_clicks?.count}</small>
                            </a>
                        </li>


                    </ul>
                </>
            }
            <div className="ms-1 date-filter-dropdown dropdown-toggle noselect"
                id="date-filter-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                <span className="date-filter-dropdown-menu-selected font-weight-500">{selectedFilter}</span>
                <img src={chevron_down} width="10px" alt="" className="ms-1" />
            </div>

            <ul className="dropdown-menu" style={{overflow:'visible'}}>
                {dateFilters && dateFilters?.map((filter, index) => (
                    <li key={index} onClick={() => handleFilterSelect(filter.label, filter?.value)}>
                        <div className="filter-dropdown-item" >{filter?.label}</div>
                    </li>
                ))}
                <li key={dateFilters?.length} onClick={(e) => {e.preventDefault();e.stopPropagation();handleFilterSelect(null, 'custom')}}>
                    <div className="filter-dropdown-item noselect" id="custom-date-filter-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                        <span className="font-weight-500">Custom Range</span>
                    </div>
                    <ul className="dropdown-menu dropdown_calendar" aria-labelledby="custom-date-filter-dropdown" onClick={e => e.stopPropagation()}>
                        <li>
                            <div className="calendar-container">
                                <div id="calendar-picker"></div>
                                <div className="calendar-controls">
                                    <div className="calendar-input-container">
                                        <label className="text-nowrap" htmlFor="start-date">Start Date:</label>
                                        <input
                                            type="text"
                                            id="start-date"
                                            className="zaapGlobal-input"
                                            placeholder={`${week_before_text}`}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="calendar-input-container mt-10px">
                                        <label className="text-nowrap" htmlFor="end-date">End Date:</label>
                                        <input
                                            type="text"
                                            id="end-date"
                                            className="zaapGlobal-input"
                                            placeholder={`${today_text}`}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                <div className="calendar-button-container">
                                    <button id="apply-dates" className="apply-dates" onClick={applyDates}>Apply</button>
                                    <button id="cancel-dates" className="cancel-dates" onClick={resetInputs}>Cancel</button>
                                </div>
                            </div>
                        </li>
                    </ul>
                </li>
            </ul>
        </>
    )
}

export default FilterDropdowns