import NewsletterModal from "../components/newsletter/modal";
import BlockEditor from "../components/page-editor/block-editor";
import SubModals from "../components/page-editor/block-editor/sub-modals";
import CustomizePage, { CustomizePageModal } from "../components/page-editor/customize-page";
import { AcceptInvite } from "../pages/accept-invite";
import Analytics from "../pages/analytics";
import Email from "../pages/profiles/email";
import CallbackLogin from "../auth/callback-login";
import Dashboard from "../pages/dashboard";
import Logout from "../pages/logout";
import ManageBilling from "../pages/manage-billing";
import NewsLetter from "../pages/newsletter";
import Orders from "../pages/orders";
import NewPageEditor from "../pages/new-page-editor";
import PaypalConnect from "../auth/paypal-connect.jsx";
import Products from "../pages/products";
import StripeCheckout from "../auth/stripe-checkout";
import StripeConnectReauth from "../auth/stripe-connect-reauth";
import StripeConnectReturn from "../auth/stripe-connect-return";
import TikTokCallBack from "../auth/tiktok-callback";
import TwitterCallback from "../auth/twitter-callback";
import Walkthrough from "../pages/walkthrough";
import CreateProduct from "../components/products/create-product";
import Settings from "../components/settings/settings";
import AccountSettings from "../components/settings/account-settings";
import Post from "../pages/posts.jsx";
import DiscountCode from "../pages/discount-code.jsx";
import DiscountCodesModal from "../components/modals/discount-codes-modal.jsx";
import InstagramCallBack from "../auth/instagram-callback.jsx";
import ProductMain from "../components/products/product-main.jsx";
import ThreeBoxPage from "../components/products/product-settings/product-tabs/product-section/three-box-page.jsx";
import ZoomCallback from "../auth/zoom-callback.jsx";
import SpotifyCallBack from "../auth/spotify-callback.jsx";
import ModalsHandler from "../components/ModalsHandler.jsx";
import Segments from "../pages/profiles/segments";
import Phone from "../pages/profiles/phone.jsx";
export let basePath = "/project/:id"
export const changeBasePath = (slug)=> basePath = `/project/${slug}`

const routes = [
    {
        exact: false,
        path: "/dashboard",
        component: Dashboard,
        haveSubPath: false
    },
    {
        exact: false,
        path: "/accept-invite",
        component: AcceptInvite,
    },
    {
        exact: false,
        path: basePath + "/page-editor",
        component: NewPageEditor,
        haveSubPath: true
    },
    // {
    //     exact: false,
    //     path: basePath + "/settings/team-members",
    //     component: Teammembers,
    //     haveSubPath: true
    // },
    {
        exact: false,
        path: basePath + "/settings",
        component: Settings,
        haveSubPath: false
    },
    {
        exact: false,
        path:"/account-settings",
        component: AccountSettings,
    },
    // {
    //     exact: false,
    //     path: basePath + "/settings/connect-calendar",
    //     component: ConnectCalendar,
    //     haveSubPath: true
    // },
    {
        exact: false,
        path: basePath + "/email",
        component: NewsLetter,
        haveSubPath: false
    },
    {
        exact: false,
        path: basePath + "/posts",
        component: Post,
        haveSubPath: false
    },
    {
        exact: false,
        path: basePath + "/codes",
        component: DiscountCode,
        haveSubPath: false
    },
    {
        exact: false,
        path: basePath + "/profiles/email",
        component: Email,
        haveSubPath: false
    },
    {
        exact: false,
        path: basePath + "/profiles/phone",
        component: Phone,
        haveSubPath: false
    },
    {
        exact: false,
        path: basePath + "/profiles/segments",
        component: Segments,
        haveSubPath: false
    },
    {
        exact: false,
        path: basePath + "/products",
        component: Products,
        haveSubPath: false
    },
    {
        exact: false,
        path: basePath + "/orders",
        component: Orders,
        haveSubPath: false
    },
    {
        exact: false,
        path: basePath + "/analytics",
        component: Analytics,
        haveSubPath: false
    },
    {
        exact: false,
        path: "/logout",
        component: Logout,
        haveSubPath: false
    },
    {
        exact: false,
        path: "/walkthrough",
        component: Walkthrough,
        haveSubPath: false
    },
    {
        exact: false,
        path: basePath + "/createProducts",
        component: CreateProduct,
        haveSubPath: false
    },
    {
        exact: false,
        path: basePath + "/editProduct",
        component: ProductMain,
        haveSubPath: true
    },
    {
        exact: false,
        path: basePath + "/editProduct/createThreeBoxSection",
        component: ThreeBoxPage,
        haveSubPath: false
    },
    {
        exact: false,
        path: basePath + "/email/send-email",
        component: NewsletterModal,
        haveSubPath: false
    },
    {
        exact: false,
        path: basePath + "/email/sendCode",
        component: DiscountCodesModal,
        haveSubPath: false
    },
    {
        exact: false,
        path: basePath + "/posts/post",
        component: NewsletterModal,
        haveSubPath: false
    },
    {
        exact: false,
        path: basePath + "/page-editor/:blockEditorId",
        component: ModalsHandler,
        haveSubPath: true
    },
    {
        exact: false,
        path: basePath + "/page-editor/:blockEditorId/:subPath",
        component: SubModals,
        haveSubPath: true
    },
    // {
    //     exact: false,
    //     path: basePath + "/page-editor/newBlockModal",
    //     component: BlockEditor,
    //     haveSubPath: true
    // },
    // {
    //     exact: false,
    //     path: basePath + "/page-editor/newBlockModal/createFolderModal",
    //     component: SubModals,
    //     haveSubPath: true
    // },
    // {
    //     exact: false,
    //     path: basePath + "/page-editor/newBlockModal/createLinkModal",
    //     component: SubModals,
    //     haveSubPath: true
    // },
    // {
    //     exact: false,
    //     path: basePath + "/page-editor/newBlockModal/createEmailWidgetModal",
    //     component: SubModals,
    //     haveSubPath: true
    // },
    // {
    //     exact: false,
    //     path: basePath + "/page-editor/newBlockModal/createProductWidgetModal",
    //     component: SubModals,
    //     haveSubPath: true
    // },
    // {
    //     exact: false,
    //     path: basePath + "/page-editor/newBlockModal/createYouTubeWidgetModal",
    //     component: SubModals,
    //     haveSubPath: true
    // },
    // {
    //     exact: false,
    //     path: basePath + "/page-editor/newBlockModal/createTiktokWidgetModal",
    //     component: SubModals,
    //     haveSubPath: true
    // },
    // {
    //     exact: false,
    //     path: basePath + "/page-editor/newBlockModal/createInstagramWidgetModal",
    //     component: SubModals,
    //     haveSubPath: true
    // },
    // {
    //     exact: false,
    //     path: basePath + "/page-editor/newBlockModal/createTwitterWidgetModal",
    //     component: SubModals,
    //     haveSubPath: true
    // },

    {
        exact: false,
        path: basePath + "/page-editor/customizePageModal",
        component: CustomizePageModal,
        haveSubPath: true
    },
    {
        exact: false,
        path: basePath + "/page-editor/customizePageModal/customDomainModal",
        component: CustomizePage,
        haveSubPath: true
    },
    {
        exact: false,
        path: basePath + "/page-editor/customizePageModal/customizePageProfile",
        component: CustomizePage,
        haveSubPath: true
    },
    {
        exact: false,
        path: basePath + "/page-editor/customizePageModal/customizePageTheme",
        component: CustomizePage,
        haveSubPath: true
    },
    {
        exact: false,
        path: basePath + "/page-editor/customizePageModal/customizePageSocials",
        component: CustomizePage,
        haveSubPath: true
    },
    {
        exact: false,
        path: basePath + "/page-editor/customizePageModal/customizePageFonts",
        component: CustomizePage,
        haveSubPath: true
    },
    // {
    //     exact: false,
    //     path: basePath + "/page-editor/customizePageModal/customizePageDomains",
    //     component: CustomizePage,
    //     haveSubPath: true
    // },
    // {
    //     exact: false,
    //     path: basePath + "/page-editor/customizePageModal/customizePageDomains/customDomainModal",
    //     component: CustomizePage,
    //     haveSubPath: true
    // },
    {
        exact: false,
        path: basePath + "/page-editor/customizePageModal/customizePageSEO",
        component: CustomizePage,
        haveSubPath: true
    },
    {
        exact: false,
        path: basePath + "/page-editor/customizePageModal/customizePageIntegrations",
        component: CustomizePage,
        haveSubPath: true
    },
    {
        exact: false,
        path: basePath + "/page-editor/customizePageModal/customizePageIntegrations/googleAnalytics",
        component: CustomizePage,
        haveSubPath: true
    },
    {
        exact: false,
        path: "/twitter-callback",
        component: TwitterCallback,
        haveSubPath: true
    },
    {
        exact: false,
        path: "/tiktok-callback",
        component: TikTokCallBack,
        haveSubPath: true
    },
    {
        exact: false,
        path: "/instagram-callback",
        component: InstagramCallBack,
        haveSubPath: true
    },
    {
        exact: false,
        path: "/zoom-callback",
        component: ZoomCallback,
        haveSubPath: true
    },
    {
        exact: false,
        path: "/stripe-checkout",
        component: StripeCheckout,
    },
    {
        exact: false,
        path: "/stripe-connect-reauth",
        component: StripeConnectReauth,
    },
    {
        exact: false,
        path: "/stripe-connect-return",
        component: StripeConnectReturn,
    },
    {
        exact: false,
        path: "/paypal-connect",
        component: PaypalConnect,
    },
    {
        exact: false,
        path: "/callback-login",
        component: CallbackLogin,
    },
    {
        exact: false,
        path:"/manage-billings",
        component: ManageBilling,
    },
    {
        exact: false,
        path: "/spotify-callback",
        component: SpotifyCallBack,
        haveSubPath: true
    },
    {
        exact: false,
        path: basePath + "/phone",
        component: Phone,
        haveSubPath: false
    },
    
]
export default routes