import { secondsToMins } from "../dateTimeFormatter";


export const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
];
let labels = [];
let graph_dates = new Date();

for (let index = 1; index < 10; index++) {
    graph_dates.setDate(graph_dates.getDate() - 1);
    labels.push(months[graph_dates.getMonth()]+' '+graph_dates.getDate());    
}

export const originalData = {
    labels: labels,
    datasets: [
        {
            label: 'Unique Visitors',
            data: [20, 40, 70, 100, 140, 180, 240, 230, 120, 40],
            fill: false,
            borderColor: '#0C8CFB',
            tension: 0.1
        },
        {
            label: 'Impressions Visitors',
            data: [120, 240, 170, 100, 40, 80, 140, 130, 140, 60],
            fill: false,
            borderColor: '#7F48FB',
            tension: 0.1
        },
        {
            label: 'New Subscribers',
            data: [220, 40, 210, 110, 140, 80, 240, 170, 100, 40],
            fill: false,
            borderColor: '#FB48C9',
            tension: 0.1
        },
        {
            label: 'Revenue',
            data: [130, 40, 100, 150, 200, 250, 220, 150, 170, 100],
            fill: false,
            borderColor: '#FB9E48',
            tension: 0.1
        }
    ]
};

export const originalOptions = {
    responsive: true,
    plugins: {
        // legend: {
        //     display: false,
        // },
        tooltip: {
            enabled: false,
            external: function(context) {
                let tooltipEl = document.getElementById('custom-tooltip');
        
                if (!tooltipEl) {
                    tooltipEl = document.createElement('div');
                    tooltipEl.id = 'custom-tooltip';
                    tooltipEl.className = 'custom-tooltip';
                    document.body.appendChild(tooltipEl);
                }
        
                if (context.tooltip.opacity === 0) {
                    tooltipEl.style.opacity = 0;
                    return;
                }

                const chart = context.chart;
                const chartPosition = chart.canvas.getBoundingClientRect();
                const dataPoint = context.tooltip.dataPoints[0];
                const dataIndex = dataPoint.index;
                const datasetIndex = dataPoint.datasetIndex;
                const dataX = chart.scales.x.getPixelForValue(dataPoint?.label);
                const dataY = chart.scales.y.getPixelForValue(dataPoint.raw);
            
                let tooltipX = dataX + chartPosition.left;
                let tooltipY = dataY + chartPosition.top;
            
                // Adjust the tooltip position to open from right to left
                const tooltipWidth = tooltipEl.offsetWidth;
                const tooltipHeight = tooltipEl.offsetHeight;
                const screenWidth = window.innerWidth;
            
                // Move the tooltip to the left if it would go off the right side of the screen
                if (tooltipX + tooltipWidth > screenWidth) {
                    tooltipX = dataX + chartPosition.left - tooltipWidth - 10;
                }
            
                // Ensure the tooltip is within the screen bounds
                if (tooltipX < 0) {
                    tooltipX = 10;
                }
                if (tooltipY + tooltipHeight > window.innerHeight) {
                    tooltipY = window.innerHeight - tooltipHeight - 10;
                }
                if (tooltipY < 0) {
                    tooltipY = 10;
                }
            
                tooltipEl.style.opacity = 1;
                tooltipEl.style.left = tooltipX + 'px';
                tooltipEl.style.top = tooltipY + 'px';
            
                const tooltipData = context?.tooltip?.dataPoints?.map((item) => `
                    <div class="tooltip-item">
                        <div class="tooltip-item-first">
                            <span class="analytics-box" style="background-color: ${item?.dataset?.borderColor};"></span>
                            <span class="tooltip-label">${item?.dataset?.label}:</span>
                        </div>
                        <span class="tooltip-value">${item?.dataset?.label == 'Visit Duration' ? secondsToMins(item.raw) : item.raw}</span>
                    </div>
                `).join('');
            
                tooltipEl.innerHTML = `
                    <div class="tooltip-title">${context?.tooltip?.title[0]}</div>
                    ${tooltipData}
                `;
            }
        }
    },
    scales: {
        y: {
            display: true,
            position: 'right',
            beginAtZero: true,

        }
    }
    // scales: {
    //     xAxes: {
    //         ticks: {
    //           callback: (value, index, values) => {
    //             // Show only the first and last label
    //             console.log(" values.lengths - 1",  values);
    //             if (index === 0 || index === values.length - 1) {
    //               return value;
    //             }
    //             return '';
    //           },
    //         },
    //       },
    //     x: {
    //         grid: {
    //             display: false
    //         },
    //         display: false
    //     },
    //     y: {
    //         grid: {
    //             drawBorder: false
    //         },
    //         display: false
    //     }
    // },
    // legend: {
    //     display: false,
    // },
}
export const originalOptionsForDashboardGraph =  {
    responsive: true,
    plugins: {
        legend: {
            display: false,
        },
        filler: {
            propagate: true
        }
    },
    scales: {
        // x: {
        //     ticks: {
        //       callback: (value, index, values) => {
        //         console.log(" values.lengths - 1",  value);
        //         if (index === 0 || index === values.length - 1) {
        //           return 'value';
        //         }
        //         return '';
        //       },
        //     },
        //   },
        x: {
            grid: {
                borderDash: [8, 4],
                display: true
            },
            display: true
        },
        y: {
            display: false
        }
    },
    tooltips: {
        callbacks: {
           label: function(tooltipItem) {
                  return tooltipItem.yLabel;
           }
        }
    },
    legend: {
        display: false,
    },
    elements: {
        point:{
            radius: 0
        }
    }
}
export const options = {
    scales: {
        xAxes: {
          ticks: {
            callback: (value, index, values) => {
              // Show only the first and last label
              console.log(" values.lengths - 1",  values);
              if (index === 0 || index === values.length - 1) {
                return value;
              }
              return '';
            },
          },
        },
      },
}