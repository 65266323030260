import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import {generateRandomColor} from "../../utils/utils";

const TagDropdown = ({
    availableTags = [],
    checkedTags = [],
    onTagToggle,
    onAddNewTag,
    targetId,
    children,
}) => {
    const [newTag, setNewTag] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);

    const handleAddNewTag = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (newTag.trim() !== '' && !availableTags.some(t => t.tag === newTag.trim())) {
            const newColor = generateRandomColor();
            onAddNewTag(newTag.trim(), newColor);
            setNewTag('');
        }
    };

    const handleTagClick = (e, tag) => {
        e.stopPropagation();
        e.preventDefault();
        const isChecked = !checkedTags.some(ct => ct.tag === tag.tag);
        onTagToggle(tag, isChecked);
    };

    const isTagChecked = (tag) => {
        return checkedTags.some(ct => ct.tag === tag.tag);
    }

    return (
        <Dropdown show={showDropdown} onToggle={(isOpen, event, metadata) => {
             if (metadata.source !== 'select' && metadata.source !== 'input' && metadata.source !== 'click') {
                setShowDropdown(isOpen);
             } else if (metadata.source === 'rootClose') {
                 setShowDropdown(false);
             } else if (metadata.source === 'click' && event.target.classList.contains('dropdown-toggle')) {
                 setShowDropdown(!showDropdown);
             }
        }}>
            <Dropdown.Toggle as="div" id={`dropdown-tags-${targetId || 'bulk'}`} className="dropdown-toggle-wrapper">
                {React.cloneElement(children, { onClick: (e) => { e.stopPropagation(); setShowDropdown(!showDropdown); } })}
            </Dropdown.Toggle>

            <Dropdown.Menu
                onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside menu
                style={{ minWidth: '200px', padding: '0.5rem' }} // Basic styling
            >
                <div className="px-2 py-1 d-flex">
                    <input
                        type="text"
                        placeholder="Add new segment"
                        className="form-control form-control-sm flex-grow-1 me-2"
                        value={newTag}
                        onChange={(e) => setNewTag(e.target.value)}
                        onClick={(e) => e.stopPropagation()} // Prevent dropdown close on input click
                    />
                    <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={handleAddNewTag}
                        disabled={!newTag.trim() || availableTags.some(t => t.tag === newTag.trim())}
                    >
                        Add
                    </button>
                </div>
                <Dropdown.Divider />
                {availableTags.length === 0 && <Dropdown.ItemText>No segments available.</Dropdown.ItemText>}
                {availableTags.map((tag, index) => (
                    <Dropdown.Item key={index} onClick={(e) => handleTagClick(e, tag)} as="button" className="d-flex align-items-center tags-option">
                         <input
                            type="checkbox"
                            className="me-2 form-check-input"
                            checked={isTagChecked(tag)}
                            readOnly
                            style={{ pointerEvents: 'none' }}
                        />
                        <span
                            style={{
                                display: 'inline-block',
                                width: '12px',
                                height: '12px',
                                backgroundColor: tag.color,
                                borderRadius: '3px',
                                marginRight: '8px',
                            }}
                        ></span>
                        <span className={`flex-grow-1 ${isTagChecked(tag) ? 'fw-bold' : ''}`}>{tag.tag}</span>
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default TagDropdown;