import {useEffect, useState} from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Clarity from '@microsoft/clarity';
import Routes from './routes';
import store from "./redux/store";
import {Provider} from "react-redux";
import {ToastContainer} from "react-toastify";
import {AlertProvider} from "./contexts/AlertContext";
import {SidebarProvider} from "./contexts/SidebarContext";
import authRoutes from "./routes/authRoutes";
import AuthLayout from "./components/layout/auth-layout";
import DynamicTitle from "./components/layout/DynamicTitle";

function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(null);

    useEffect(() => {
        // Check authentication status function
        const checkAuthStatus = () => {
            const userExists = localStorage.getItem("user");
            setIsAuthenticated(!!userExists);
        };

        // Add an explicit logout handler
        const handleLogout = () => {
            setIsAuthenticated(false);
        };

        checkAuthStatus();

        window.addEventListener('auth-change', checkAuthStatus);
        window.addEventListener('logout', handleLogout);
        window.addEventListener('storage', (e) => {
            if (e.key === 'user') {
                checkAuthStatus();
            }
        });

        if (process.env.REACT_APP_PRODUCTION === "true") {
            const timer = setTimeout(() => {
                Clarity.init(`${process.env.REACT_APP_CLARITY_ID}`);
            }, 100);
            return () => clearTimeout(timer);
        }

        // Clean up event listeners
        return () => {
            window.removeEventListener('auth-change', checkAuthStatus);
            window.removeEventListener('logout', handleLogout);
            window.removeEventListener('storage', checkAuthStatus);
        };
    }, []);

    // Show loader during initial auth check
    if (isAuthenticated === null) {
        return (
            <div className="full-page-loader d-flex">
                <div className="lds-ripple">
                    <div></div>
                    <div></div>
                </div>
            </div>
        );
    }

    return (
        <Provider store={store}>
            {/* Force Router to re-mount when auth state changes by using key */}
            <Router key={isAuthenticated ? 'authenticated' : 'unauthenticated'}>
                <DynamicTitle defaultTitle="Zaap"/>
                <AlertProvider>
                    {isAuthenticated ? (
                        // Authenticated routes with SidebarProvider
                        <SidebarProvider>
                            <Routes/>
                        </SidebarProvider>
                    ) : (
                        // Authentication routes (when not authenticated)
                        <>
                            <Switch>
                                {/* Render all auth routes from authRoutes.js */}
                                {authRoutes.map((route, index) => (
                                    route.component && (
                                        <Route
                                            key={index}
                                            exact={route.exact}
                                            path={route.path}
                                            component={route.component}
                                        />
                                    )
                                ))}
                            </Switch>

                            {/* If no route matches, show AuthLayout as fallback */}
                            {window.location.pathname !== "/login" &&
                                window.location.pathname !== "/register" &&
                                window.location.pathname !== "/forgot-password" &&
                                window.location.pathname !== "/reset-password" &&
                                window.location.pathname !== "/create-url" &&
                                window.location.pathname !== "/google-oauth" &&
                                window.location.pathname !== "/callback-login" &&
                                window.location.pathname !== "/accept-invite" && (
                                    <AuthLayout/>
                                )}
                        </>
                    )}

                    <ToastContainer
                        position="bottom-center"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="dark"
                    />
                </AlertProvider>
            </Router>
        </Provider>
    );
}

export default App;