import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { Modal, Button, Table, Form, Dropdown } from 'react-bootstrap';

// Assets
import dotsIcon from "../../assets/img/dots.svg";
import returnIcon from "../../assets/img/modals/return.svg";
import deleteIcon from "../../assets/img/delete-icon-red.svg"; // Icon for bulk delete

// API and Utils
import segmentApi from "../../api/segment";
import { showLoader, hideLoader } from "../../components/loader";
import dateTimeFormatter from "../../utils/dateTimeFormatter";

// Contexts and Hooks
import { useAlert } from "../../contexts/AlertContext";

// Components
import PaginationFooter from "../../components/table/PaginationFooter"; // TODO
import Sidebar from "../../components/layout/Sidebar";
import EmptyState from "../empty-state";
import NewConfirmationModal from "../../components/common/new-confirmation-modal";
import HeaderSearch from "../../components/table/HeaderSearch"; // TODO
import Skeleton from "react-loading-skeleton";

// Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../styles/Profiles.module.css";
import 'react-loading-skeleton/dist/skeleton.css';


// Custom Toggle for Action Dropdown
const CustomMenuToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
        className="ms-auto w-fit-content table-action-dropdown noselect"
        style={{ cursor: 'pointer' }}
    >
        <img src={dotsIcon} alt="Actions" />
        {children}
    </div>
));

// Sort Arrow Component
const SortArrow = ({ direction }) => {
    if (!direction) return null;
    return direction === 'asc' ? <span className="ms-1">↑</span> : <span className="ms-1">↓</span>;
};


const Segments = () => {
    const [loading, setLoading] = useState(true);
    const { slug } = useSelector(state => state?.slugReducer);
    const { setAlert } = useAlert();

    // State for the list of segments and pagination
    const [segmentsData, setSegmentsData] = useState({ data: [], total: 0, last_page: 1, current_page: 1 }); // API response structure

    // --- State for Selection ---
    const [selectedRows, setSelectedRows] = useState([]); // Array of selected segment IDs

    // State for Add/Edit Modal
    const [showModal, setShowModal] = useState(false);
    const [editingSegment, setEditingSegment] = useState(null); // null for 'Add', segment object for 'Edit'
    const [newSegmentName, setNewSegmentName] = useState('');

    // State for Confirmation Modal
    const [confirmationModalProps, setConfirmationModalProps] = useState({ show: false });

    // State for filters (pagination, search, sorting)
    const defaultCount = localStorage?.getItem('segment_count') ?? 10; // Use a specific key?
    const [filter, setFilter] = useState({
        page: 1,
        count: defaultCount,
        search: '', // Added search filter
        orderBy: 'segment', // Default sort column
        orderType: 'asc',   // Default sort direction
    });

    // --- Data Fetching ---
    const callSegments = useCallback(async () => {
        if (!slug) return;
        setLoading(true);
        showLoader(); // Consider integrating loader with loading state

        const queryParams = {
            page: filter.page,
            count: filter.count,
            ...(filter.search && { search: filter.search }),
            ...(filter.orderBy && { orderBy: filter.orderBy }),
            ...(filter.orderType && { orderType: filter.orderType }),
        };

        try {
            const response = await segmentApi.getSegments(slug, queryParams);
            // Adjust based on your actual API response structure
            setSegmentsData({
                data: response?.data?.data || [],
                total: response?.data?.total || 0,
                last_page: response?.data?.last_page || 1,
                current_page: response?.data?.current_page || 1,
            });
        } catch (error) {
            console.error('Failed to fetch segments:', error);
            setAlert({ type: "error", title: "Error", message: "Failed to fetch segments." });
            setSegmentsData({ data: [], total: 0, last_page: 1, current_page: 1 }); // Reset on error
        } finally {
            setLoading(false);
            hideLoader();
            setSelectedRows([]); // Clear selection on data refresh
        }
    }, [slug, filter, setAlert]); // Dependencies

    useEffect(() => {
        callSegments();
    }, [callSegments]); // Fetch when component mounts or dependencies change

    // --- Selection Handlers ---
    const handleSelectRow = (id, isSelected) => {
        setSelectedRows(prev =>
            isSelected ? [...prev, id] : prev.filter(rowId => rowId !== id)
        );
    };

    const handleSelectAll = (event) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            // Select only IDs currently visible/loaded if API doesn't support select all across pages easily
            const allVisibleIds = segmentsData.data.map(segment => segment.id);
            setSelectedRows(allVisibleIds);
        } else {
            setSelectedRows([]);
        }
    };

    // --- Sorting Handler ---
    const handleSort = (column) => {
        // Determine new sort direction
        const newOrderType = (filter.orderBy === column && filter.orderType === 'asc') ? 'desc' : 'asc';
        // Update filter state
        setFilter(prevFilter => ({
            ...prevFilter,
            orderBy: column,
            orderType: newOrderType,
            page: 1,
        }));
    };

    // --- Modal Handling ---
    const handleShowModal = (segmentToEdit = null) => {
        if (segmentToEdit) {
            setEditingSegment(segmentToEdit);
            setNewSegmentName(segmentToEdit.segment);
        } else {
            setEditingSegment(null);
            setNewSegmentName(''); // Clear name for adding
        }
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setNewSegmentName('');
        setEditingSegment(null);
    };

    // --- CRUD Operations ---
    const handleSaveSegment = async (e) => {
        e.preventDefault();
        const trimmedName = newSegmentName.trim();
        if (trimmedName === '') return;

        // Prevent saving if name hasn't changed during edit
        if (editingSegment && editingSegment.segment === trimmedName) {
            handleCloseModal();
            return;
        }

        // Check for duplicate name (case-insensitive recommended) before sending API call
        const isDuplicate = segmentsData.data.some(seg =>
            seg.segment.toLowerCase() === trimmedName.toLowerCase() &&
            (!editingSegment || seg.id !== editingSegment.id) // Allow saving if it's the same segment being edited
        );

        if (isDuplicate) {
            setAlert({ type: 'warning', title: 'Duplicate', message: `Segment "${trimmedName}" already exists.` });
            return;
        }

        showLoader();
        try {
            let response;
            if (editingSegment) {
                // Update existing segment
                response = await segmentApi.updateSegment({ // Pass ID and new name
                    id: editingSegment.id,
                    segment: trimmedName,
                    // Include color if you allow editing it - removed color requirement
                });
            } else {
                // Create new segment
                response = await segmentApi.createSegment({
                    slug: slug,
                    segment: trimmedName,
                    // Color is no longer generated/sent based on requirements
                });
            }

            // Check response and update UI
            if (response?.status_code === 200) {
                setAlert({ type: 'success', title: 'Success', message: response?.status_message || `Segment ${editingSegment ? 'updated' : 'added'}.` });
                handleCloseModal();
                callSegments(); // Refetch the list to show changes
            } else {
                setAlert({ type: 'error', title: 'Error', message: response?.status_message || 'Failed to save segment.' });
            }
        } catch (error) {
            console.error("Failed to save segment:", error);
            setAlert({ type: 'error', title: 'Error', message: 'An unexpected error occurred.' });
        } finally {
            hideLoader();
        }
    };

    const confirmDeleteSegment = (segmentToDelete) => {
        // Confirmation for single delete
        setConfirmationModalProps({
            show: true,
            title: `Delete Segment: ${segmentToDelete.segment}`,
            message: 'Are you sure you want to permanently delete this segment? This action cannot be undone.',
            buttonText: 'Yes, Delete',
            variant: 'danger',
            callback: () => handleDeleteSegment(segmentToDelete.id) // Callback for single delete
        });
    };

    const confirmBulkDelete = () => {
        // Confirmation for bulk delete
        if (selectedRows.length === 0) return;
        setConfirmationModalProps({
            show: true,
            title: `Delete ${selectedRows.length} Segment(s)`,
            message: `Are you sure you want to permanently delete the selected ${selectedRows.length} segment(s)? This action cannot be undone.`,
            buttonText: 'Yes, Delete Selected',
            variant: 'danger',
            callback: () => handleDeleteSegment() // Callback for bulk delete (will use selectedRows)
        });
    };


    const handleDeleteSegment = async (id = null) => {
        // Handles both single (id provided) and bulk (id is null, uses selectedRows) delete
        showLoader();
        const idsToDelete = id ? [id] : selectedRows; // Determine IDs to delete

        if (idsToDelete.length === 0) {
            hideLoader();
            setConfirmationModalProps({ show: false });
            return; // Nothing to delete
        }

        try {
            // TODO
            const response = await segmentApi.deleteBulkSegments(idsToDelete);

            if (response?.status_code === 200) {
                setAlert({ type: 'success', title: 'Success', message: response?.status_message || 'Segment(s) deleted.' });
                callSegments();
            } else {
                setAlert({ type: 'error', title: 'Error', message: response?.status_message || 'Failed to delete segment(s).' });
            }
        } catch (error) {
            console.error("Failed to delete segment(s):", error);
            setAlert({ type: 'error', title: 'Error', message: 'An unexpected error occurred.' });
        } finally {
            setConfirmationModalProps({ show: false }); // Hide confirmation modal
            hideLoader();
        }
    };

    // --- Render ---
    const segmentList = segmentsData.data || [];
    const hasSegments = segmentList.length > 0;
    const totalRecords = segmentsData.total || 0;
    const allSelected = hasSegments && segmentList.every(segment => selectedRows.includes(segment.id));


    return (
        <div id="wrapper">
            <div className="d-flex new-sidebar">
                <Sidebar from={"segments"} />

                <section className="community-section pb-0 flex-grow-1">
                    {/* Header */}
                    <div className="section-header d-flex justify-content-between align-items-center p-3 border-bottom">
                        <h1 className="mb-0 h5">Segments</h1>
                        <div className="d-flex align-items-center">
                            {/* Search Filter */}
                            <HeaderSearch filter={filter} setfilter={setFilter} name="segments" /> {/* Pass name prop */}

                            {/* Add Segment button */}
                            <Button variant="primary" className="ms-2" onClick={() => handleShowModal()}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-lg me-1" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"/>
                                </svg>
                                Add Segment
                            </Button>
                        </div>
                    </div>

                    {/* Main Content */}
                    <div className="section-main p-3">
                        {/* Show Empty State only if loading is done and no segments exist on first page without search */}
                        {!loading && !hasSegments && filter.page === 1 && !filter.search ? (
                            <EmptyState
                                from={"segments"} 
                                firstText={'Woah! It’s quiet in here...'}
                                secondText={"Segments are a great way to divide your profiles. Build your first segment and start using them when sending emails to your profiles."}
                                buttonText={"Add Segment"}
                                clickedFunction={() => handleShowModal()}
                            />
                        ) : (
                            <>
                                {/* Bulk Delete Button - shown when items are selected */}
                                {selectedRows.length > 0 && (
                                    <div className="mb-2 d-flex justify-content-start">
                                        <Button variant="outline-danger" size="sm" onClick={confirmBulkDelete} className="d-flex align-items-center">
                                            <img src={deleteIcon} alt="Delete" width="12" className="me-1" />
                                            Delete Selected ({selectedRows.length})
                                        </Button>
                                    </div>
                                )}

                                <Table striped bordered hover responsive className="segment-table"> {/* Added class for potential styling */}
                                    <thead>
                                    <tr>
                                        {/* Checkbox Header */}
                                        <th style={{ width: '50px' }}>
                                            <Form.Check
                                                type="checkbox"
                                                id="select-all-segments"
                                                checked={allSelected}
                                                onChange={handleSelectAll}
                                                disabled={!hasSegments || loading} // Disable if no data or loading
                                            />
                                        </th>
                                        {/* Segment Name Header (Sortable) */}
                                        <th onClick={() => handleSort('segment')} style={{ cursor: 'pointer' }}>
                                            Segment Name
                                            <SortArrow direction={filter.orderBy === 'segment' ? filter.orderType : null} />
                                        </th>
                                        {/* Members Header (Sortable - Placeholder) */}
                                        <th onClick={() => handleSort('members_count')} style={{ cursor: 'pointer', width: '15%' }}> {/* Adjust 'members_count' if API field is different */}
                                            Members
                                            <SortArrow direction={filter.orderBy === 'members_count' ? filter.orderType : null} />
                                        </th>
                                        {/* Created Header (Sortable) */}
                                        <th onClick={() => handleSort('created_at')} style={{ cursor: 'pointer', width: '20%' }}>
                                            Created
                                            <SortArrow direction={filter.orderBy === 'created_at' ? filter.orderType : null} />
                                        </th>
                                        {/* Actions Header */}
                                        <th style={{ width: '100px', textAlign: 'center' }}>Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {loading ? (
                                        // Skeleton Loading Rows
                                        Array.from({ length: filter.count }).map((_, index) => (
                                            <tr key={`skeleton-${index}`}>
                                                <td><Skeleton circle={true} height={20} width={20} /></td>
                                                <td><Skeleton height={20} /></td>
                                                <td><Skeleton height={20} width={50} /></td>
                                                <td><Skeleton height={20} width={150} /></td>
                                                <td><Skeleton height={20} width={50} /></td>
                                            </tr>
                                        ))
                                    ) : (
                                        // Actual Segment Rows
                                        segmentList.map((segment) => (
                                            <tr key={segment.id}>
                                                {/* Checkbox Cell */}
                                                <td>
                                                    <Form.Check
                                                        type="checkbox"
                                                        id={`select-segment-${segment.id}`}
                                                        checked={selectedRows.includes(segment.id)}
                                                        onChange={(e) => handleSelectRow(segment.id, e.target.checked)}
                                                    />
                                                </td>
                                                {/* Segment Name Cell */}
                                                <td>
                                                    {/* Removed color indicator span */}
                                                    <span className="prodname">{segment.segment}</span>
                                                </td>
                                                {/* Members Cell (Placeholder - Use real data when available) */}
                                                <td>
                                                    {segment.members_count ?? 0} {/* Display actual count or 0 */}
                                                </td>
                                                {/* Created Cell (Formatted) */}
                                                <td>
                                                    {dateTimeFormatter(segment.created_at, true)} {/* Format actual date */}
                                                    {/* {dateTimeFormatter('2025-03-03 03:21:00')} Placeholder */}
                                                </td>
                                                {/* Actions Cell */}
                                                <td style={{ textAlign: 'center' }}>
                                                    {/* Action Dropdown */}
                                                    <Dropdown>
                                                        <Dropdown.Toggle as={CustomMenuToggle} id={`dropdown-segment-${segment.id}`} />
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item onClick={() => handleShowModal(segment)}>Edit</Dropdown.Item>
                                                            <Dropdown.Item onClick={() => confirmDeleteSegment(segment)} className="text-danger">Delete</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                    {/* Show if not loading and list is empty after filtering/searching */}
                                    {!loading && !hasSegments && (
                                        <tr>
                                            <td colSpan="5" className="text-center py-4"> {/* Adjusted colSpan */}
                                                No segments found matching your criteria.
                                            </td>
                                        </tr>
                                    )}
                                    </tbody>
                                </Table>

                                {/* Footer with Pagination */}
                                <div className="section-footer mt-3 d-flex align-items-center justify-content-between">
                                    {totalRecords > filter.count ? (
                                        <PaginationFooter
                                            from={"segments"} // Custom 'from' prop?
                                            loading={loading}
                                            filter={filter}
                                            setfilter={setFilter}
                                            total={totalRecords}
                                            last_page={segmentsData.last_page}
                                            current_page={segmentsData.current_page}
                                            perPage={filter.count}
                                        />
                                    ) : (
                                        totalRecords > 0 && <span className="text-muted">{totalRecords} results</span>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </section>
            </div>

            {/* Add/Edit Segment Modal */}
            <Modal centered show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton className="create-biolink-header">
                    <Modal.Title className="create-biolink-heading">
                        {editingSegment ? 'Edit Segment' : 'Add New Segment'}
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSaveSegment}>
                    <Modal.Body>
                        <Form.Group className="mb-3" controlId="segmentNameInput">
                            <Form.Label>Segment Name<span className="text-danger">*</span></Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter segment name"
                                value={newSegmentName}
                                onChange={(e) => setNewSegmentName(e.target.value)}
                                required
                                autoFocus
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-end gap-2">
                        <Button variant="secondary" onClick={handleCloseModal} className="btn-grey btn-semi-rounded fs-12px">
                            Cancel <small className="badge bg-light text-dark p-1 ms-1">Esc</small>
                        </Button>
                        <Button
                            type="submit"
                            variant="primary"
                            className="new-primary-btn" // Use custom class if styling needed
                            disabled={!newSegmentName.trim() || loading || (editingSegment && editingSegment.segment === newSegmentName.trim())} // Disable if name empty or unchanged during edit
                        >
                            {editingSegment ? 'Save Changes' : 'Add Segment'}
                            <small className="primary-badge ms-1">
                                <img src={returnIcon} alt="" />
                            </small>
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>


            {/* Confirmation Modal */}
            {confirmationModalProps.show && (
                <NewConfirmationModal
                    {...confirmationModalProps} // Spread all props (show, title, message, etc.)
                    setShow={(showState) => setConfirmationModalProps(prev => ({ ...prev, show: showState }))} // Pass the specific setter for 'show'
                    // Callback is already included in confirmationModalProps
                />
            )}
        </div>
    );
};

export default Segments;