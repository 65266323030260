import axios from 'axios';
import { apiUrl } from '../utils/url';

const apiClient = axios.create({
  baseURL: apiUrl,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
});

// Add request interceptor for auth token
apiClient.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => {
  return Promise.reject(error);
});
apiClient.interceptors.response.use(response => {
  return response.data;  // ✅ Only return `data`, no need to access `data.data`
}, error => {
  return Promise.reject(error);
});

export default apiClient;
