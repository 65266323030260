import * as $ from "jquery";
import returnIcon from "../../assets/img/modals/return.svg";
import imageIcon from "../../assets/img/image-icon.svg";
import tickIconPrimary from "../../assets/img/tick-icon-primary.svg";
import { useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import CroppieModal from "../modals/croppie-modal";
import { hideLoader, showLoader } from "../loader";
import { useSelector } from "react-redux";
import { apiUrl, pageUrl, protocol } from "../../utils/url";
import ErrorMessage from "../errors/errorMessage";
import FormFieldError from "../errors/formFieldError";
import SideBar from "../layout";
import digitalFileIcon from "../../assets/img/products/digitalfileicon.svg";
import calendarIcon from "../../assets/img/products/calendar-icon.svg";
import visitIcon from "../../assets/img/products/visiticon.svg";
import googleMeetIcon from "../../assets/img/products/googleMeetIcon.svg";
import customIcon from "../../assets/img/products/customIcon.svg";
import lightmodeicon from "../../assets/img/products/lightmodeicon.svg";
import removeicon from "../../assets/img/products/removeicon.svg";
import linkdarkicon from "../../assets/img/products/linkdarkimg.svg";
import editicon from "../../assets/img/products/editicon.svg";
import pacleicon from "../../assets/img/products/pancleicon.svg";
import questionIcon from "../../assets/img/products/questionicon.svg";
import addFaqIcon from "../../assets/img/products/add-faq-btn.svg";
import producticon from "../../assets/img/products/product-pageicon.svg";
import urlicon from "../../assets/img/products/urlicon.svg";
import { ReactSortable } from "react-sortablejs";
import Tab from "react-bootstrap/Tab";
import { ChevronRight } from "../../utils/svg";
import dotsIcon from "../../assets/img/products/faq-dots.svg";
import dustbinWhite from "../../assets/img/products/dustbin-white.svg";
import Tabs from "react-bootstrap/Tabs";
import iro from "@jaames/iro";
import timezones from "./timezone.json";
import globeIcon from "../../assets/img/globe.svg"
import leftArrow from "../../assets/img/left-arrow.svg"
import rightArrow from "../../assets/img/right-arrow.svg"
import searchicon from "../../assets/img/products/searchbar.svg"
import chevron_down from "../../assets/img/modals/chevron-down.svg"
import { Accordion, OverlayTrigger, Tooltip } from "react-bootstrap";
import EditorJSModal from "../editorjs/EditorJsModal";
import SaveChanges from "./save-changes";
import "../../styles/monetization.css"
import ConnectCalendarModal from "../modals/connect-calendar-modal";
import Sidebar from "../layout/Sidebar";

var productTemplateMarkup = [];
var emailTemplateMarkup = [];
var editorJSTemplateMarkup = [];
export default function CreateProduct() {
  const productModalReducer = useSelector(state => state.productModalReducer);
  const location = useLocation();
  const [editData, seteditData] = useState({
  maincheckout:"",
  coverimg:"",
  mainfaq:"",
  availabilities_days:"",
  });
  const locationState = location.state || {};
    const {edit, product_id} = locationState;
  const checkmain = location.state
  const encryptedProductId = product_id ?  window.btoa(product_id.toString()): null;
  console.log(checkmain,"checkmai")
  const { checkout1 } = location.state || {};
  let history = useHistory();
  const { slug, basePath } = useSelector((state) => state?.slugReducer);
  const [title, setTitle] = useState("");
  const [short_description, setShort_Description] = useState( "");
  const [product_slug, setProductSlug] = useState("");
  const [cover_image, setCover_Image] = useState([]);
  const [price_type, setpetPaymentValue] = useState("free");
  const [content, setContent] = useState("");
  const [days, setDays] = useState("Mon");
  const [from, setfrom] = useState("");
  const [to, setto] = useState("");
  const [timezone, setTimezone] = useState("");
  const [duration, setDuration] = useState("15");
  const [book_within_next_day, setBook_Within_Next_Day] = useState("7");
  const [custom_meeting_link, setCustomMeetingLink] = useState(null);
  const [break_between_meeting_before, setBreakBetweenMeetingBefore] = useState("15");
  const [break_between_meeting_after, setBreakBetweenMeetingAfter] = useState("20");
  const [meeting_location, setMeetingLocation] = useState("");
  const [product_theme, setProductTheme] = useState("light");
  const [accent_theme, setAccent_Theme] = useState( "#d7ff45");
  // const [buy_button_text, set_buy_button_text] = useState(buybut);
  const [buy_button_text, set_buy_button_text] = useState('Buy Now');
  const [description, setDescription] = useState();
  const [editorjs_template_json, setEditorjs_template_json] = useState();
  const [FAQ_heading, setFAQ_heading] = useState("");
  const [content_file, setContent_File] = useState();
  const [content_url, setContent_Url] = useState('');
  const [id, setId] = useState();
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [uploadImg, setUploadImg] = useState("");
  const [showCroppieModal, setshowCroppieModal] = useState(null);
  const [croppie, setCroppie] = useState(null);
  const [cover_image_url, setcover_image_url] = useState(null);
  const [showSaveChanges, setshowSaveChanges] = useState(false);
  const [showEditorModal, setShowEditorModal] = useState(false);
  const [croppie_image, setcroppie_image] = useState({ url: "", id: null });
  const [price, setPrice] = useState(editData?.mainprice ? editData?.mainprice : 0);
  const [min_price, setMin_Price] = useState(editData?.minprice ? editData?.minprice : 0);
  const [urlCopy, setUrlCopy] = useState(`zaap.bio/${slug}/${product_slug}`);
  const [openPriceInp, setOpenPriceInp] = useState(price_type == "single_payment"? true:false);
  const [openPayInp, setOpenPayInp] = useState(price_type == "Pay_what_you_want" ? true : false);
  const [removepop, setRemoveopop] = useState(false);
  const [urlPop, setUrlPop] = useState(true)
  const [suggested_price, setSugested_Price] = useState(editData?.suggestedprice ? editData?.suggestedprice : 0);
  const [contentText, setContentText] = useState()
  const [error, setError] = useState({});
  const [selectedTimezone, setSelectedTimezone] = useState();
  const [searchInput, setSearchInput] = useState("");
  const [showdasbinimg, setShowdasbinimg] = useState(false);
  const [deleted_cover_image, setDeleted_cover_image] = useState([])
  const [timedropdownOpenFrom, setTimeDropdownOpenFrom] = useState({});
  const [timedropdownOpenTo, setTimeDropdownOpenTo] = useState({});
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [color, setColor] = useState(product_theme == "light" ? "#ffffff" : "#000000");
  const [textcolor, settextcolor] = useState(product_theme == "light" ?  "#000000" : "#ffffff" )
  const [Dolorbutcolor, setDolorbutcolor] = useState(product_theme == "light" ? "#F6F6F7" : "#333131")
  const [showButtonDropdown, setshowButtonDropdown] = useState(false)
  const [linkcolor, setLinkcolor] = useState(product_theme == "light" ? "rgb(246, 246, 247)" : "#606774")
  const [editorjs_template, seteditorjs_template] = useState(null);
  const [template_json, settemplate_json] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isMeetButtonDisabled, setIsMeetButtonDisabled] = useState(true);
  const [isMeetConnected, setIsMeetConnected] = useState(false);
  const [showConnectCalendarModal, setShowConnectCalendarModal] = useState(false);
  const [currency, setCurrency] = useState('usd');
  
  const editProduct = (product_id) => {
    console.log('localStorage',localStorage.getItem("product_id"))
    const api = apiUrl + "/ProductFlow/edit/" + product_id;
    showLoader()
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const setOptions = {
            method: "GET",
            headers: myHeaders,
        }
        fetch(api, setOptions)
        .then(async (res) => {
          const response = await res?.json();
          if (response?.status_code == 200) {
          setProductSlug(response?.data?.slug);
          setTitle(response?.data?.name)
          setShort_Description(response?.data?.short_description)
          setProductTheme(response?.data?.product_theme)
          setAccent_Theme(response?.data?.accent_theme)
          set_buy_button_text(response?.data?.buy_button_text)
          setFAQ_heading(response?.data?.FAQ_heading)
          setPrice(response?.data?.price)
          setMin_Price(response?.data?.price)
          setSugested_Price(response?.data?.suggested_price)
          setCurrency(response?.data?.currency ?? '')
          setContent(response?.data?.content);
          setContent_Url(response?.data?.content_url)
          setMeetingLocation(response?.data?.calendar[0]?.meeting_location)
          setCustomMeetingLink(response?.data?.calendar[0]?.custom_meet_link)
          setDuration(response?.data?.calendar[0]?.duration)
          setBook_Within_Next_Day(response?.data?.calendar[0]?.book_within_next_day)
          setSelectedTimezone(response?.data?.calendar[0]?.timezone)
          setTimezone(response?.data?.calendar[0]?.timezone)
          setProductTheme(response?.data?.product_theme),
          setColor(response?.data?.product_theme == "light" ? "#ffffff" : "#000000");
          settextcolor(response?.data?.product_theme == "light" ?  "#000000" : "#ffffff" )
          setDolorbutcolor(response?.data?.product_theme == "light" ? "#F6F6F7" : "#333131")
          setLinkcolor(response?.data?.product_theme == "light" ? "rgb(246, 246, 247)" : "#606774")
          setpetPaymentValue(response?.data?.price_type)
          setContentText(response?.data?.content_file_name)
          setRemoveopop(response?.data?.content_file_name ? true : false);
          seteditorjs_template(response?.data?.editorjs_template_json);
          settemplate_json(response?.data?.description);
          setIsMeetConnected(response?.data?.meet_connected);
          seteditData({
            maincheckout: response?.data?.checkoutquestion,
            coverimg: response?.data?.cover_images,
            mainfaq: response?.data?.product_faqs,
            availabilities_days: response?.data?.availabilities_days,
          });
          if (response.data.cover_image) {
            seteditData((prevData) => ({
              ...prevData,
              coverimg: [...prevData.coverimg, {images:response.data.cover_image}],
            }));
          }
        }
        hideLoader();
      }).catch(() => {
        hideLoader();
      });
  }
  useEffect(() => {
    if (product_id) {
      editProduct(product_id);
    }else if(localStorage?.product_id){
      editProduct(localStorage.getItem("product_id"));
    }
    }, [edit]);

  const getDayName = (dayIndex) => {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thurs', 'Fri', 'Sat'];
    return days[dayIndex];
};
const [timeSlotsPerDay, setTimeSlotsPerDay] = useState(
  Array.from({ length: 7 }, (_, dayIndex) => ({
    id: dayIndex,
    day: getDayName(dayIndex),
    slots: Array.from({ length: 3 }, (_, slotIndex) => ({
      id: `${dayIndex}-${slotIndex}`,
      time: null,
    })),
    showDropdown: false,
  }))
);
const numberOfSlots = editData?.availabilities_days ? editData?.availabilities_days.length : 8 ;
const [defaultTimeSlots, setDefaultTimeSlots] = useState(
  Array.from({ length: 7 }, (_, dayIndex) => ({
      id: dayIndex,
      day: getDayName(dayIndex),
      slots: Array.from({ length: numberOfSlots }, (_, slotIndex) => ({
          id: `${dayIndex}-${slotIndex}`,
          time: 9 + slotIndex,
      })),
      showDropdown: false, // New property
  }))
);
useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = '';
    };
    window.addEventListener('beforeunload', handleBeforeUnload, {
    });
    window.onbeforeunload = null;
    
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
   
    };
  }, []);
  const initialUrlRef = useRef(window.location.href);

  useEffect(() => {
    const handleUrlChange = () => {
      if (window.location.href !== initialUrlRef.current) {
        alert('URL has changed!');
      }
    };
    
    window.addEventListener('popstate', handleUrlChange);
    
    return () => {
      window.removeEventListener('popstate', handleUrlChange);
    };
  }, []);
  const getTimeSlotsByDay = (availabilities) => {
    const timeSlotsByDay = {};
    availabilities.forEach((availability) => {
      const { days, from, to } = availability;
      if (!timeSlotsByDay[days]) {
        timeSlotsByDay[days] = [];
      }
      timeSlotsByDay[days].push({ from, to });
    });
    return timeSlotsByDay;
  };

  const updateStateWithAvailabilities = (availabilities) => {
  const updatedTimeSlots = defaultTimeSlots.map((daySlot) => {
    const dayAvailabilities = availabilities.filter(
      (availability) => availability.days.toLowerCase() === daySlot.day.toLowerCase()
    );
  
    if (dayAvailabilities.length > 0) {
      return {
        ...daySlot,
        slots: daySlot.slots.map((slot, slotIndex) => {
          if (dayAvailabilities[slotIndex]) {
            return {
              ...slot,
              time: dayAvailabilities[slotIndex].from,
            };
          } else {
            return {
              ...slot,
              time: false,
            };
          }
        }),
      };
    } else {
      return {
        ...daySlot,
        slots: daySlot.slots.map((slot) => ({
          ...slot,
          time: false,
        })),
      };
    }
  });
  setDefaultTimeSlots(updatedTimeSlots);
  };

  useEffect(() => {
    if (editData?.availabilities_days) {
      updateStateWithAvailabilities(editData?.availabilities_days);
    }
  }, [editData?.availabilities_days]);

  function formatTime(time) {
    const formattedTime = String(time);
    
    if (formattedTime.includes(':')) {
      return formattedTime;
    } else {
      return formattedTime.padStart(2, '0') + ':00';
    }
  }

  const [faqs, setFaqs] = useState([
    {
      questions: "",
      answers: "",
    },
  ]);

  const [checkout_question, setCheckout_Question] = useState([
    {
      questions: "",
      isRequired: false,
    },
  ]);

  useEffect(() => {
    if (editData?.mainfaq) {
      setFaqs([...editData?.mainfaq])
    }
  }, [editData?.mainfaq]);

  useEffect(() => {
    if (editData?.coverimg) {
      setCover_Image([...editData?.coverimg]);
    }
  }, [editData?.coverimg]);

  const handleSelectTab = (key) => {
    setDays(key);
  };

  useEffect(() => {
    if (cover_image_url) {
      setCover_Image([...cover_image, cover_image_url]);
    }
  }, [cover_image_url]);

  const [openCalendar, setOpenCalendar] = useState(
    checkmain?.checkout1 || checkout1 ? true : false
  );
  useEffect(() => {
  if(checkmain?.checkout1 || checkout1 ){
   setContent("calendar") 
  }
  else if(checkmain?.checkout2 ){
    setContent("files")
  }
  },[]);
  const [opendigitalfile, setOpendigitalfile] = useState(
    checkmain?.checkout2 ? true : false
  );
  const [openVisitUrl, setOpenVisitUrl] = useState(false);
  let ejInstance = useRef();
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(true)
  const [sharedProjects, setSharedProjects] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState(null)
  const getProjects = () => {
    // showLoader();
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
    fetch(apiUrl + "/project/get-all", { headers: myHeaders })
        .then(async (res) => {
            const response = await res.json();
            setProjects(response?.data.projects)
            setSharedProjects(response?.data?.shared_projects)
            setSelectedProfile(response?.data.projects[0]?.id)
            setSelectedProject(true)
            // hideLoader()
        }).catch((err) => {
            
// console.log(err)
            // setisProjectLoading(false)
            // hideLoader()
        })
}
useEffect(() => {
  if (projects?.length == 0)
      getProjects()
  else localStorage.removeItem("sendRequest")
}, []);
  // let editorConfig;
  // let TEMPLATE_EDIT = {
  //   time: new Date().getTime(),
  //   blocks: null,
  // };
  const apiName = edit || localStorage?.edit ? "update" : "createProduct";
  sessionStorage.removeItem("isEdit");

  useEffect(() => {
    const handleMessage = (event) => {

      if (event.data && event.data.type === 'meetToken') {

        setIsMeetConnected(!!event.data.meetToken);
        setShowConnectCalendarModal(false);
      }
    };
    window.addEventListener('message', handleMessage);
  
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    const findSlug = projects.find(item => item.slug === slug);
      if (findSlug && findSlug.meet_token) {
        setIsMeetConnected(true);
      }else{
        setIsMeetConnected(false);
      }
  },[projects])

  async function handleSave() {

    if (meeting_location == 'google' && !isMeetConnected) {
      setShowConnectCalendarModal(true);
      return false;
    }
    showLoader();
    setError({});
    const obj = {
      faqs: JSON.stringify(faqs),
      currency,
      days,
      monTimeSlot: JSON.stringify(defaultTimeSlots[1].slots),
      tueTimeSlot: JSON.stringify(defaultTimeSlots[2].slots),
      wedTimeSlot: JSON.stringify(defaultTimeSlots[3].slots),
      thurTimeSlot: JSON.stringify(defaultTimeSlots[4].slots),
      friTimeSlot: JSON.stringify(defaultTimeSlots[5].slots),
      satTimeSlot: JSON.stringify(defaultTimeSlots[6].slots),
      sunTimeSlot: JSON.stringify(defaultTimeSlots[0].slots),
      title,
      short_description,
      product_slug,
      content,
      duration,
      timezone,
      book_within_next_day,
      break_between_meeting_before,
      break_between_meeting_after,
      meeting_location,
      price_type,
      product_theme,
      accent_theme,
      buy_button_text,
      FAQ_heading,
      content_file,
      content_url,
      price,
      min_price,
      checkout_question: JSON.stringify(checkout_question),
      suggested_price,
      deleted_cover_image,
      custom_meeting_link
    };
    emailTemplateMarkup = template_json;
    editorJSTemplateMarkup = editorjs_template;

    const api = apiUrl + "/ProductFlow/" + apiName;
    const formdata = new FormData();
        for (const key in obj) {
      if (Object.hasOwnProperty.call(obj, key)) {
        if (key === "content") {
          if (obj[key] === "files") {
              if (typeof ["content_file"] === "string") {
                  delete obj["content_file"];
              }
              delete obj["content_url"];
          }
        }
        formdata.append(key, obj[key]);
    // let temp_obj = { ...obj }
    // for (const key in temp_obj) {
    //   if (Object.hasOwnProperty.call(temp_obj, key)) {
    //     if (key === "content") {
    //       if (temp_obj[key] === "files") {
    //           if (typeof temp_obj["content_file"] === "string") {
    //               delete temp_obj["content_file"];
    //           }
    //           temp_obj["content_url"] = null;
    //       }
    //   }
    //     if (temp_obj[key]) formdata.append(key, temp_obj[key]);
        console.log(key, obj[key]);
      }
    }



    formdata.append("project", slug);
    cover_image.map((val, key) => {
      console.log("productTemplateMarkup", new File([val.img], "image.png"));
      if (val?.img) {
        formdata.append("cover_image[]", new File([val.img], "image.png"));
      }
    });
    formdata.append("description", JSON.stringify(productTemplateMarkup));
    formdata.append('template', emailTemplateMarkup);
    formdata.append("editorjs_template", editorJSTemplateMarkup);
    formdata.append('product_id',product_id ?? localStorage?.product_id);
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage?.getItem("token")}`
    );
    const setOptions = {
      method: "POST",
      body: formdata,
      headers: myHeaders,
    };
    fetch(api, setOptions)
      .then(async (res) => {
        const response = await res?.json();
        if (response?.status_code == 200) {
          console.log(basePath + "/products", "basepath");
          history.push(basePath + "/products");
          hideLoader();
          toast.success(`Product ${apiName == "update" ? 'Updated' : 'Created'} Successfully `)
        } else {
          setError(Object.values(response?.errors));
          if (Object.keys(response?.errors).length > 0)
            $(".modal-body").scrollTop(0);
          hideLoader();
        }
      })
      .catch(() => {
        hideLoader();
      });
  }
  const handleCancel = () => {
    if (Object.keys(productModalReducer).some((key) => productModalReducer[key])) {
      setshowSaveChanges(true);
    }
  }
  console.log("showEditorModal", showEditorModal);
  const copyingText = () => {
    navigator.clipboard
      .writeText(`${pageUrl}/${slug}/${product_slug}`)
      .then((r) => toast.success("✅ Copied and ready to paste!", { icon: false,}));
  };
  const el = useRef();
  let colorWheel4;
  let typingTimer4; //timer identifier
  let doneTypingInterval = 500;

  useEffect(() => {
    colorWheel4 = new iro.ColorPicker("#colorWheelDemo4", {
      width: 100,
      color: accent_theme,
      borderWidth: 1,
      borderColor: "#fff",
      display: "flex",
    });

    colorWheel4.on("color:change", function (color, changes) {
      $("#hex_code4").val(color.hexString);
      $("#rgb_code4").val(
        colorWheel4.color.rgbString.replace("rgb(", "").replace(")", "")
      );
      setAccent_Theme(color.hexString);
    });

    $("#hex_code4").keyup(function () {
      clearTimeout(typingTimer4);
      if ($(this).val()) {
        typingTimer4 = setTimeout(() => {
          colorWheel4.color.hexString = $(this).val();
        }, doneTypingInterval);
      }
    });
  }, []);

  function handleQuestion() {
    var newdata = [...checkout_question];
    newdata.push({ questions: "", isRequired: false });
    setCheckout_Question(newdata);
  }

  function handleDuplicate(i) {
    setId(i);
    const tempFaq = [...faqs];
    tempFaq.splice(i + 1, 0, tempFaq[i]);
    setFaqs([...tempFaq]);
  }

  function handleadd() {
    var newdata = [...faqs];
    newdata.push({ question: "", answer: "" });
    setFaqs(newdata);
  }

  function handleCalendar() {
    setContent("calendar");
    if (checkmain) {
      checkmain.checkout1 = true;
      checkmain.checkout2 = false;
    }
    setOpendigitalfile(false);
    setOpenCalendar(true);
    setOpenVisitUrl(false);
  }

  function handldigital() {
    setContent("files");
    if (checkmain) {
      checkmain.checkout1 = false;
      checkmain.checkout2 = true;
    }
    setOpendigitalfile(true);
    setOpenCalendar(false);
    setOpenVisitUrl(false);
  }
  function handlevisiturl() {
    if (checkmain) {
      checkmain.checkout1 = false;
      checkmain.checkout2 = false;
    }
    setContent("url");
    setOpenVisitUrl(true);
    setOpenCalendar(false);
    setOpendigitalfile(false);
  }

  function handleDelete(i) {
    const newarr = [...faqs];
    newarr.splice(i, 1);
    setFaqs(newarr);
  }

  function handlefreefun() {
    setMin_Price(0);
    setPrice(0);
    setSugested_Price(0);
    setOpenPayInp(false);
    setOpenPriceInp(false);
  }

  function handlememberfun() {
    setOpenPayInp(false);
    setOpenPriceInp(false);
  }

  function handleRemoveUrl() {
  // if (content !== "files"){
    setContentText(null);
    setContent_File(null);
    setRemoveopop(false);
  // }
    // setUrlPop(false)
  }
  function generate_image_preview(input) {
    showLoader();
    if (input.files && input.files[0]) {
      let reader = new FileReader();
      if (input.files[0].size >= 5242880) {
        $("#image_file_input").val("");
        let notification_container = $(".notification-container");
        toast.error("File Size Should not exceed 1MB");
        // showModal();
        return;
      }
      let val = input.files[0].name;
      let valid_extensions = ["png", "jpg", "jpeg"];
      let extension =
        val.substring(val.lastIndexOf(".") + 1, val.length) || val;
      if (!valid_extensions.includes(extension?.toLowerCase())) {
        $("#image_file_input").val("");
        let notification_container = $(".notification-container");
        toast.error("Invalid Image File. Should be png, jpg or jpeg");
        // showModal();
        return;
      }
      reader.onload = (event) => {
        setcroppie_image({
          ...croppie_image,
          url: event.target.result,
        });
        console.log(setcroppie_image);
        console.log("setcroppie_image", croppie_image);
        setshowCroppieModal(true);
        setTimeout(() => {
          const el = document.getElementById("image_demo2");
          try {
            console.log("el", el);
            if (el) {
              var croppieInstance = new Croppie(el, {
                enableExif: true,
                viewport: {
                  width: 850,
                  height: 470,
                  type: "square",
                },
                boundary: {
                  width: 870,
                  height: 570,
                },
              });
              croppieInstance
                .bind({
                  url: event.target.result,
                })
                .then(function () {
                  console.log("jQuery bind complete");
                  setTimeout(() => { }, 300);
                })
                .catch(() => {
                  croppieClose();
                });
              setCroppie(croppieInstance);
            }
          } catch { }
        }, 500);
      };
      reader.readAsDataURL(input.files[0]);
    }
  }

  const croppieClose = () => {
    showLoader();
    setshowCroppieModal(false);
    setCroppie(null);
    setTimeout(() => {
      hideLoader();
    }, 400);
  };

  const croppedImage = () => {
    if (croppie !== null) {
      croppie
        .result({
          type: "canvas",
          size: "original",
          format: "png",
          quality: 1,
        })
        .then((response) => {
          const tempObj = { image_url: response };
          croppie
            .result({
              size: "viewport",
              format: "png",
              quality: 1,
              type: "blob",
            })
            .then((response) => {
              setCover_Image([
                ...cover_image,
                {
                  ...tempObj,
                  img: response
                }]
              )
              croppieClose()
            });
        });
    }
  };

  function Removecardfun({ i, id }) {
    setDeleted_cover_image([...deleted_cover_image, id])
    if (!id) {
      setDeleted_cover_image([...deleted_cover_image, 'old_cover_image'])
    }
    const covimg = [...cover_image]
    covimg.splice(i, 1)
    setCover_Image(covimg)
  }

  const handleHideModal = () => {
    setshowCroppieModal(false);
    setCroppie(null);
    setcroppie_image({ url: "", id: null });
    // history.push(basePath + "/products");
  };
  useEffect(() => {
    if (editData?.maincheckout) {
        const updatedCheckoutQuestion = editData?.maincheckout.map(item => ({
            questions: item.questions,
            isRequired: item.isrequired == true, // Convert "true" to true, and "false" to false
        }));

        setCheckout_Question(updatedCheckoutQuestion);
    }
}, [editData?.maincheckout]);

  function handleCheckFun(a, i) {
    setId(i);
    const questionscheck = a.target.value;
    const tempCheckoutQuestion = [...checkout_question];
    const currentObj = { ...tempCheckoutQuestion[i] };
    currentObj.questions = questionscheck;
    tempCheckoutQuestion[i] = currentObj;
    setCheckout_Question([...tempCheckoutQuestion]);
  }

  const delete_question = (index) => {
    setCheckout_Question(prevState => {
        const updatedQuestions = [...prevState];
        updatedQuestions.splice(index, 1); // Remove the element at the specified index
        return updatedQuestions;
    });
  };

  function handleswitch(a, i) {
    const isRequired = a.target.checked;
    const tempCheckoutQuestion = [...checkout_question];
    const currentObj = { ...tempCheckoutQuestion[i] };
    currentObj.isRequired = isRequired;
    // currentObj.isRequired = !currentObj.isRequired;
    tempCheckoutQuestion[i] = currentObj;
    setCheckout_Question([...tempCheckoutQuestion]);
  }
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    if (event.target.files[0].size >= 5242880) {
      $("#image_file_input").val("");
      let notification_container = $(".notification-container");
      toast.error("File Size Should not exceed 1MB");
      return;
    }
    let val = event.target.files[0].name;
    let valid_extensions = ["png", "jpg", "jpeg"];
    let extension = val.substring(val.lastIndexOf(".") + 1, val.length) || val;
    if (!valid_extensions.includes(extension?.toLowerCase())) {
      $("#image_file_input").val("");
      let notification_container = $(".notification-container");
      toast.error("Invalid Image File. Should be png, jpg or jpeg");
      showModal();
      return;
    }
    reader.onload = (event) => {
      const dataURL = event.target.result;
      setCover_Image(dataURL);
    };
    reader.readAsDataURL(file);
  };
  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }
    return new Blob([arrayBuffer], { type: mimeString });
  };

  const handleopenmodal = (e) => {
    setcroppie_image({ url: "", id: null, name: null, type: null });
    console.log("croppie_image", croppie_image);
    generate_image_preview(e.target);
    hideLoader();
  };

  function handlepriceopop() {
    setMin_Price(0);
    setSugested_Price(0);
    setOpenPriceInp(true);
    setOpenPayInp(false);
  }
  function handlepayfun() {
    setOpenPayInp(true);
    setOpenPriceInp(false);
  }

  const handleAddTimeSlot = (day) => {
    const tempTimeSlots = { ...timeSlotsPerDay };
    tempTimeSlots[day].push({
      from: "",
      to: "",
    });
    setTimeSlotsPerDay(tempTimeSlots);
  };

  const handleQuestionFaq = (a, i) => {
    const questions = a.target.value;
    const tempFaq = [...faqs];
    const currentObj = { ...tempFaq[i] };
    currentObj.questions = questions;
    tempFaq[i] = currentObj;
    setFaqs([...tempFaq]);
  };

  const handleAnswerFaq = (a, i) => {
    const answers = a.target.value
    let tempFaq = [...faqs];
    const currentObj = { ...tempFaq[i] };
    currentObj.answers = answers;
    tempFaq[i] = currentObj;
    setFaqs([...tempFaq]);
  };

  const [inputColor, setInputColor] = useState("#ffffff");
  const onColorChange = (color) => {
    dispatch({
      type: "SET_CUSTOM_COLOR",
      payload: {
        ...color,
        [themeType]: color.hexString,
      },
    });
  };
  useEffect(() => {
   if (removepop) {
    setUrlPop(false);
  }   
  else{
    setUrlPop(true);
  }
  }, [removepop]);

  function handleContentfun(e) {
    setContent_File(e.target.files[0])
    setContentText(e.target.files[0]?.name)
    setRemoveopop(true)
    setUrlPop(false)
  }
  const handleSelectTimezone = (timezone) => {
    setSelectedTimezone(timezone);
    setTimezone(timezone.city + ' GMT' + timezone.gmtOffset);
    setDropdownOpen(false);
  };
  const handleSelectTime = (from, i, day) => {
    const tempTimeSlots = { ...timeSlotsPerDay };
    tempTimeSlots[day][i].from = from;
    setTimeSlotsPerDay(tempTimeSlots);
    setTimeDropdownOpenFrom(false);
  };
  const handleSelectTimeTo = (to, i, day) => {
    const tempTimeSlots = { ...timeSlotsPerDay };
    tempTimeSlots[day][i].to = to;
    setTimeSlotsPerDay(tempTimeSlots);
    setTimeDropdownOpenTo(false);
  };
  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };
  function lightmodelfun() {
    setColor("#ffffff")
    settextcolor("#000000")
    setDolorbutcolor("#F6F6F7")
    setLinkcolor("#F6F6F7")
  }
  function darkmodelfun() {
    setColor("#000000")
    settextcolor("#ffffff")
    setDolorbutcolor("#333131")
    setLinkcolor("#606774")
  }
  // function Moveproductfun() {
  //   history.push("products")
  // }

  const handleTitleChange = (e) => {
    setTitle(e.target.value)
    let slug = e.target.value.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '-');
    slug = slug.replaceAll(' ', '-');
    setProductSlug(slug)
  }

  const filteredTimezones = timezones.filter((timezone) =>
    timezone.city.toLowerCase().includes(searchInput.toLowerCase())
  );

  const openEditorModal = () => {
    setShowEditorModal(true)
  };
  const closeEditorModal = () => {
    console.log("Hide MOdal")
    setShowEditorModal(false)
  };
  const closeSaveChanges = () => {
    setshowSaveChanges(false)
  };
  useEffect(() => {
    if (content === "calendar") {
     handleCalendar();
   }   
   else if(content === "files"){
    handldigital();
   }else if(content === "url"){
    handlevisiturl();
   }
   }, [content]);
   const handlePriceChange = (e) => {
    const sanitizedValue = e.target.value.replace(/[^0-9.]/g, '');
  
    const singleDecimalValue = sanitizedValue.replace(/(\..*?)\..*/g, '$1');
  if(e.target.name === "price"){
    setPrice(singleDecimalValue);
    setMin_Price(0);
    setSugested_Price(0);
  }
  else if(e.target.name === "min_price"){
  setMin_Price(singleDecimalValue);
  setPrice(0);
  }
  else if(e.target.name === "suggested_price"){
    setSugested_Price(singleDecimalValue);
    setPrice(0);
  }
  };
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % cover_image.length);
  };
  const prevImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + cover_image.length) % cover_image.length);
  };
  const handleCalendarCondition = (e) => {
    const sanitizedValue = e.target.value.replace(/[^0-9.]/g, '');
  
    const singleDecimalValue = sanitizedValue.replace(/(\..*?)\..*/g, '$1');
    if (e.target.name === "duration") {
      setDuration(singleDecimalValue);
    }
    else if (e.target.name === "book_with_in_next"){
      setBook_Within_Next_Day(singleDecimalValue);
    }
  }
  const getTextColor = (backgroundColor) => {
    // Convert background color to RGB format
    const rgbColor = hexToRgb(backgroundColor);
    // Calculate the relative luminance of the color (for contrast)
    const relativeLuminance = (0.2126 * rgbColor.r + 0.7152 * rgbColor.g + 0.0722 * rgbColor.b) / 255;
  
    // Use a light color for dark backgrounds, and a dark color for light backgrounds
    return relativeLuminance > 0.5 ? '#000000' : '#FFFFFF';
  };
  const hexToRgb = (hexColor) => {
    const hex = hexColor.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return { r, g, b };
  };

  function slugify(text, divider = '-') {
    // replace non letter or digits by divider
    text = text.replace(/[^\p{L}\d]+/gu, divider);

    // transliterate
    text = text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    
    // remove unwanted characters
    text = text.replace(/[^-\w]+/g, '');

    // trim
    text = text.replace(new RegExp(`^${divider}+|${divider}+$`, 'g'), '');

    // remove duplicate divider
    text = text.replace(new RegExp(`${divider}+`, 'g'), divider);

    // lowercase
    text = text.toLowerCase();

    if (!text) {
        return 'n-a';
    }

    return text;
}
    const generateTimeSlots = (duration) => {
      const timeSlots = [];
      const endTime = 23 * 60 + 59; // Convert end time to minutes
      duration = parseInt(duration);   
      for (let totalMinutes = 0; totalMinutes <= endTime; totalMinutes += duration) {
        const hour = Math.floor(totalMinutes / 60);
        const minute = totalMinutes % 60;
        
        const formattedHour = String(hour).padStart(2, '0');
        const formattedMinute = String(minute).padStart(2, '0');
        
        timeSlots.push(`${formattedHour}:${formattedMinute}`);
      }
            
      return timeSlots;
    };

    const removeAddTimeSlot = (i , slotId) => {
      // const tempTimeSlots = { ...timeSlotsPerDay };
      // tempTimeSlots[day].splice(i, 1);
      // setTimeSlotsPerDay(tempTimeSlots);
      const updatedTimeSlots = timeSlotsPerDay.map((day) => {
        console.log("dayId",slotId,"i",i)
        if (i) {
            const updatedSlots = day.slots.filter((slot) => slot.id !== i);
            return { ...day, slots: updatedSlots, showDropdown: false };
        }
        return day;
    });

    setDefaultTimeSlots(updatedTimeSlots);
    };

    const handleDeleteTimeSlot = (dayId, slotId) => {
      const updatedTimeSlots = defaultTimeSlots.map((day) => {
          if (day.id === dayId) {
              const updatedSlots = day.slots.filter((slot) => slot.id !== slotId);
              return { ...day, slots: updatedSlots, showDropdown: false };
          }
          return day;
      });

      setDefaultTimeSlots(updatedTimeSlots);
    };
    const handleNewAddTimeSlot = (dayId) => {
      const updatedTimeSlots = defaultTimeSlots.map((day) => {
          if (day.id === dayId) {
              return { ...day, showDropdown: !day.showDropdown };
          }
          return day;
      });

      setDefaultTimeSlots(updatedTimeSlots);
    };
    const handleDropdownSelect = (dayId, selectedTime) => {
      const updatedTimeSlots = defaultTimeSlots.map((day) => {
          if (day.id === dayId) {
              const newSlotId = `${day.id}-${day.slots.length}`;
              const newSlot = {
                  id: newSlotId,
                  time: selectedTime,
              };
              const updatedSlots = [...day.slots, newSlot];
              return { ...day, slots: updatedSlots, showDropdown: false };
          }
          return day;
      });

      setDefaultTimeSlots(updatedTimeSlots);
    };
    const isSlotDisabled = (slot, dayID) => {
      for (const day of defaultTimeSlots) {
        if (dayID == day.id) {
        for (const existingSlot of day.slots) {
          const existingTime = existingSlot.time < 10 ? `0${existingSlot.time}` : existingSlot.time;
          if (existingTime === slot || `${existingTime}:00` === slot) {
            return true;
          }
        }
      }
    }
      return false;
    };
    console.log("location.pathname",location.pathname)
    const handleMeetButtonClick = async () => {
      try {
          localStorage.setItem("last_activity", location.pathname)
          localStorage.setItem("product_id", product_id)
          localStorage.setItem("edit", edit)
          // Make the API call
          const response = await fetch(apiUrl + "/google-meet?redirectUrl="+localStorage.getItem("last_activity")+'&product_id='+encryptedProductId, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });

          if (response.status === 200) {
            const responseData = await response.json();
                // Redirect to the OAuth URL
            window.location.href = responseData.data.oauth_url;
              } else {
                // Handle errors here
                console.error("API Error:", response.statusText);
                hideLoader();
              }

      } catch (error) {
        // Handle fetch or other errors
        console.error("Error:", error);
        hideLoader();
      }
    };
  return (
    <div id="wrapper" className="main-wrapper">
      <PageHeader />
      <div class="monetization-main">
        <Sidebar />
        <div className="create-product-main" style={{ width: "100%" ,overflowY: "auto" ,overflowX: "hidden"}}>
          <div className="row monetization-margin" >
            <div className="col-md-7  noHorizontalPadding main-width">
              <div
                className=""
                id="newProductModal"
                role="document"
                style={{ margin: 0, height: "100%", maxWidth: '700px' }}
              >
                <div
                  className="modal-content p-0 zaap-border-straight remove-background"
                  style={{ height: "100%", background: "none" }}
                >
                  <div
                    className="modal-header "
                    style={{ border: "none" }}
                  >
                    <h3 className="modal-title create-biolink-heading">
                      {" "}
                      {edit ? "Edit" : "Create"} Product
                    </h3>
                  </div>
                  <div className="modal-body px-4 pb-4 pt-1 modal-body-main">
                    <ErrorMessage error={error} />
                    <div class="form-group parent-producttoggle">
                      <div>
                        <Tabs
                          defaultActiveKey="home"
                          id="justify-tab-example"
                          className="mb-3 up-in-toggle main-btn-header btnTxtDark border-less"
                          justify
                        >
                          <img width="20%" src={editicon} />
                          <Tab
                            eventKey="home"
                            title={
                              <div className="d-flex align-items-center justify-content-center">
                                <img
                                  src={pacleicon}
                                  className="product-icon"
                                  width="25px"
                                  style={{ marginRight: 10 }}
                                />
                                <label className="text-gray font-weight-500 cursor-pointer product-tab" style={{ fontSize: 18, whiteSpace: "nowrap" }}>Product Details</label>
                              </div>
                            }
                            className="product-nav-link"
                          >
                            <div className="accordion-item borderless mt-4">
                              <div
                                id="productDetails"
                                className="accordion-collapse collapse show"
                                aria-labelledby="headingOne"
                              >
                                <div className="form-group mt-3">
                                  <label className="text-gray font-weight-500 input-text">
                                    Product Title
                                    <small className="text-red">*</small>
                                  </label>
                                  <input
                                    value={title}
                                    onChange={(e) => handleTitleChange(e)}
                                    type="text"
                                    className="form-control zaap-form-control man-inp"
                                    name="title"
                                    placeholder="My Awesome Product"
                                  />
                                  <FormFieldError error={error?.title} />
                                </div>
                                <div className="form-group mt-4">
                                  <label className="text-gray font-weight-500 input-text">
                                    Short Description
                                    <small className="text-red">*</small>
                                  </label>
                                  <input
                                    value={short_description}
                                    onChange={(a) =>
                                      setShort_Description(a.target.value)
                                    }
                                    type="text"
                                    className="form-control zaap-form-control man-inp"
                                    name="short_description"
                                    placeholder="Got my new awesome course!"
                                  />
                                </div>
                                <div className="form-group mt-4">
                                  <label className="text-gray font-weight-500 input-text">
                                    Product Description
                                  </label>
                                  <button type="button" class="mt-2 close btn btn-semi-rounded me-2 d-flex align-items-center bg-white justify-content-center py-2 px-3 btn-shadowed" onClick={openEditorModal}>
                                    <b>Edit Product Description</b>
                                  </button>
                                  {/* <div className="product-description-main">
                                    <div className="product-description-body pt-0">
                                      <div
                                        id="email-body-template-field"
                                        className="col-12 email-body-template-field py-md-2 px-md-4 py-2 px-2"
                                      ></div>
                                      <div className="product-description-input-main px-2">
                                        <div id="editor"> </div>
                                      </div>
                                    </div>
                                  </div> */}
                                </div>
                                <div className="form-group mt-4">
                                  <label className="text-gray font-weight-500 input-text">
                                    URL
                                  </label>
                                  <div className="url-dotted">
                                    <div style={{ display: "flex" }}>
                                      <div>
                                        <img
                                          className="cursor-pointer"
                                          src={producticon}
                                          width="20px"
                                          alt=""
                                          onClick={() =>
                                            copyingText()
                                          }
                                        />
                                      </div>
                                      <div
                                        style={{
                                          color: "#5B5B5B",
                                          fontWeight: "bold",
                                          marginLeft: 5,
                                        }}
                                      >
                                        zaap.bio/{slug}/
                                      </div>
                                      <div>
                                        <input
                                          className="input-style"
                                          maxLength={20}
                                          value={product_slug?.toLowerCase()}
                                          onChange={(a) =>
                                            setProductSlug(a.target.value)
                                          }
                                          autoComplete="off"
                                          type="text"
                                          name="short_description"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group mt-4">
                                  <label className="text-gray font-weight-500 input-text">
                                    Upload image
                                  </label>
                                  <div className="preview-zone hidden "></div>
                                  <div
                                    style={{
                                      width: "100%",
                                      height: 140,
                                      marginTop: 5,
                                    }}
                                    className="dropzone-wrapper dropzone-wrapper4 product-dropzone mb-2"
                                  >
                                    <>
                                      <div className="dropzone-desc">
                                        <img src={imageIcon} alt="" />
                                        <h6 className="mt-2 mb-1 text-gray">
                                          Drag and drop files, or{" "}
                                          <a className="text-primary decoration-none">
                                            click here to browse
                                          </a>
                                        </h6>
                                        <small className="text-muted-light">
                                          1MB max file size upload
                                        </small>
                                      </div>
                                      <input
                                        type="file"
                                        className="dropzone dropzone4"
                                        name="cover_image"
                                        accept=".png,.jpg,.jpeg"
                                        id="image_file_input"
                                        onChange={handleopenmodal}
                                      />
                                    </>
                                  </div>
                                </div>
                                <div >
                                  <ReactSortable style={{ display: "flex", flexWrap: "wrap", justifyContent: "start" }} list={cover_image} setList={setCover_Image}>
                                    {cover_image?.map((e, i) => {
                                      return <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}><div key={i}> <img draggable
                                        src={e.image_url ? e.image_url : e.images} width={"200px"} style={{ marginLeft: 4, borderRadius: 13, marginTop: 10 }} />
                                      </div>
                                        <div>
                                          <img onClick={() => Removecardfun({ i, id: e.id })} src={removeicon} width={"22px"} style={{ padding: 4, cursor: "pointer" }} />

                                        </div>
                                      </div>;
                                    })}

                                  </ReactSortable>
                                </div>
                                <div
                                  id="productPricing"
                                  className="form-group"
                                  aria-labelledby="headingOne"
                                >
                                  <div className="form-group w-100 p-0 mt-3">
                                    <label className="text-gray font-weight-500 input-text">
                                      Pricing
                                    </label>
                                    <div className="up-in-toggle bg-white mt-2 p-0">
                                      <input
                                        checked={price_type == 'free'? true :false }
                                        type="radio"
                                        id="switch_left"
                                        name="pricingType"
                                        value="free"
                                        onChange={(a) =>
                                          setpetPaymentValue(a.target.value)
                                        }
                                      />
                                      <label
                                        onClick={handlefreefun}
                                        htmlFor="switch_left"
                                        className="d-flex align-items-start flex-column"
                                      >
                                        <span className="w-100 d-flex align-items-center justify-content-between">
                                          <h5
                                            className="m-0 font-weight-500 pricing-text"
                                          >
                                            Free
                                          </h5>
                                          <img
                                            className="mb-3 pt-1"
                                            width="17px"
                                            src={tickIconPrimary}
                                            alt=""
                                          />
                                        </span>
                                        <h6 className="text-left text-muted-light">
                                          Allow access for free.
                                        </h6>
                                      </label>
                                      <input
                                      checked={price_type == 'single_payment'? true :false }
                                        type="radio"
                                        id="switch_right"
                                        name="pricingType"
                                        value="single_payment"
                                        onChange={(a) =>
                                          setpetPaymentValue(a.target.value)
                                        }
                                      />
                                      <label
                                        onClick={handlepriceopop}
                                        htmlFor="switch_right"
                                        className="d-flex align-items-start flex-column"
                                      >
                                        <span className="w-100 d-flex align-items-center justify-content-between">
                                          <h5
                                            className="m-0 font-weight-500 pricing-text"
                                          >
                                            Single Payment
                                          </h5>
                                          <img
                                            className="mb-3 pt-1"
                                            width="17px"
                                            src={tickIconPrimary}
                                            alt=""
                                          />
                                        </span>
                                        <h6 className="text-left text-muted-light">
                                          Charge a one-time fee.
                                        </h6>
                                      </label>
                                    </div>
                                  </div>
                                  <div className="form-group w-100 p-0 mt-3">
                                    <div className="up-in-toggle bg-white p-0 pay-class">
                                      <input
                                        checked={price_type == 'Pay_what_you_want'? true :false }
                                        type="radio"
                                        id="payment"
                                        name="pricingType"
                                        value="Pay_what_you_want"
                                        onChange={(a) =>
                                          setpetPaymentValue(a.target.value)
                                        }
                                      />
                                      <label
                                        onClick={handlepayfun}
                                        htmlFor="payment"
                                        className="d-flex align-items-start flex-column"
                                      >
                                        <span className="w-100 d-flex align-items-center justify-content-between">
                                          <h5
                                            className="m-0 font-weight-500 pricing-text"
                                          >
                                            Pay what you want
                                          </h5>
                                          <img
                                            className="mb-3 pt-1"
                                            width="17px"
                                            src={tickIconPrimary}
                                            alt=""
                                          />
                                        </span>
                                        <h6 className="text-left text-muted-light">
                                          Let customers choose
                                        </h6>
                                      </label>
                                      <input
                                        type="radio"
                                        id="membership"
                                        name="pricingType"
                                        value="menbership"
                                        disabled
                                        onChange={(a) =>
                                          setpetPaymentValue(a.target.value)
                                        }
                                      />
                                      <label
                                        onClick={handlememberfun}
                                        htmlFor="membership"
                                        className="d-flex align-items-start flex-column membershipstyle"
                                      >
                                        <span className="w-100 d-flex align-items-center justify-content-between ">
                                          <h5
                                            className="m-0 font-weight-500 pricing-text"
                                            style={{
                                              color: "#b1b1b1",
                                            }}
                                          >
                                            Membership
                                          </h5>
                                          <img
                                            className="mb-3 pt-1"
                                            width="17px"
                                            src={tickIconPrimary}
                                            alt=""
                                          />
                                        </span>
                                        <h6
                                          className="text-left"
                                          style={{
                                            color: "#cfcfcf",
                                          }}
                                        >
                                          COMING SOON
                                        </h6>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                {price_type == "single_payment" && (
                                  <div className="form-group mt-3">
                                    <label className="text-gray font-weight-500">
                                      Price
                                      <small className="text-red">*</small>
                                    </label>
                                    <div
                                      style={{
                                        border: "1px solid #E1E1E1",
                                        borderRadius: 12,
                                        height: 50,
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          marginTop: 10,
                                        }}
                                      >
                                        <div
                                          style={{
                                            backgroundColor: "#F3F3F3",
                                            color: "black",
                                            fontWeight: "bold",
                                            borderRadius: 10,
                                            width: "46px",
                                            height: "25px",
                                            marginLeft: 10,
                                            padding: 17,
                                            marginTop: -4,
                                          }}
                                        >
                                          <p
                                            style={{
                                              marginTop: -15,
                                              fontWeight: 5,
                                              fontSize: 22,
                                            }}
                                          >
                                            {currency == "eur" ? '€' : currency == "gbp" ? '£' : '$'}
                                          </p>
                                        </div>
                                        <div>
                                          <input
                                            value={price}
                                            // onChange={(a) =>
                                            //   setPrice(a.target.value)
                                            // }
                                            onChange={handlePriceChange}
                                            style={{ backgroundColor: "transparent", border: "none", outline: "none" }}
                                            type="text"
                                            name="price"
                                            placeholder="0"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {price_type == "Pay_what_you_want" && (
                                  <div>
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div className="form-group mt-3">
                                          <label className="text-gray font-weight-500">
                                            <small>
                                              Minimum Price
                                              <small className="text-red">
                                                *
                                              </small>
                                            </small>
                                          </label>
                                          <div
                                            style={{
                                              border: "1px solid #E1E1E1",
                                              borderRadius: 15,
                                              height: 50,
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                marginTop: 10,
                                              }}
                                            >
                                              <div
                                                style={{
                                                  backgroundColor: "#F3F3F3",
                                                  color: "black",
                                                  fontWeight: "bold",
                                                  borderRadius: 10,
                                                  width: "48px",
                                                  height: "25px",
                                                  marginLeft: 10,
                                                  padding: 17,
                                                  marginTop: -4,
                                                }}
                                              >
                                                <p
                                                  style={{
                                                    marginTop: -15,
                                                    fontWeight: 5,
                                                    fontSize: 22,
                                                  }}
                                                >
                                                  {currency == "eur" ? '€' : currency == "gbp" ? '£' : '$'}
                                                </p>
                                              </div>
                                              <div>
                                                <input
                                                  value={min_price}
                                                  // onChange={(a) =>
                                                  //   setMin_Price(a.target.value)
                                                  // }
                                                  onChange={handlePriceChange}
                                                  style={{ backgroundColor: "transparent", border: "none", outline: "none" }}
                                                  type="text"
                                                  name="min_price"
                                                  placeholder="0"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group mt-3">
                                          <label className="text-gray font-weight-500">
                                            <small>
                                              Suggested Price
                                              <small className="text-red">
                                                *
                                              </small>
                                            </small>
                                          </label>
                                          <div
                                            style={{
                                              border: "1px solid #E1E1E1",
                                              borderRadius: 15,
                                              height: 50,
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                marginTop: 10,
                                              }}
                                            >
                                              <div
                                                style={{
                                                  backgroundColor: "#F3F3F3",
                                                  color: "black",
                                                  fontWeight: "bold",
                                                  borderRadius: 10,
                                                  width: "48px",
                                                  height: "25px",
                                                  marginLeft: 10,
                                                  padding: 17,
                                                  marginTop: -4,
                                                }}
                                              >
                                                <p
                                                  style={{
                                                    marginTop: -15,
                                                    fontWeight: 5,
                                                    fontSize: 22,
                                                  }}
                                                >
                                                  {currency == "eur" ? '€' : currency == "gbp" ? '£' : '$'}
                                                </p>
                                              </div>
                                              <div>
                                                <input
                                                  value={suggested_price}
                                                  type="text"
                                                  name="suggested_price"
                                                  style={{ backgroundColor: "transparent", border: "none", outline: "none" }}
                                                  placeholder="9.99"
                                                  // onChange={(a) =>
                                                  //   setSugested_Price(
                                                  //     a.target.value
                                                  //   )
                                                  // }
                                                  onChange={handlePriceChange}
                                                />
                                                {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {(price_type == "Pay_what_you_want") || (price_type == "single_payment") ?
                                <div className="currency-dropdown mt-4">
                                  <div className="d-flex flex-column">
                                    <span>Currency</span>
                                    <small className="text-gray">This can't be edited later</small>
                                  </div>
                                  <div className="currency-select-box">
                                    <select name="currency" id="currency" disabled={edit || localStorage?.edit} value={currency} onChange={(a) =>setCurrency(a.target.value)}>
                                      {edit || localStorage?.edit ?
                                        <option value={currency}>{currency.toUpperCase()}</option>:<>
                                          <option value="usd">$USD</option>
                                          <option value="eur">€EUR</option>
                                          <option value="gbp">£GBP</option>
                                        </>
                                      }
                                    </select>
                                  </div>
                                </div>:''
                                }
                                <div class="form-group  parent-producttoggle mt-4">
                                  <label className="text-gray font-weight-500 mb-2 input-text">
                                    Product Content
                                  </label>
                                  <div class="up-in-toggle p-1 bg-f1f1f1 producttoggle ">
                                    <input
                                      Checked={content == "calendar" ? content : openCalendar}
                                      type="radio"
                                      id="calendar"
                                      name="content"
                                      value="calendar"
                                      onChange={(a) => {
                                        setContent(a.target.value);
                                        setOpenCalendar(true);
                                        setOpendigitalfile(false);
                                      }}
                                      style={{
                                        display: "none",
                                      }}
                                    />
                                    <label
                                      onClick={handleCalendar}
                                      htmlFor="calendar"
                                      className="d-flex align-items-center justify-content-center"
                                      style={{
                                        padding: "9px",
                                        borderRadius: "9px",
                                        fontSize: 18,
                                        display: "flex",
                                        justifyContent: "center"
                                      }}
                                    >
                                      <img
                                        className="me-2 content-img"
                                        width="25px"
                                        height="27px"
                                        src={calendarIcon}
                                      />
                                      <span className="font-weight-500 content-tab">
                                        Calendar
                                      </span>
                                    </label>
                                    <input
                                      Checked={content == "files"? content :opendigitalfile}
                                      type="radio"
                                      id="digitalFile"
                                      name="content"
                                      value={"files"}
                                      onChange={(a) => {
                                        setContent(a.target.value);
                                        setOpenCalendar(false);
                                        setOpendigitalfile(true);
                                      }}
                                      style={{
                                        display: "none",
                                      }}
                                    />
                                    <label
                                      onClick={handldigital}
                                      htmlFor="digitalFile"
                                      style={{
                                        padding: "9px",
                                        borderRadius: "9px",
                                        fontSize: 18,
                                        display: "flex",
                                        justifyContent: "center"
                                      }}
                                    >
                                      <img
                                        className="me-2 content-img"
                                        width="25px"
                                        src={digitalFileIcon}
                                      />
                                      <span style={{ whiteSpace: "nowrap" }} className="font-weight-500 content-tab">
                                        Digital Files
                                      </span>
                                    </label>
                                    <input
                                      checked={content == "url"? content : openVisitUrl}
                                      type="radio"
                                      id="visitUrl"
                                      name="content"
                                      value={"url"}
                                      onClick={(a) =>
                                        setContent(a.target.value)
                                      }
                                      style={{
                                        display: "none",
                                      }}
                                    />
                                    <label
                                      onClick={handlevisiturl}
                                      htmlFor="visitUrl"
                                      style={{
                                        padding: "9px",
                                        borderRadius: "9px",
                                        fontSize: 18,
                                        display: "flex",
                                        justifyContent: "center"
                                      }}
                                    >
                                      <img
                                        className="me-2 content-img"
                                        width="25px"
                                        src={visitIcon}
                                      />
                                      <span style={{ whiteSpace: "nowrap" }} className="font-weight-500 content-tab">
                                        Visit URL
                                      </span>
                                    </label>
                                  </div>
                                  {openCalendar && (
                                    <>
                                      <div style={{ marginTop: 16 }}>
                                        <p style={{ fontSize: 13 }}>
                                        Let customers book a time slot on your calendar. For paid coaching,<br /> meetings and events.
                                        {" "}
                                        </p>
                                      </div>
                                      <div className="mb-4">
                                        <p className="mt-4 mb-1 input-text">
                                          Meeting Details
                                        </p>
                                        <div className=" d-flex">
                                          <div className="break-between">
                                          <span className="text-gray ">Duration</span>
                                            <select name="cars" id="cars" value={duration} onChange={(a) => {setDuration(a.target.value ) } } >
                                              <option value="15">15 min</option>
                                              <option value="30">30 min</option>
                                              <option value="45">45 min</option>
                                              <option value="60">60 min</option>
                                            </select>
                                          </div>
                                          <div className="break-between"style={{ marginLeft: "2vh" }}>
                                          <span className="text-gray ">Within the next</span>
                                            <select
                                              name="cars" id="cars" value={book_within_next_day} onChange={(a) =>setBook_Within_Next_Day(a.target.value)}>
                                              <option value="7">7 days</option>
                                              <option value="14">14 days</option>
                                              <option value="30">30 days</option>
                                              <option value="60">60 days</option>
                                            </select>
                                          </div>
                                        </div>
                                        <div className="timezone-select-container ">
                                        <div
                                          className="timezone-select-input-container"
                                          onClick={() => setDropdownOpen(!dropdownOpen)}
                                        >
                                          <img src={globeIcon} alt="" width="20px" className="globe-icon" />
                                          <span className="text-grey">Timezone</span>
                                          <div className="selected-timezone ">{console.log("selectedTimezone",selectedTimezone)}
                                            {selectedTimezone ? (
                                              <>
                                              <span>{selectedTimezone.city ? 'GMT' : selectedTimezone  } </span>&nbsp;
                                                <div className="selected-timezone-city">
                                                  {selectedTimezone.city ? selectedTimezone.gmtOffset +' '+ selectedTimezone.city : null}
                                                </div>
                                                <div className="selected-timezone-time">
                                                  {selectedTimezone.formattedTime}
                                                </div>
                                              </>
                                            ) : (
                                              <div className="selected-timezone-placeholder"><span>GMT+0</span>&nbsp; Select timezone </div>
                                            )}
                                            <img src={chevron_down} width="15px" alt="" style={{ paddingLeft: 5, paddingTop: 2 }} />
                                          </div>

                                        </div>
                                        {dropdownOpen && (
                                          <div >
                                            <div className="timezone-dropdown"style={{ overflowY: "auto", overflowX: "hidden", height: 256 }}>
                                              <div className="bg-input ">
                                                <img src={searchicon} alt="" width="20px" />
                                                <input
                                                  style={{ backgroundColor: "transparent", border: "none", outline: "none" }}
                                                  type="text"
                                                  placeholder="Search"
                                                  value={searchInput}
                                                  onChange={handleSearchInputChange}
                                                  className="timezone-search-bar "
                                                />
                                              </div>
                                              <div  >
                                                {filteredTimezones.map((timezone) => (

                                                  <div
                                                    key={timezone.timezone}
                                                    className="timezone-option"
                                                    onClick={() => handleSelectTimezone(timezone)}
                                                  >
                                                    <div className="timezone-option-city">{timezone.city}</div>
                                                    <div className="timezone-option-time">
                                                      {timezone.gmtOffset}
                                                    </div>
                                                  </div>
                                                ))}
                                              </div>
                                            </div>
                                          </div>
                                        )}

                                      </div>
                                        </div>
                                      <div>
                                        <p style={{ fontSize: 14 }}>Your Availability</p>
                                        <div>
                                        <Tabs defaultActiveKey="0"
                                            className="mb-3 up-in-toggle main-btn-header btnTxtDark border-less"
                                            justify
                                            id="days-tabs">
                                              {defaultTimeSlots.map((day) => ( 
                                                  <Tab eventKey={day.id} title={day.day} key={day.id}>
                                                      <div className="d-flex justify-content-start flex-wrap">
                                                          {day.slots.map((slot) => (
                                                             slot.time ? (
                                                              <div className="px-1" key={slot.id}>
                                                                  <div className="form-group">
                                                                      <label className="text-gray font-weight-500"></label>
                                                                      <div className="time-slot-btn">
                                                                      {formatTime(slot.time)}
                                                                      {/*  (String(slot.time).includes(':30') || String(slot.time).includes(':00') || String(slot.time).includes(':15') || String(slot.time).includes(':45') ? String(slot.time) : String(slot.time).padStart(2, '0') + ':00') */}
                                                                          <small
                                                                              className="badge  text-dark p-1"
                                                                              onClick={() => handleDeleteTimeSlot(day.id, slot.id)}
                                                                          >
                                                                              <img src={dustbinWhite} alt="" />
                                                                          </small>
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                              ) : null
                                                          ))}
                                                      </div>
                                                      <div className="mt-3">
                                                        <div
                                                            className="btn-style"
                                                            onClick={() => handleNewAddTimeSlot(day.id)}
                                                        >
                                                            Add time slot
                                                            <small
                                                                style={{ height: 20 }}
                                                                className="badge text-dark p-1 fs-5 ms-1"
                                                            >
                                                                <p
                                                                    className=""
                                                                    style={{
                                                                        backgroundColor: "#494949",
                                                                        borderRadius: 2,
                                                                        width: 20,
                                                                        color: "white",
                                                                        fontWeight: 5,
                                                                    }}
                                                                >
                                                                    +
                                                                </p>
                                                            </small>
                                                        </div>
                                                        {day.showDropdown && (
                                                          <div className="availability-dropdown">
                                                             {generateTimeSlots(duration).map((slot) => (
                                                              <div
                                                                key={slot}
                                                                className={`${isSlotDisabled(slot, day.id) ? 'availability-option-check' : 'availability-option'}`}
                                                                onClick={isSlotDisabled(slot, day.id) ? null : () => handleDropdownSelect(day.id, slot)}
                                                              >
                                                                {slot}
                                                              </div>
                                                            ))}
                                                          </div>
                                                            // <select className="form-select" onChange={(e) => handleDropdownSelect(day.id, e.target.value)}>
                                                            //     <option disabled selected>Select a time</option>
                                                            //     <option value="9">9:00</option>
                                                            //     <option value="9:30">9:30</option>
                                                            //     <option value="10">10:00</option>
                                                            //     <option value="10:30">10:30</option>
                                                            // </select>
                                                        )}
                                                    </div>
                                                  </Tab>
                                              ))}
                                          </Tabs>
                                        </div>
                                      </div>
                                      <div
                                        style={{ marginTop: 20 }}
                                      >

                                        <div>
                                          <div class="form-group parent-producttoggle mt-4">
                                            <p className="m-1">Meeting Location</p>
                                            <div class="up-in-toggle bg-f1f1f1 mian-google-style ">
                                              <input

                                                checked={meeting_location == "google" ? true : false}
                                                type="radio"
                                                id="googlemeet"
                                                name="meeting_location"
                                                value={"google"}
                                                onChange={(a) =>
                                                  setMeetingLocation(a.target.value)
                                                }
                                                style={{
                                                  display: "none",
                                                }}
                                              />
                                              <label
                                                htmlFor="googlemeet"
                                                style={{
                                                  padding: "10px",
                                                  borderRadius: "10px",
                                                  fontSize: 17,
                                                  whiteSpace: "nowrap",
                                                  fontWeight: 500,
                                                  color: "#5B5B5B",



                                                }}
                                              >
                                                <img
                                                  style={{ marginRight: 3 }}
                                                  width="11%"
                                                  src={googleMeetIcon}
                                                />
                                                Google Meet
                                              </label>
                                              <input

                                                checked={meeting_location == "custom" ? true : false}
                                                type="radio"
                                                id="custom"
                                                name="meeting_location"
                                                value={"custom"}
                                                onChange={(a) =>
                                                  setMeetingLocation(a.target.value)
                                                }
                                                style={{
                                                  display: "none",
                                                }}
                                              />
                                              <label
                                                htmlFor="custom"
                                                style={{
                                                  padding: "10px",
                                                  borderRadius: "10px",
                                                  fontSize: 17,
                                                  whiteSpace: "nowrap",
                                                  fontWeight: 500,
                                                  color: "#5B5B5B",

                                                }}
                                              >
                                                <img
                                                  style={{ marginRight: 3 }}
                                                  width="12%"
                                                  src={customIcon}
                                                />
                                                Custom
                                              </label>
                                            </div>
                                            {meeting_location == 'custom'?
                                            <>
                                            <p className="mt-2">
                                              <img
                                                src={questionIcon}
                                                width="28px"
                                                style={{ padding: 5 }}
                                              />
                                              Create the meeting yourself after a customer purchases
                                            </p>
                                            <div className="mt-3">
                                            <input
                                              value={custom_meeting_link}
                                              onChange={(a) =>
                                                setCustomMeetingLink(a.target.value)
                                              }
                                              type="url"
                                              className="form-control zaap-form-control man-inp"
                                              name="short_description"
                                              placeholder="Meeting Service URL"
                                            />
                                            </div>
                                            </>
                                            : null}
                                            {meeting_location == 'google'?
                                            <p className="ms-1 mt-1">Google Meet event will be created automatically after booking. Manage Google Account from settings
                                            </p>:null}
                                          </div>
                                        </div>
                                      </div>



                                    </>
                                  )}
                                  {opendigitalfile && (

                                    <>
                                      {urlPop && <div
                                        className="form-group mt-2 w-100 product-files-section"
                                        data-type="files"
                                      >

                                        <label className="text-gray font-weight-500 mt-3 mb-2">
                                          <small>
                                            Upload your Product files, your
                                            customers will receive an email with
                                            a download link.
                                          </small>
                                        </label>
                                        <div className="mt-2 dropzone-wrapper dropzone-wrapper4 product-dropzone">
                                          <div className="dropzone-desc">
                                            <img src={imageIcon} alt="" />
                                            <h6 className="mt-2 mb-1 text-gray">
                                              Drag and drop files, or{" "}
                                              <a className="text-primary decoration-none">
                                                click here to browse
                                              </a>
                                            </h6>
                                            <small className="text-muted-light">
                                              100MB max file size upload
                                            </small>
                                          </div>
                                          <input
                                            onChange={handleContentfun}
                                            type="file"
                                            accept="jpeg,jpg,png,gif,bmp,pdf,doc,docx,ppt,pptx,xls,xlsx,zip,rar,svg"
                                            name="content_file"
                                            className="dropzone dropzone4"
                                          />
                                        </div>
                                      </div>}

                                      {removepop && <div style={{ display: "flex", justifyContent: "space-between", marginTop: 10 }}>
                                        <p>{contentText}</p>
                                        <img src={removeicon} width="3%" style={{ cursor: "pointer" }} onClick={handleRemoveUrl} />
                                      </div>}

                                    </>
                                  )}

                                  {openVisitUrl && (
                                    <div>
                                      <div
                                        className="form-group mt-2 w-100 product-files-section"
                                        data-type="url"
                                      >
                                        <label className="text-gray font-weight-500 mt-3 mb-2">
                                          <small>
                                            Redirect to a URL after purchase
                                            <small className="text-red">
                                              *
                                            </small>
                                          </small>
                                        </label>
                                        <input
                                          type="url"
                                          className="form-control zaap-form-control"
                                          name="content_url"
                                          placeholder="Please Enter Redirect URL"
                                          onChange={(a) => setContent_Url(a.target.value)}
                                          value={content_url}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Tab>
                          <Tab
                            eventKey="profile"
                            className="up-in-toggle"
                            title={
                              <div style={{ whiteSpace: "nowrap" }}>
                                <img
                                  className="product-icon"
                                  src={producticon}
                                  width="25px"
                                  style={{ marginRight: 10 }}
                                />
                                <label className="text-gray font-weight-500 cursor-pointer product-tab" style={{ fontSize: 18, whiteSpace: "nowrap" }}>Product Page</label>
                              </div>
                            }
                          >
                            <div>
                              <div className="form-group p-0 mt-4">
                                <label className="text-gray font-weight-500 theme-text">Product Page Theme</label>
                                <div
                                  className="up-in-toggle bg-f7f7f8  product-style"
                                  style={{ Color: "black" }}
                                >
                                  <input
                                    onChange={(a) =>
                                      setProductTheme(a.target.value)
                                    }
                                    type="radio"
                                    id="file_type_switch_left"
                                    name="product_theme"
                                    value="light"
                                    className="light-inp"
                                    checked={product_theme == "light" ? true : false}
                                  />
                                  <label
                                    htmlFor="file_type_switch_left"
                                    style={{ padding: 12, borderRadius: 25, fontSize: 18 }}
                                    onClick={lightmodelfun}
                                    className="d-flex align-items-center justify-content-center"
                                  >
                                    {" "}
                                    <img
                                      src={lightmodeicon}
                                      style={{ marginRight: 5 }}
                                      width="25px"
                                    />
                                    <span class="font-weight-500 dark-style">Light</span>
                                  </label>
                                  <input
                                    onChange={(a) =>
                                      setProductTheme(a.target.value)
                                    }
                                    type="radio"
                                    id="file_type_switch_right"
                                    name="product_theme"
                                    value="darkmode"
                                    checked={product_theme == "darkmode" ? true : false}
                                  />
                                  <label
                                    style={{ padding: 12, borderRadius: 25, fontSize: 18 }}
                                    htmlFor="file_type_switch_right"
                                    onClick={darkmodelfun}
                                    className="d-flex align-items-center justify-content-center"
                                  >
                                    {/* <img style={{ marginRight: 5 }} src={darkmodeicon} width="25px" /> */}
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M5.5 12L6.28446 13.5689C6.54995 14.0999 6.68269 14.3654 6.86003 14.5954C7.01739 14.7996 7.20041 14.9826 7.40455 15.14C7.63462 15.3173 7.9001 15.4501 8.43108 15.7155L10 16.5L8.43108 17.2845C7.9001 17.5499 7.63462 17.6827 7.40455 17.86C7.20041 18.0174 7.01739 18.2004 6.86003 18.4046C6.68269 18.6346 6.54995 18.9001 6.28446 19.4311L5.5 21L4.71554 19.4311C4.45005 18.9001 4.31731 18.6346 4.13997 18.4046C3.98261 18.2004 3.79959 18.0174 3.59545 17.86C3.36538 17.6827 3.0999 17.5499 2.56892 17.2845L1 16.5L2.56892 15.7155C3.0999 15.4501 3.36538 15.3173 3.59545 15.14C3.79959 14.9826 3.98261 14.7996 4.13997 14.5954C4.31731 14.3654 4.45005 14.0999 4.71554 13.5689L5.5 12Z" stroke="#6B6B6B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                      <path d="M14 1L15.1786 4.06442C15.4606 4.79765 15.6016 5.16426 15.8209 5.47264C16.0153 5.74595 16.254 5.98475 16.5274 6.17909C16.8357 6.39836 17.2024 6.53937 17.9356 6.82138L21 8L17.9356 9.17862C17.2024 9.46063 16.8357 9.60164 16.5274 9.82091C16.254 10.0153 16.0153 10.254 15.8209 10.5274C15.6016 10.8357 15.4606 11.2024 15.1786 11.9356L14 15L12.8214 11.9356C12.5394 11.2024 12.3984 10.8357 12.1791 10.5274C11.9847 10.254 11.746 10.0153 11.4726 9.82091C11.1643 9.60164 10.7976 9.46063 10.0644 9.17862L7 8L10.0644 6.82138C10.7976 6.53937 11.1643 6.39836 11.4726 6.17909C11.746 5.98475 11.9847 5.74595 12.1791 5.47264C12.3984 5.16426 12.5394 4.79765 12.8214 4.06442L14 1Z" stroke="#6B6B6B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>

                                    <span className="dark-style" style={{ marginLeft: 5 }}>Dark</span>

                                  </label>
                                </div>
                              </div>

                              <div>
                                <div className="mb-3 col-md-12 mt-4">
                                  <div>
                                    <label className="text-gray font-weight-500 accent-text">Accent Color</label>

                                  </div>

                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  <div>
                                    <div
                                      className="wheel"
                                      id="colorWheelDemo4"
                                    ></div>
                                  </div>
                                  <div style={{ paddingLeft: 10 }}>
                                    {" "}
                                    <input
                                      type="text"
                                      id="hex_code4"
                                      className="w-50  py-1"
                                      name="hex_code4"
                                      defaultValue={accent_theme}
                                      required="required"
                                      style={{ marginTop: 10, border: "none", outline: "none", fontWeight: "bold", color: "  rgba(14, 18, 59, 0.5)   " }}
                                    />
                                  </div>
                                </div>


                                <div className="form-group mt-4">
                                  <label className="text-gray font-weight-500 input-text">
                                    CTA Button Text
                                  </label>
                                  <input
                                    onChange={(a) =>
                                      set_buy_button_text(a.target.value)
                                    }
                                    type="text"
                                    className="form-control zaap-form-control man-inp"
                                    name="short_description"
                                    placeholder="Got my new awesome course!"
                                    // value={buy_button_text ?? 'Buy Now'}
                                    value={buy_button_text}
                                  />
                                </div>
                                <div className="form-group mt-4">

                                  <label className="text-gray font-weight-500">
                                    <span
                                      style={{
                                        display: "flex"
                                      }}
                                    >
                                      <span className="input-text">FAQ Section Title</span>
                                      <small>
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={<Tooltip id="button-tooltip-2">Answer commonly asked questions</Tooltip>}
                                        ><img src={questionIcon} width="15px" alt="" className="payout-warning ms-2" />
                                        </OverlayTrigger>
                                      </small>
                                    </span>

                                  </label>
                                  <input
                                    onChange={(a) =>
                                      setFAQ_heading(a.target.value)
                                    }
                                    type="text"
                                    className="form-control zaap-form-control man-inp"
                                    name="short_description"
                                    value={FAQ_heading}
                                  />
                                </div>

                                {faqs?.map((e, i) => {
                                  return (
                                    <div key={i}>
                                      <div className="form-group mt-3">
                                        <div
                                          style={{
                                            border: "1px solid #E1E1E1",
                                            width: "100%",
                                            padding: 8,
                                            borderRadius: 20,
                                            marginTop: 10,
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <div style={{width:'100%'}}>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    backgroundColor: "#F3F3F3",
                                                    color: "black",
                                                    fontWeight: "bold",
                                                    padding: 6,
                                                    borderRadius: 10,
                                                    width: "75px",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  Q
                                                </div>
                                                <input
                                                  className="faq-input-fields"
                                                  value={e.questions}
                                                  onChange={(a) => {
                                                    handleQuestionFaq(a, i);
                                                  }}
                                                  placeholder="Add question here..."
                                                  style={{
                                                    padding: 7,
                                                    marginLeft: 5,

                                                  }}
                                                />
                                              </div>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  marginTop: 4,
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    backgroundColor: "#F3F3F3",
                                                    color: "black",
                                                    fontWeight: "bold",
                                                    padding: 6,
                                                    borderRadius: 10,
                                                    width: "75px",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  A
                                                </div>
                                                <input
                                                  className="faq-input-fields"
                                                  value={e.answers}
                                                  onChange={(a) => {
                                                    handleAnswerFaq(a, i);
                                                  }}
                                                  placeholder="Add answer here..."
                                                  style={{
                                                    padding: 7,
                                                    marginLeft: 5
                                                  }}
                                                />
                                              </div>
                                            </div>
                                            {faqs.length > 1 ? <div>
                                              <div
                                                className="table-action-dropdown dropdown-toggler noselect d-flex align-items-center justify-content-end dropdown-subscribers "
                                                id="table-action-dropdown"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                              >
                                                <img
                                                  src={dotsIcon}
                                                  className="menu-btn"
                                                  alt=""
                                                  style={{ padding: 10, marginRight: 10 }}
                                                />
                                              </div>
                                              <ul
                                                class="dropdown-menu"
                                                aria-labelledby="table-action-dropdown"
                                              >
                                                <li>
                                                  <a
                                                    class="dropdown-item"
                                                    onClick={(event) =>
                                                      handleDuplicate(i, event)
                                                    }
                                                  >
                                                    <span class="me-5">
                                                      Duplicate
                                                    </span>
                                                    <ChevronRight />
                                                  </a>
                                                </li>

                                                <li>
                                                  <a onClick={(a) =>
                                                    handleDelete(i)
                                                  } class="dropdown-item theme-active semi-bold">
                                                    <span
                                                      class="me-5"
                                                    >
                                                      Delete FAQ
                                                    </span>
                                                    <ChevronRight />
                                                  </a>
                                                </li>
                                              </ul>
                                            </div> : <></>}

                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}

                                <div className="mt-4">
                                  <button
                                    type="button"
                                    className="add-faqs-buton"
                                    onClick={handleadd}
                                    width="100%"
                                  >
                                    <span className="me-2">Add FAQ</span>
                                    <img src={addFaqIcon} width="20px" alt="" />
                                  </button>
                                </div>
                                <div className="form-group mt-4">
                                  <label className="text-gray font-weight-500">
                                    <span
                                      style={{
                                        display: "flex"
                                      }}
                                    >
                                      <span className="input-text">Checkout Question</span>
                                      <small>
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={<Tooltip id="button-tooltip-2">Ask your customer questions before purchasing</Tooltip>}
                                        ><img src={questionIcon} width="15px" alt="" className="payout-warning ms-2" />
                                        </OverlayTrigger>
                                      </small>
                                    </span>
                                  </label>
                                  {checkout_question?.map((ele, i) => {
                                    return (
                                      !ele?.questions && i == 0?  '' :
                                      <div key={i} style={{display:"flex",alignItems:"baseline"}}>
                                        <div className="form-control zaap-form-controls">
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: 'space-between',
                                              alignItems: "center",
                                              marginTop: 10,
                                              flex: 1,

                                            }}
                                          >
                                            <input
                                              value={ele.questions}
                                              type="text"
                                              name="title"
                                              onChange={(a) => {
                                                handleCheckFun(a, i);
                                              }}
                                              placeholder="what is  your username"
                                              className="faq-input-fields"
                                              style={{ marginTop: -18, backgroundColor: "transparent" }}
                                            />
                                            <div
                                              style={{
                                                display: "flex",
                                                marginRight: 10,
                                              }}
                                              class="required-switch"
                                            >
                                              <p
                                                style={{ marginRight: "10px" }}
                                                className="input-text"
                                              >

                                                Required?
                                              </p>
                                              <div>
                                                <label className="link-switch">
                                                  <input
                                                    defaultChecked={ele.isRequired == 1 ? true : false}
                                                    type="checkbox"
                                                    className=""
                                                    name="isrequired"
                                                    value={ele.isRequired}
                                                    onChange={(a) =>
                                                      handleswitch(a, i)
                                                    }
                                                  />
                                                  <span className="slider round"></span>
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <img
                                          style={{ cursor: "pointer" }}
                                          src={removeicon}
                                          className="ms-2"
                                          width={16}
                                          height={16}
                                          onClick={() => delete_question(i)}
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                              <div className="mt-4">
                                <button
                                  type="button"
                                  className="add-question-buton"
                                  onClick={handleQuestion}
                                  width="100%"
                                >
                                  <span className="me-2">Add question</span>
                                  <img src={addFaqIcon} width="20px" alt="" />
                                </button>
                              </div>
                            </div>
                          </Tab>
                        </Tabs>
                      </div>
                    </div>
                  </div>



                  <div className="product-footer d-flex">
                    <button
                      type="button"
                      className="btn mt-2 mb-2 btn-primary btn-semi-rounded d-flex align-items-center justify-content-center py-2 px-3 savechang-style"
                      onClick={handleSave}
                    >
                      <b className="me-2" style={{ whiteSpace: "nowrap" }}>Save Changes</b>
                      <small className="badge bg-primary-dark text-dark px-2 py-1 d-flex align-items-center justify-content-center">
                        <img src={returnIcon} alt="" />
                      </small>
                    </button>
                    <button
                      onClick={handleCancel}
                      type="button"
                      style={{ backgroundColor: "#FFFFFF" }}
                      class="close btn btn-semi-rounded me-2 d-flex align-items-center justify-content-center py-2 px-3 exit-style"
                    >
                      <b class="me-2">Exit</b>
                      <small className="badge bg-gray text-dark p-1">Esc</small>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5 noHorizontalPadding main-width">
              <div style={{ backgroundColor: `${color}` }} className="mobile-container">
                <div className="scrool-container" >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ backgroundColor: `${linkcolor}`, boxShadow: "none" }} className="page-preview-link d-flex align-items-center justify-content-around link-style">
                      <div style={{ flex: 1 }}>

                        <input
                          // style={{ color: `${textcolor}` }}
                          className={`link-inp ${textcolor == "#000000" ? 'text-grey' : 'text-light' }`}
                          onChange={(a) => setUrlCopy(a.target.value)}
                          placeholder=""
                          value={`zaap.bio/${slug}/${slugify(product_slug) ?? ""}`}
                        />
                        <a
                          className="text-decoration-none text-dark"
                          target="blank"
                        >
                          {" "}
                        </a>
                      </div>
                      {product_theme == "light" ? <img
                        className="main-courser"
                        style={{ color: "red" }}
                        src={linkdarkicon}
                        width="16px"
                        alt=""
                        onClick={(a) => copyingText()}
                      /> : <img
                        className="main-courser"
                        style={{ color: "red" }}
                        src={urlicon}
                        width="16px"
                        alt=""
                        onClick={(a) => copyingText()}
                      />}

                    </div>
                  </div>
                  {/* {cover_image[0]?.images || cover_image[0]?.image_url ? <div > <img className="binary-img" src={cover_image[0]?.images || cover_image[0]?.image_url} /> </div> : <div style={{ display: "flex", justifyContent: "center" }}><div style={{ backgroundColor: `${Dolorbutcolor}` }} className="mobile-screen"></div></div>} */}
                  <div style={{ position: 'relative' }}>
                    {cover_image.length > 0 ? (
                      <div>
                        <img className="binary-img" src={cover_image[currentIndex]?.images || cover_image[currentIndex]?.image_url} alt="" />
                      </div>
                    ) : (
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <div style={{ backgroundColor: `${Dolorbutcolor}` }} className="mobile-screen"></div>
                      </div>
                    )}
                    {cover_image.length > 1 && (
                      <div style={{ position: 'absolute', top: '50%', left: 0 }}>
                        <button className="product-slider-buttons" onClick={prevImage}>
                          <span><img src={leftArrow} alt="" width="25px"/></span>
                        </button>
                      </div>
                    )}
                    {cover_image.length > 1 && (
                      <div style={{ position: 'absolute', top: '50%', right: 0 }}>
                        <button className="product-slider-buttons" onClick={nextImage}>
                          <span><img src={rightArrow} alt="" width="25px"/></span>
                        </button>
                      </div>
                    )}
                  </div>
                  {projects?.filter((value) => value?.id == selectedProfile).map((value, index) => (
                  <div className="img-header">
                    <img
                      // src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTsVY-ZGbMfMeQ35J48ScBcN55qk7ubyM3ux52bxtlE4w&s"
                      src={value?.settings ? value?.settings[1]?.value:value?.project?.settings[1]?.value}
                      style={{
                        borderRadius: "50%",
                        width: "70px",
                        height: "70px",
                        objectFit: "cover",
                      }}
                      width="100%"
                    />
                    <div>
                      <p style={{ color: `${textcolor}` }} className="text-styling">
                        {title ? title : ""}
                      </p>
                      <p style={{ color: `${textcolor}` }} className="para-style">
                        {short_description
                          ? short_description
                          : ""}
                      </p>
                      <div className="mt-4">
                        <button
                          style={{
                            backgroundColor: `${accent_theme}`,
                            padding: 9,
                            borderRadius: 9,
                            minWidth: "130px",
                            border: "none",
                            fontWeight: 500,
                            color:getTextColor(accent_theme)
                          }}
                        >
                          {buy_button_text ? buy_button_text : <>Buy Now</>}
                        </button>
                        {/* {price ? ( */}
                        {price_type === "free" ? "":
                          <button style={{ backgroundColor: ` ${Dolorbutcolor}`, color: `${textcolor}` }} className="dollor-btn">{currency == "eur" ? '€' : currency == "gbp" ? '£' : '$'}{suggested_price ? suggested_price : price ? price : "39"}</button> }
                        {/* ) : ( */}
                          {/* <button style={{ backgroundColor: ` ${Dolorbutcolor}`, color: `${textcolor}` }} className="dollor-btn">$39</button> */}
                        {/* )} */}
                      </div>


                      {/* {faqs[0].questions == "" ? null : <div style={{ display: "flex", justifyContent: "center", paddingBottom: 70 }}>
                        <div style={{ backgroundColor: Dolorbutcolor }} className="widget-block product-widget-block mt-4">
                          <h3 style={{ backgroundColor: Dolorbutcolor, color: textcolor, wordBreak: "break-word" }}>{FAQ_heading}</h3>
                          {faqs.map((e, i) => e.questions !== "" && (
                            <div key={`faq-${i}`}>

                              {e.questions?.length >= 1 || e.answers?.length >= 1 ? (
                                <div>
                                  <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse-${i}`} aria-expanded="false" aria-controls={`collapse-${i}`}>
                                    {e.questions}
                                  </button>
                                  <div id={`collapse-${i}`} className="accordion-collapse collapse" aria-labelledby={`heading${i}`} data-bs-parent="#faqaccordion">
                                    <div style={{ wordWrap: "break-word" }} className="accordion-body faq-accordion-body">
                                      <p>{e.answers}</p>

                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          ))}
                        </div>
                      </div>} */}

                      {faqs[0]?.questions !== "" || faqs[0]?.answers !== "" ? (
                        <div style={{ display: "flex", justifyContent: "center", paddingBottom: 70 }}>
                          <div style={{ backgroundColor: Dolorbutcolor }} className="widget-block product-widget-block mt-4">
                            <h3 className={  product_theme == "light" ? "text-dark" : "text-light" }>{FAQ_heading}</h3>
                            {faqs.map((e, i) => {
                              console.log(e);
                              const id = `faq${i}`; // create a unique ID using the index of the item

                              return e.questions?.length >= 1 || e.answers?.length >= 1 ? (
                                <div class="faq-accordion-item">
                                <div key={id}>
                                  <button className={`accordion-button faq-accordion-button faq-accordion-button${product_theme == "light" ? "-light":"-dark"}`} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse-${id}`} aria-expanded="false" aria-controls={`collapse-${id}`}>
                                    {e.questions}
                                  </button>
                                  <div id={`collapse-${id}`} className="accordion-collapse collapse" aria-labelledby={`heading${id}`} data-bs-parent="#faqaccordion">
                                    <div style={{ wordWrap: "break-word" }} className={`accordion-body faq-accordion-body faq-accordion-body${product_theme == "light" ? "-light":"-dark"}`}>
                                      {e.answers}
                                    </div>
                                  </div>
                                </div>
                                </div>
                              ) : null;
                            })}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CroppieModal
        show={showCroppieModal}
        handleHide={handleHideModal}
        croppieClose={croppieClose}
        croppedImage={croppedImage}
        modalWidth={500}
        productCroppie={"productCroppie"}
      />
      {showConnectCalendarModal && <ConnectCalendarModal show={showConnectCalendarModal} setShow={setShowConnectCalendarModal} text={<>Connect your Google Account to create Meet <br/> invite links when a customer purchases.</>} TwoButtons={true}/>}
      {showEditorModal && <EditorJSModal isOpen={showEditorModal} onHide={closeEditorModal} callNewsletter={"ABC"} seteditorjs_template={seteditorjs_template} settemplate_json={settemplate_json} editorjs_template={editorjs_template} template_json={template_json} edit={edit} setIsEdit={edit} reducer={productModalReducer} reducerType={"SET_NEW_PRODUCT_DETAILS"} modalHeading={"Build your product"} endPoint={`${apiUrl}/ProductFlow/${apiName}`} />}
      {showSaveChanges && <SaveChanges isOpen={showSaveChanges} onHide={closeSaveChanges} handleSave={handleSave}/> }
    </div >
  );
}
