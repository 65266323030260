import React from 'react';
import TagDropdown from './TagDropdown'; // Import the new TagDropdown
import deleteIcon from "../../assets/img/delete-icon-red.svg"; // Assuming you have a red delete icon

const BulkActionsBar = ({
                            selectedCount,
                            tags, // All available tags
                            bulkCheckedTags = [], // Tags common to *all* selected items (or define behavior)
                            onDeleteAll,
                            onBulkTagToggle,
                            onAddNewTag,
                        }) => {
    if (selectedCount === 0) return null;

    const handleBulkTagToggle = (tag, isChecked) => {
        // Callback receives the tag object and the desired state (true/false)
        onBulkTagToggle(tag, isChecked);
    };


    return (
        <div className="d-flex align-items-center bulk-actions-bar p-2"
             style={{border: "1px solid #eee", borderRadius: "8px", background: "#f8f9fa"}}>
            <span className="me-3 fw-bold">{selectedCount} Selected</span>
            <button className="btn btn-sm btn-outline-danger d-flex align-items-center me-2" onClick={onDeleteAll}>
                <img src={deleteIcon} alt="Delete" width="12" className="me-1"/>
                Delete
            </button>

            <TagDropdown
                availableTags={tags}
                checkedTags={[]}
                onTagToggle={handleBulkTagToggle}
                onAddNewTag={onAddNewTag}
                targetId="bulk"
            >
                {/* Button trigger for the TagDropdown */}
                <button className="btn btn-sm btn-outline-secondary d-flex align-items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 14 16" fill="none"
                         className="me-1">
                        <path d="M1 4V8L6 13L13 6L8 1H4C2 1 1 2 1 4Z" stroke="#333333" strokeWidth="1.5"
                              strokeLinecap="round" strokeLinejoin="round"/>
                        <circle cx="4" cy="4" r="1" fill="#333333"/>
                    </svg>
                    Segment
                </button>
            </TagDropdown>
        </div>
    );
};

export default BulkActionsBar;