import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { Button } from 'react-bootstrap'; // TODO
import 'react-loading-skeleton/dist/skeleton.css'

// Assets
import addUserIcon from "../../assets/img/add-user.svg";
import exportIcon from "../../assets/img/export-icon.svg";

// API and Utils
import getAudience, {getDefaultAudience } from "../../api/getAudience";
import { audiencePosts } from "../../api/audience"; // TODO POST/DELETE
import { apiUrl } from "../../utils/url";
import segmentApi from "../../api/segment";

// Contexts and Hooks
import { useAlert } from "../../contexts/AlertContext";

// Components
import { showLoader, hideLoader } from "../../components/loader";
import ImportAudienceModal from "../../components/modals/import-audience-modal";
import EditSegmentsModal from "../../components/profiles/EditSegmentsModal";
import ConfirmationModal from "../../components/modals/confirmation-modal";
import PaginationFooter from "../../components/table/PaginationFooter";
import HeaderSearch from "../../components/table/HeaderSearch";
import Sidebar from "../../components/layout/Sidebar";
import EmptyState from "../empty-state";
import AudienceTable from "../../components/profiles/AudienceTable";

// Styles
import "../../styles/Profiles.module.css";

const Email = () => {
    const [loading, setLoading] = useState(true);
    const [audienceData, setAudienceData] = useState({ model: null, commonTag: [] });
    const [defaultAudienceCounts, setDefaultAudienceCounts] = useState({});
    const [confirmationModalShow, setConfirmationModalShow] = useState({ show: false, text: null, id: null, deleteAll: false });
    const [showImportModal, setShowImportModal] = useState(false);
    const [tags, setTags] = useState([]);
    const [checkedTagsMap, setCheckedTagsMap] = useState({});
    const [selectedRows, setSelectedRows] = useState([]);

    const [showEditModal, setShowEditModal] = useState(false);
    const [editingMember, setEditingMember] = useState({ id: null, name: null, initialSegments: [] });
    const [availableSegments, setAvailableSegments] = useState([]); // Fetch or get from Redux

    const { slug } = useSelector(state => state?.slugReducer);
    const segmentsReducer = useSelector(state => state?.segmentsReducer); // Assuming this holds { segment: name } structure
    const { setAlert } = useAlert();

    // Pagination count
    const defaultCount = localStorage?.getItem('audience_count') ?? 10;

    const [filter, setFilter] = useState({
        page: 1,
        orderBy: null,
        filter: null, // Status filter (subscribed, unsubscribed)
        filterTag: null, // Segment filter
        orderType: "desc",
        count: defaultCount,
        search: '',
    });

    // Fetch data
    const callAudience = useCallback(() => {
        if (!slug) return;
        setLoading(true);
        showLoader();

        const params = new URLSearchParams();
        params.append('page', filter.page);
        params.append('count', filter.count);
        if (filter.orderBy) params.append('orderBy', filter.orderBy);
        if (filter.orderType) params.append('orderType', filter.orderType);
        if (filter.filter) params.append('filter', filter.filter);
        if (filter.filterTag) params.append('filterTag', filter.filterTag);
        if (filter.search) params.append('search', filter.search);

        getAudience(slug, params.toString())
            .then((response) => {
                setAudienceData(response || { model: null, commonTag: [] });
                // Process tags and checked tags from the response
                 if (response?.model?.data?.length > 0) {
                    const firstAudienceMember = response.model.data[0];
                    try {
                        const audienceTags = JSON.parse(firstAudienceMember?.project?.audience_tags || '[]');
                        setTags(Array.isArray(audienceTags) ? audienceTags : []);
                    } catch (e) {
                         console.error("Error parsing segments:", e);
                         setTags([]);
                    }

                    const newCheckedTagsMap = {};
                    response.model.data.forEach(member => {
                        try {
                            const memberTags = JSON.parse(member.tags || '[]');
                            newCheckedTagsMap[member.id] = Array.isArray(memberTags) ? memberTags : [];
                        } catch (e) {
                            console.error(`Error parsing segments for profile ${member.id}:`, e);
                            newCheckedTagsMap[member.id] = [];
                        }
                    });
                    setCheckedTagsMap(newCheckedTagsMap);
                } else {
                    // Reset tags if no profiles data
                    setTags([]);
                    setCheckedTagsMap({});
                }
            })
            .catch(error => {
                console.error("Failed to fetch profiles:", error);
                setAlert({ type: 'error', title: 'Error', message: 'Failed to load profiles data.' });
                setAudienceData({ model: null, commonTag: [] }); // Reset on error
            })
            .finally(() => {
                setLoading(false);
                hideLoader();
                setSelectedRows([]); // Deselect rows on data refresh
            });
    }, [slug, filter, setAlert]); // Dependencies for useCallback

    const fetchDefaultCounts = useCallback(() => {
         if (!slug) return;
         getDefaultAudience(slug).then((response) => {
             setDefaultAudienceCounts(response || {});
         }).catch(error => {
             console.error("Failed to fetch default profiles counts:", error);
         });
    }, [slug]);


    useEffect(() => {
        fetchDefaultCounts(); // Fetch counts once slug is available
    }, [fetchDefaultCounts]);

    useEffect(() => {
        callAudience(); // Fetch profiles data when slug or filter changes
    }, [callAudience]);

    // Fetch available segments if not already done
    useEffect(() => {
        async function fetchAvail() {
            // Fetch logic using segmentApi.getSegments...
            const response = await segmentApi.getSegments(slug, { count: 500 });
            setAvailableSegments(response?.data?.data || []);
        }
        if(slug) fetchAvail();
    }, [slug]);

    // Event handlers
    const handleSelectRow = (id, isSelected) => {
        setSelectedRows(prev =>
            isSelected ? [...prev, id] : prev.filter(rowId => rowId !== id)
        );
    };

    const handleSelectAll = (event) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            const allIds = audienceData?.model?.data?.map(member => member.id) || [];
            setSelectedRows(allIds);
        } else {
            setSelectedRows([]);
        }
    };

    const confirmDelete = (id = null) => {
        if (id) { // Single delete
            setConfirmationModalShow({ show: true, text: "Are you sure you want to delete this Profile?", id: id, deleteAll: false });
        } else { // Bulk delete
            if (selectedRows.length === 0) return;
            setConfirmationModalShow({ show: true, text: `Are you sure you want to delete ${selectedRows.length} selected Profiles?`, id: null, deleteAll: true });
        }
    };

     const executeDelete = () => {
        const { id, deleteAll } = confirmationModalShow;
        showLoader();
        if (deleteAll) {
            // Bulk Delete Logic
             const api = apiUrl + "/community/subscribers/delete-all";
            const myHeaders = new Headers({ "Authorization": `Bearer ${localStorage.getItem("token")}` });
            const formData = new FormData();
            formData.append('slug', slug);
            selectedRows.forEach(rowId => formData.append("id[]", rowId));

            audiencePosts(setAlert, api, hideLoader, { method: "POST", headers: myHeaders, body: formData })
                .then(() => {
                    setAlert({ type: 'success', title: 'Success', message: 'Selected Contacts have been deleted.' });
                    callAudience(); // Refresh data
                    fetchDefaultCounts(); // Refresh counts
                })
                .catch(() => { /* Error handled by audiencePosts */ })
                .finally(() => setConfirmationModalShow({ show: false, text: null, id: null, deleteAll: false }));

        } else if (id) {
            // Single Delete Logic
            const api = apiUrl + "/community/subscribers/delete/" + id;
            const myHeaders = new Headers({ "Authorization": `Bearer ${localStorage.getItem("token")}` });

            audiencePosts(setAlert, api, hideLoader, { method: "DELETE", headers: myHeaders })
                .then(() => {
                     setAlert({ type: 'success', title: 'Success', message: 'Audience member deleted.' });
                    callAudience(); // Refresh data
                     fetchDefaultCounts(); // Refresh counts
                })
                .catch(() => { /* Error handled by audiencePosts */ })
                .finally(() => setConfirmationModalShow({ show: false, text: null, id: null, deleteAll: false }));
        } else {
             hideLoader();
             setConfirmationModalShow({ show: false, text: null, id: null, deleteAll: false });
        }
    };

    const handleShowEditSegmentsModal = (memberId, memberName, currentSegments) => {
        setEditingMember({ id: memberId, name: memberName, initialSegments: currentSegments || [] });
        setShowEditModal(true);
    };

    const handleHideEditSegmentsModal = () => {
        setShowEditModal(false);
        setEditingMember({ id: null, name: null, initialSegments: [] });
        // Optionally refetch the main list if segments might affect filtering/display significantly
        // callAudience(); or callPhoneContacts();
    };

    const handleUnsubscribe = (id) => {
        showLoader();
        const api = apiUrl + "/community/subscribers/unsubscribe";
        const myHeaders = new Headers({ "Authorization": `Bearer ${localStorage.getItem("token")}` });
        const formData = new FormData();
        formData.append("subscriber", id);

        audiencePosts(setAlert, api, hideLoader, { method: "POST", headers: myHeaders, body: formData })
            .then(() => {
                setAlert({ type: 'success', title: 'Success', message: 'User unsubscribed.' });
                callAudience(); // Refresh
                 fetchDefaultCounts(); // Refresh counts
            })
            .catch(() => { /* Error handled by audiencePosts */ });
    };

    const handleCopyEmail = (email) => {
        navigator.clipboard.writeText(email)
            .then(() => setAlert({ type: 'success', title: 'Copied', message: `Email ${email} copied to clipboard.` }))
            .catch(err => setAlert({ type: 'error', title: 'Error', message: 'Could not copy email.' }));
    };

    const handleExport = () => {
        showLoader();
        const api = apiUrl + "/community/subscribers/export";
        const formdata = new FormData();
        formdata.append("project", slug);
        const myHeaders = new Headers({ "Authorization": `Bearer ${localStorage.getItem("token")}` });

        audiencePosts(setAlert, api, hideLoader, { method: "POST", headers: myHeaders, body: formdata }, "export")
            .catch(() => { /* Error handled by audiencePosts */ });
    };

    const handleAddNewTag = (newTagName, newTagColor, memberId = null) => {
        // Adds tag globally AND potentially updates the specific member if memberId provided
         showLoader();
        const api = apiUrl + "/community/subscribers/add-tag";
        const myHeaders = new Headers({ "Authorization": `Bearer ${localStorage.getItem("token")}` });
        const formdata = new FormData();
        formdata.append('slug', slug);
        formdata.append('tag', newTagName);
        formdata.append('color', newTagColor);
        // If memberId is present, we might want to immediately assign it
        // This depends on backend capability, or we can do it client-side first
        // formdata.append('assign_to', memberId); // Example if backend supports

        audiencePosts(setAlert, api, hideLoader, { method: "POST", headers: myHeaders, body: formdata })
            .then(() => {
                 setAlert({ type: 'success', title: 'Success', message: `Segment "${newTagName}" added.` });
                // Optimistically update UI or refetch
                const newTag = { tag: newTagName, color: newTagColor };
                setTags(prev => [...prev, newTag]);

                // If memberId was provided, optimistically update that member's tags
                if (memberId) {
                     setCheckedTagsMap(prevMap => ({
                        ...prevMap,
                        [memberId]: [...(prevMap[memberId] || []), newTag]
                    }));
                    // Optionally call the single tag toggle API immediately after global add success
                    handleTagToggle(memberId, newTag, true, false); // Add tag to member, don't show loader again
                }
            })
            .catch(() => { /* Error handled */ });
    };

     const handleTagToggle = (memberId, tag, isChecked, showLoad = true) => {
        // Handles adding/removing a tag for a SINGLE member
        if (showLoad) showLoader();
        const api = apiUrl + "/community/subscribers/tag-change";
        const myHeaders = new Headers({ "Authorization": `Bearer ${localStorage.getItem("token")}` });
        const formdata = new FormData();
        formdata.append('slug', slug);
        formdata.append('value', JSON.stringify(tag)); // Send the tag object {tag, color}
        formdata.append('subscriber_id', memberId);
        formdata.append('checked', isChecked ? 1 : 0);

        // Optimistic UI Update
        setCheckedTagsMap(prevMap => {
            const currentTags = prevMap[memberId] || [];
            const updatedTags = isChecked
                ? [...currentTags, tag]
                : currentTags.filter(t => t.tag !== tag.tag);
            return { ...prevMap, [memberId]: updatedTags };
        });


        audiencePosts(setAlert, api, hideLoader, { method: "POST", headers: myHeaders, body: formdata })
            .then(() => {
                // Optional: Confirmation alert, UI already updated optimistically
                // setAlert({ type: 'success', title: 'Success', message: `Segment updated.` });
                // Refetch might be needed if backend modifies data in unexpected ways
                 // callAudience(); // Uncomment if optimistic update is not reliable
            })
            .catch(() => {
                // Revert Optimistic Update on failure
                 setAlert({ type: 'error', title: 'Error', message: `Failed to update segment.` });
                 setCheckedTagsMap(prevMap => {
                    const currentTags = prevMap[memberId] || [];
                    // Revert the change
                    const revertedTags = !isChecked
                        ? [...currentTags, tag]
                        : currentTags.filter(t => t.tag !== tag.tag);
                    return { ...prevMap, [memberId]: revertedTags };
                 });
            });
    };

     const handleBulkTagToggle = (tag, isChecked) => {
        // Handles adding/removing a tag for MULTIPLE selected members
        if (selectedRows.length === 0) return;
        showLoader();
        const api = apiUrl + "/community/subscribers/bulk-add-tag";
        const myHeaders = new Headers({ "Authorization": `Bearer ${localStorage.getItem("token")}` });
        const formdata = new FormData();
        formdata.append('slug', slug);
        formdata.append('value', JSON.stringify(tag));
        formdata.append('checked', isChecked ? 1 : 0);
        selectedRows.forEach(id => formdata.append("id[]", id));

        // Optimistic UI Update for all selected rows
         setCheckedTagsMap(prevMap => {
            const newMap = { ...prevMap };
            selectedRows.forEach(id => {
                const currentTags = newMap[id] || [];
                 const updatedTags = isChecked
                    ? [...currentTags.filter(t => t.tag !== tag.tag), tag] // Avoid duplicates
                    : currentTags.filter(t => t.tag !== tag.tag);
                newMap[id] = updatedTags;
            });
            return newMap;
        });


        audiencePosts(setAlert, api, hideLoader, { method: "POST", headers: myHeaders, body: formdata })
            .then(() => {
                // Optional: success alert
                // callAudience(); // Consider refetch if optimistic update might be inaccurate
            })
            .catch(() => {
                 setAlert({ type: 'error', title: 'Error', message: `Failed to bulk update segment.` });
                 // Revert optimistic update (more complex, might need original state snapshot)
                 // Easiest might be to just refetch on error
                 callAudience();
            });
    };


    // --- Render ---
    const audienceList = audienceData?.model?.data || [];
    const hasAudience = audienceList.length > 0;
    const totalRecords = audienceData?.model?.total ?? 0;
    const audienceDisplayConfig = {
        primaryIdField: 'email',
        primaryIdLabel: 'Email Address',
        showStatus: true,
        showRevenue: true,
        segmentLabel: 'Segment',
        sortableColumns: ['name', 'created_at', 'revenue'],
        statusDropdownOptions: ['All', 'Subscribers', 'Unsubscribed'],
        defaultAudienceCounts: defaultAudienceCounts
    };
    return (
        <div id="wrapper">
            <div className="d-flex new-sidebar">
                <Sidebar from={"audience"} />

                <section className="community-section pb-0 flex-grow-1">
                    {/* Header */}
                    <div className="section-header d-flex justify-content-between align-items-center p-3 border-bottom">
                        <h1 className="mb-0 h5">Email Profiles</h1>
                        <div className="d-flex align-items-center">
                            <HeaderSearch filter={filter} setfilter={setFilter} name="audience" />

                            <Button variant="light" className="btn-semi-rounded ms-2 d-flex align-items-center" onClick={() => setShowImportModal(true)}>
                                <img src={addUserIcon} width="14px" alt="" className="me-1" />
                                Import
                            </Button>

                            <Button variant="danger" className="btn-red btn-semi-rounded ms-2 d-flex align-items-center" onClick={handleExport} disabled={!hasAudience || loading}>
                                <img src={exportIcon} width="14px" alt="" className="me-1" />
                                Export
                            </Button>
                        </div>
                    </div>

                    {/* Main Content */}
                    <div className="section-main p-3">
                         {!loading && !hasAudience && filter.page === 1 && !filter.filter && !filter.filterTag && !filter.search ? (
                             <EmptyState
                                from={"audience"}
                                firstText={'Woah! It’s quiet in here...'}
                                secondText={"Start building your email list by adding the signup form to your page or by importing your existing contacts."}
                                buttonText={"Import"}
                                clickedFunction={() => setShowImportModal(true)}
                            />
                         ) : (
                            <>
                                <AudienceTable
                                    loading={loading}
                                    audienceData={audienceList}
                                    totalAudienceCount={audienceList.length} // Count in current view
                                    filter={filter}
                                    setFilter={setFilter}
                                    selectedRows={selectedRows}
                                    handleSelectAll={handleSelectAll}
                                    handleSelectRow={handleSelectRow}
                                    defaultAudienceCounts={defaultAudienceCounts}
                                    segments={segmentsReducer} // Pass redux segments
                                    tags={tags} // Pass available tags {tag, color}
                                    displayConfig={audienceDisplayConfig}
                                    checkedTagsMap={checkedTagsMap} // Pass map {id: [tags]}
                                    onShowEditSegmentsModal={handleShowEditSegmentsModal}
                                    tags={availableSegments} // Pass available segments (used by BulkActionsBar)
                                    onUnsubscribe={handleUnsubscribe}
                                    onCopyEmail={handleCopyEmail}
                                    onTagToggle={handleTagToggle} // Single tag change
                                    onAddNewTag={handleAddNewTag} // Add new tag globally
                                    onDelete={() => confirmDelete(confirmationModalShow.id)} // Pass function for single delete confirmation
                                    onDeleteAll={() => confirmDelete()} // Pass function for bulk delete confirmation
                                    onBulkTagToggle={handleBulkTagToggle} // Bulk tag change
                                />

                                {/* Footer */}
                                <div className="section-footer mt-3 d-flex align-items-center justify-content-between">
                                    {totalRecords > (parseInt(filter.count) || 10) ? (
                                        <PaginationFooter
                                            from={"audience"}
                                            loading={loading}
                                            filter={filter}
                                            setfilter={setFilter}
                                            total={totalRecords}
                                            last_page={audienceData?.model?.last_page ?? 1}
                                            current_page={audienceData?.model?.current_page ?? 1}
                                            perPage={parseInt(filter.count) || 10} // Pass perPage
                                        />
                                    ) : (
                                        // Optionally show total count even if no pagination needed
                                        <span className="text-muted">{totalRecords} results</span>
                                    )}
                                </div>
                            </>
                         )}
                    </div>
                </section>
            </div>

            {showEditModal && (
                <EditSegmentsModal
                    show={showEditModal}
                    onHide={handleHideEditSegmentsModal}
                    memberId={editingMember.id}
                    memberName={editingMember.name}
                    slug={slug}
                    memberType={ "audience" } // Or "phone" for phone.jsx
                    initialAvailableSegments={availableSegments} // Pass fetched segments
                    // ** Crucially, pass the initial checked segments **
                    // ** Remove this prop once the modal can fetch the member's segments itself **
                    initialCheckedSegments={editingMember.initialSegments}
                />
            )}

            {/* Modals */}
            <ImportAudienceModal
                 callAudience={callAudience} // Pass callback to refresh data
                 tags={tags} // Pass current tags
                 show={showImportModal}
                 handleHide={() => setShowImportModal(false)}
                 // Removed setshowImportModal prop if handleHide covers it
            />
            {confirmationModalShow.show && (
                <ConfirmationModal
                    confirmationModalShow={confirmationModalShow}
                    setShow={setConfirmationModalShow}
                    callback={executeDelete} // The function to call on confirm
                />
            )}
        </div>
    );
};

export default Email;