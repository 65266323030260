import React from 'react'

const SaveChangesBar = ({showSaveChanges, revertChanges, submitAllChanges, from}) => {
  return (
        showSaveChanges?.show && showSaveChanges?.formId?.length > 0 &&
        <div className={`show-save-changes show-save-changes-${from}`}>
            <span className='btn-ededed fs-12px'>{showSaveChanges?.totalChanges} Unpublished {showSaveChanges?.totalChanges === 1 ? 'Change' : 'Changes'}</span>
            <div>
                <button className='btn-gray ml-10-u' onClick={revertChanges}>Revert Changes</button>
                <button className='blue-btn ml-10-u' onClick={submitAllChanges}>Publish Changes</button>
            </div>
        </div>
  )
}

export default SaveChangesBar