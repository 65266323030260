import returnIcon from "../../assets/img/modals/return.svg"
// import removeicon from "../../assets/img/products/removeicon.svg";
import * as $ from "jquery";
import { useHistory, useParams } from "react-router-dom";
import Loader, { hideLoader, showLoader } from "../loader";
import { Modal, Offcanvas } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { apiUrl } from "../../utils/url";
import { toast } from "react-toastify";
import ErrorMessage from "../errors/errorMessage";
import { useState, useEffect, useRef } from "react";
import TeamModal from "../teammember/team_modal";
import { useAlert } from "../../contexts/AlertContext";
import SwitchButton from "../buttons/SwitchButton";


const DiscountCodesModal = ({ from, show, setShow, callAllCodes, edit, setIsEdit, duplicate, setIsDuplicate }) => {
    let history = useHistory();
    const codesModalReducer = useSelector(state => state.codeModalReducer);
    const { basePath, slug } = useSelector(state => state?.slugReducer);
    const [error, setError] = useState([])
    const dispatch = useDispatch();
    const [showEndDate, setShowEndDate] = useState(false);
    const [showModalasd, setShowModalasd] = useState(false)
    const isPro = useSelector(state => state?.proReducer);
    const newUser = useSelector(state => state?.newUserReducer);
    const isEligible = useSelector(state => state?.eligibilityReducer);
    const [checkedProducts, setCheckedProducts] = useState([]);
    const { setAlert } = useAlert();
    const [allProducts, setAllProducts] = useState([])
    
    let editid = edit?.id;
    const removeModal = () => {
        setShow(false)
        sessionStorage.removeItem("isEdit")
        setIsEdit({ is: false, id: null })
        sessionStorage.removeItem("isDuplicate")
        setIsDuplicate({ is: false, id: null })
        history.push(basePath + "/" + from)
    };
    const [loading, setloading] = useState(true);
    const handleSubmit = () => {
        const apiName = edit?.is == true ? "update" : "create"
        sessionStorage.removeItem("isEdit")
        showLoader();
        setError([])
        var form_data = new FormData();
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        for (const key in codesModalReducer) {
            if (Object.hasOwnProperty.call(codesModalReducer, key)) {
                if (codesModalReducer[key] != null) {
                    if (key === "products" && Array.isArray(codesModalReducer[key])) {
                        codesModalReducer[key]?.forEach(innerArray => {
                            if (Array.isArray(innerArray)) {
                                innerArray?.forEach(id => {
                                    form_data.append("products[]", id);
                                });
                            } else {
                                form_data.append("products[]", innerArray);
                            }
                        });
                    } else {
                        form_data.append(key, codesModalReducer[key]);
                    }
                }
            }
        };
        form_data.append('project', slug);
        if (apiName == "update") {
            form_data.append('code_id', edit?.id);
        }
        const setOptions = {
            method: "POST",
            body: form_data,
            headers: myHeaders,
        };
        const api = apiUrl + "/codes/" + apiName;
        fetch(api, setOptions)
            .then(async (res) => {
                const response = await res?.json();
                if (response?.status_code == 200) {
                    history.push(basePath + "/codes");
                    setShow(false)
                    callAllCodes();
                    hideLoader();
                    setAlert({ type: 'success', title: `Code ${apiName == "update" ? 'Updated' : 'Created'} Successfully` })
                    // toast.success(`Code ${apiName == "update" ? 'Updated' : 'Created'} Successfully `)
                } else {
                    setError(Object.values(response?.errors));
                    if (Object.keys(response?.errors).length > 0)
                        $(".modal-body").scrollTop(0);
                    hideLoader();
                }
            })
            .catch(() => {
                hideLoader();
            });
    };
    // Get today's date in YYYY-MM-DD format
    const today = new Date().toISOString().split("T")[0];

    useEffect(() => {
        if (isEligible) {
            if (allProducts && codesModalReducer?.products && codesModalReducer?.products !== 'all_products') {
                const checkedProductsData = JSON?.parse(codesModalReducer?.products)?.map(item => {
                    console.log("item: " + item)
                    console.log("allProducts",allProducts)
                    const matchedProduct = allProducts.find(product => product.id === parseInt(item));
                    return {
                        id: matchedProduct?.id,
                        name: matchedProduct?.name
                    };
                });
                setCheckedProducts(checkedProductsData);
            }
        }
    }, [slug,allProducts])

    // Checkbox deselect hone pe end date ko payload se completely remove karna
    useEffect(() => {
        if (!showEndDate) {
            const updatedPayload = { ...codesModalReducer };
            delete updatedPayload.date_range_end;
            dispatch({ type: "SET_CODE_MODAL", payload: updatedPayload });
        }
    }, [showEndDate]);

    // Default start date set karna
    useEffect(() => {
        dispatch({ type: "SET_CODE_MODAL", payload: { ...codesModalReducer, 'date_range': today } });
    }, [today]);

    useEffect(() => {
        if (codesModalReducer?.date_range_end) {
            setShowEndDate(true);
        } else {
            setShowEndDate(false);
        }
    }, [codesModalReducer?.date_range_end])


    const handleChange = (e) => {
        dispatch({ type: "SET_CODE_MODAL", payload: { ...codesModalReducer, [e.target.name]: e.target.value } })
    };
    const handleDropdownChange = (val, key) => {
        if (val == 0) {
            dispatch({ type: "SET_CODE_MODAL", payload: { ...codesModalReducer, products: "all_products" } })
            setCheckedProducts([])
        } else {
            dispatch({ type: "SET_CODE_MODAL", payload: { ...codesModalReducer, products: [] } })
        }
    };
    const handleProductChange = (e, product) => {
        const isChecked = e.target.checked;
        let updatedProducts;

        if (isChecked) {
            updatedProducts = [...checkedProducts, product];
        } else {
            updatedProducts = checkedProducts.filter(p => p.id !== product.id);
        }

        setCheckedProducts(updatedProducts);

        dispatch({
            type: "SET_CODE_MODAL",
            payload: {
                ...codesModalReducer,
                products: [updatedProducts.map(p => p.id)]
            }
        });
    }
    const getProducts = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const setOptions = {
            method: "GET",
            headers: myHeaders,
          };
        const api = apiUrl + "/codes/get-products/" + slug;
          fetch(api, setOptions)
            .then(async (res) => {
              const response = await res?.json();
              if (response?.status_code === 200) {
                setAllProducts(response?.data);
              } else {
              }
            })
            .catch(() => {
              hideLoader();
            });
    };
    useEffect(() => {
        if (slug && isEligible && allProducts?.length === 0){
            getProducts()
        }
    }, [isEligible,slug])
    return (
        <>
            <Modal id="newNewsletterModal" show={show} onHide={removeModal} placement="end" style={{ width: "auto", margin: 0 }} className="right marketing-section-modal" >
                <div class="modal-dialog modal-dialog-centered" role="document" style={{ height: "100%", margin: 0 }}>
                    <div class="modal-content p-0 zaap-border-straight" style={{ height: "100%" }}>

                        <div class="modal-header send-newsletter-header" style={{ border: "none", }}>
                            <h4 class="modal-title create-biolink-heading posthead">{edit?.is ? `Edit` : `Create`} Discount</h4>

                            <button type="button" class="close-new" onClick={removeModal}>
                                <span aria-hidden="true">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="10" viewBox="0 0 9 10" fill="none">
                                        <path d="M8 1.5L1 8.5M1 1.5L8 8.5" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>Exit
                                </span>
                            </button>
                        </div>

                        <div class="modal-body body-u">

                            <ErrorMessage error={error} />

                            <div class="form-group">
                                <label class="text-gray font-weight-500"><small>Discount Code</small></label>
                                <input type="text" style={{ marginTop: "6px" }} value={codesModalReducer?.code} onChange={handleChange} class="form-control zaap-form-control new-title-inp" name="code" required="required" placeholder="Eg: BlackFriday10" />
                            </div>

                            <div class="form-group mt-5 mb-4">
                                <div class="up-in-toggle bg-f1f1f1 w-50 btn-semi-rounded" style={{ padding: "5px 3px" }}>
                                    <input type="radio" id="code_type_left" onChange={handleChange} name="code_type" defaultChecked={codesModalReducer?.code_type == "fixed" ? true : false} style={{ display: "none" }} value={"fixed"} />
                                    <label for="code_type_left" class="btn-semi-rounded toggletext newsletter-btn-semi-rounded " >Fixed</label>
                                    <input type="radio" id="code_type_right" onChange={handleChange} name="code_type" defaultChecked={codesModalReducer?.code_type == "percentage" ? true : false} style={{ display: "none" }} value={"percentage"} />
                                    <label for="code_type_right" class="btn-semi-rounded toggletext newsletter-btn-semi-rounded "  > Percentage</label>
                                </div>
                            </div>

                            <div class="form-group" style={{ marginTop: "24px" }} >
                                <label class="text-gray font-weight-500"><small>Amount</small></label>
                                <input type="number" style={{ marginTop: "6px" }} onChange={handleChange} class="form-control zaap-form-control new-title-inp" name="amount" value={codesModalReducer?.amount} required="required" placeholder="Eg: 10%" />
                            </div>
                            <div class="form-group" style={{ marginTop: "13px" }} >
                                <label class="text-gray font-weight-500"><small>Number of Uses</small></label>
                                <input type="number" style={{ marginTop: "6px" }} onChange={handleChange} class="form-control zaap-form-control new-title-inp" name="no_of_uses" value={codesModalReducer?.no_of_uses} required="required" placeholder="Eg: 10" />
                            </div>

                            <div className="form-group" style={{ marginTop: "13px" }}>
                                {/* Start Date Input */}
                                <div className="date-picker-label">
                                    <label htmlFor="start_date">Start Date</label>
                                    <input type="date" onChange={handleChange} class="form-control zaap-form-control new-title-inp" name="date_range" value={codesModalReducer?.date_range || today} required="required" placeholder="Eg: 10%" />
                                </div>

                                {/* Checkbox to Show/Hide End Date */}
                                <div className="switch-check">
                                    <input type="checkbox" id="setEndDate" checked={showEndDate} onChange={() => setShowEndDate(!showEndDate)} />
                                    <label htmlFor="setEndDate">Set end date</label>
                                </div>

                                {/* End Date Input (Hidden by Default) */}
                                {showEndDate && (
                                    <div className="date-picker-label">
                                        <label htmlFor="end_date">End Date</label>
                                        <input type="date" onChange={handleChange} class="form-control zaap-form-control new-title-inp" name="date_range_end" value={codesModalReducer?.date_range_end} required="required" placeholder="Eg: 10%" />
                                    </div>
                                )}
                            </div>

                            <div class="form-group position-relative" style={{ marginTop: "13px" }} >
                                <label class="text-gray font-weight-500"><small>Products</small></label>
                                <SwitchButton
                                    label="products"
                                    customClass={"w-fit-content"}
                                    options={[
                                        { label: 'All', value: 0 },
                                        { label: 'Selected', value: 1 },
                                    ]}
                                    value={codesModalReducer?.products == 'all_products' ? 0 : 1 }
                                    onChange={(value) => handleDropdownChange(value, 'products')}
                                />
                                {codesModalReducer?.products !== 'all_products' &&
                                    <>
                                        <div data-bs-display="static" data-bs-placement="bottom-start" onClick={(e) => e.stopPropagation()} class="mt-2 flex-wrap align-items-center cursor-pointer zaap-form-control new-title-inp add-products-dropdown dropdown-toggle noselect d-md-flex gap-1" id="add-products-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                            Select Products:
                                            {checkedProducts.map((product) => (
                                                <span className="selected-products" key={product?.id}>{product?.name}</span>
                                            ))}
                                        </div>
                                        <ul className="code-products-dropdown dropdown-menu" aria-labelledby="add-products-dropdown">
                                            {allProducts?.length > 0 ? allProducts?.map((value, index) => {
                                                const isChecked = checkedProducts?.some(val => val?.id == value?.id);
                                                return (
                                                    <li key={index} className="dropdown-item code-products-option text-truncate" onClick={() => {
                                                        const checkbox = document.getElementById(`product-checkbox-${value?.id}-${index}`);
                                                        if (checkbox) {
                                                            checkbox.click();
                                                        }
                                                    }}>
                                                        <input
                                                            data-id={value?.id}
                                                            onChange={(e) => handleProductChange(e, value)}
                                                            className="product-checked "
                                                            type="checkbox"
                                                            id={`product-checkbox-${value?.id}-${index}`}
                                                            style={{ display: 'none' }}
                                                            checked={isChecked}
                                                        />
                                                        <label onClick={(e) => e.stopPropagation()} style={{ backgroundColor: value?.color }} className="small-box" htmlFor={`product-checkbox-${value?.id}-${index}`}>
                                                        </label>
                                                        <span className={`cursor-pointer  ${isChecked ? 'checked-product' : ''}`}>{value?.name}</span>
                                                    </li>)
                                            }) : <div className="mx-auto fs-12px d-flex justify-content-center align-items-center" style={{ width: "200px", height: "100px" }}>
                                                No purchasable products found.
                                            </div>
                                            }
                                        </ul>

                                    </>
                                }
                            </div>

                        </div>

                        <div class="send-newsletter-footer">
                            <button type="button" class="btn btn-primary btn-semi-rounded d-flex align-items-center send-newsletter-btn justify-content-center py-2 px-3" onClick={handleSubmit}>
                                <b class="me-2 newbtn-u2">Publish</b>
                                <small class="badge bg-primary-dark text-dark px-2 py-1 d-flex align-items-center justify-content-center">
                                    <img src={returnIcon} alt="" />
                                </small>
                            </button>
                        </div>

                    </div>

                </div>
            </Modal>
            {showModalasd && <TeamModal showModal={showModalasd} heading={'Upgrade to access Codes.'} text={' This is a Pro Feature<br /> Upgrade to Zaap Pro to unlock this feature.'} isUltra={true} />}
        </>
    )
}
export default DiscountCodesModal