export const createFormDataValues = (values) => {
    const form_data = new FormData();
    for (const key in values) {
        if (Object.hasOwnProperty.call(values, key)) {
            if (values[key])
                form_data.append(key, values[key])
        }
    };
    return form_data
}

export const getInitials = (name) => {
    if (!name) return '?';
    const parts = name.split(' ').filter(Boolean);
    if (parts.length === 0) return '?';
    if (parts.length === 1) return parts[0].substring(0, 1).toUpperCase();
    return (parts[0].substring(0, 1) + parts[parts.length - 1].substring(0, 1)).toUpperCase();
}


export const generateRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};