import apiClient from './config';

export default {
    createPhone(data) {
        return apiClient.post('/community/subscribers/phone/create', data);
    },

    deletePhone(id) {
        return apiClient.delete(`/community/subscribers/phone/delete/${id}`);
    },

    getPhoneContacts(slug, params) {
        return apiClient.get('/community/subscribers/phone/get/'+slug, { params });
    },

};