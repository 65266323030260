const INIT_STATE = [];
const segmentsReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case 'SET_SEGMENTS':
            return action.payload
        case 'RESET_SEGMENTS':
            return INIT_STATE
        default:
            return state
    }
}
export default segmentsReducer