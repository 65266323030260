import { Link, NavLink, useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { useEffect, useState, useRef } from "react"
import { useAlert } from "../../contexts/AlertContext";
import { useSidebar } from "../../contexts/SidebarContext";
import DashboardProjectsModal from "../dashboard/projects/modal";
import ConfirmationModal from "../modals/confirmation-modal";
import NewBottomBar from "./new-bottombar";
import { hideLoader, showLoader } from "../loader";
import { apiUrl, appUrl } from "../../utils/url";
import { audiencePosts as apiService } from "../../api/audience";
import { redirectToWalkthrough } from "../../auth/google-sign";
import menuCommunityIcon from "../../assets/img/sidebar-audience-icon.svg";
import menuNewsletterIcon from "../../assets/img/sidebar-post-icon.svg";
import broadcastIcon from "../../assets/img/broadcast-icon.svg";
import closedArrow from "../../assets/img/sidebar-close.svg";
import openedArrow from "../../assets/img/sidebar-open.svg";
import userIcon from "../../assets/img/products/user-icon.svg";
import chevronDown from "../../assets/img/chevron-down.svg";
import starIcon from "../../assets/img/star.svg";
import payoutsIcon from "../../assets/img/menu-payout-settings.svg";
import dotsIcon from "../../assets/img/gear.svg";
import linkIcon from "../../assets/img/page/black-link-icon.svg";
import announceIcon from "../../assets/img/announce-icon.svg"
import logoIcon from "../../assets/img/logo-icon.svg"
import logoText from "../../assets/img/logo-text.svg"
import styles from "../../styles/Sidebar.module.css";
import PrimaryButton from "../buttons/PrimaryButton";

const DropdownArrow = () =>{
  return(
      <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.6148 0.39565C11.1284 0.923184 11.1284 1.77849 10.6148 2.30602L6.42993 6.60435C5.91632 7.13188 5.0836 7.13188 4.56999 6.60435C4.05638 6.07682 4.05638 5.22151 4.56999 4.69398L8.75485 0.39565C9.26846 -0.131883 10.1012 -0.131884 10.6148 0.39565Z" fill="#333333"/>
        <path d="M6.42993 6.60435C5.91632 7.13188 5.0836 7.13188 4.56999 6.60435L0.385206 2.30668C-0.128402 1.77914 -0.128402 0.92384 0.385206 0.396306C0.898814 -0.131227 1.73154 -0.131228 2.24515 0.396306L6.43001 4.69464C6.94362 5.22217 6.94354 6.07682 6.42993 6.60435Z" fill="#333333"/>
      </svg>
  )
}

const Sidebar = ({
  from = "dashboard",
  newAccountCreated,
  sidebarOpen,
  onMenuClick,
  activePage,
  selectedProfile,
  projects,
  isPro,
  plan
}) =>
{
  // Get values from context
  const {
    projectsState,
    sharedProjectsState,
    isLoadingState,
    basePathState,
    selectedSlugState,
    selectedProfileState,
    setSelectedProfileState,
    setSelectedProjectState,
    profile,
    title,
    projectSlug,
    changeProject,
    refreshProjects,
  } = useSidebar();

  const router = useHistory();
  const location = useLocation();
  const history = useHistory();
  const sidebarRef = useRef(null);

  const [planLabel, setPlanLabel] = useState('Loading...');
  const [showModal, setShowModal] = useState({ show: false, duplicate: false });
  const handleCloseModal = () => setShowModal({ show: false, duplicate: false });
  const handleOpenModal = () => setShowModal({ show: true, duplicate: false });

  useSelector((state) => state.accountsModalReducer);
  const reduxPro = useSelector(state => state?.proReducer);
  const reduxPlanType = useSelector(state => state?.plantypeReducer);
  const finalPro = isPro !== undefined ? isPro : reduxPro;
  const finalPlanType = plan !== undefined ? plan : reduxPlanType;
  const storedValue = localStorage.sidebarOpen;

  const containerRef = useRef(null);
  const scrollToRef = useRef(null);

  const handleScrollToBottom = () =>
  {
    if (containerRef.current && scrollToRef.current) {
      const container = containerRef.current;
      const target = scrollToRef.current;

      // Calculate the bottom position with an offset (e.g., 20px above)
      const offset = 20;
      container.scrollTo({
        top: target.offsetTop - container.offsetTop - offset,
        behavior: "smooth",
      });
    }
  }

  const [isOpen, setIsOpen] = useState(
    storedValue ? JSON.parse(storedValue) : true
  );

  const [confirmationModalShow, setconfirmationModalShow] = useState({
    show: false,
    text: null,
  });

  const [openDropdown, setOpenDropdown] = useState(null);
  const [openUserDropdown, setOpenUserDropdown] = useState(false);
  const projectDisabledReducer = useSelector(
    (state) => state?.projectDisabledReducer
  );

  const { setAlert } = useAlert();
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [openAudienceMenu, setOpenAudienceMenu] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
    localStorage.setItem("sidebarOpen", !isOpen);
  };

  useEffect(() => {
    // Don't show loader if we have any source of pro information
    if (finalPro === undefined && reduxPro === undefined) {
      showLoader();
    }
    else
    {
      // Determine the final plan label
      let label = 'Free';
      if (finalPro === 1 || finalPro === true) {
        if (!finalPlanType || finalPlanType === 'pro' || finalPlanType === 'pro_legacy') {
          label = 'Pro';
        } else {
          label = finalPlanType.charAt(0).toUpperCase() + finalPlanType.slice(1);
        }
      }

      setPlanLabel(label);
    }
  }, [finalPro, finalPlanType, reduxPro, reduxPlanType]);

  useEffect(() => {
    if (from === "dashboard" && newAccountCreated) {
      localStorage.setItem("sidebarOpen", true);
    }
  }, [from, newAccountCreated]);

  const handleClick = (page) => {
    onMenuClick && onMenuClick(page);
  };

  const toggleSubMenu = (title) => {
    setIsSubMenuOpen((prevTitle) => (prevTitle === title ? null : title));
    if(title == 'analytics'){
      setTimeout(()=>{
        handleScrollToBottom();
      },200)
    }
  };

  const gotoUpgrade = () =>{
    history.push(`/project/${localStorage?.slug}/settings`)
  }

  const deleteProject = (id) => {
    showLoader();
    const api = apiUrl + "/project/delete/" + id;
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
    const setOptions = {
      method: "DELETE",
      headers: myHeaders,
    };
    apiService(setAlert, api, hideLoader, setOptions)
      ?.then((response) => {
        if (response) {
          setconfirmationModalShow({ show: false, text: null });
          const remainingProjects = projectsState.filter(
            (project) => project?.slug !== id
          );
          if (localStorage?.slug === id) {
            if (response?.project_counts >= 1) {
              window.location.href =
                appUrl + "/project/" + remainingProjects[0]?.slug + "/campaigns";
            } else {
              localStorage.setItem("currentStep", 1);
              localStorage.setItem("lastStep", 0);
              localStorage.setItem("walkthrough", 0);
              redirectToWalkthrough(1, router);
            }
          } else {
            setconfirmationModalShow({
              show: false,
              text: null,
              id: null,
              deleteAll: null,
            });
          }
          refreshProjects();
        } else {
          hideLoader();
        }
      })
      .catch(() => hideLoader());
  };

  function checkScreenSize() {
    if (window.innerWidth < 1429 && window.innerWidth > 729) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  }

  useEffect(() => {
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  useEffect(() => {
    console.log("Sidebar data:", {
      projectsState,
      isLoadingState,
      basePathState,
      selectedProfileState,
    });
  }, [projectsState, isLoadingState, basePathState, selectedProfileState]);

  const handleDropdownClick = (e, index) => {
    e.stopPropagation();
    if (openDropdown === index) {
      setOpenDropdown(null);
    } else {
      setOpenDropdown(index);
    }
  };

  console.log('openDropdown', openDropdown)

  useEffect(() => {
    const pathsToCheck = ["analytics/posts", "analytics/page", "analytics/links"];
    const pathsToCheck1 = ['/audience', '/segments'];
    if (pathsToCheck.some((path) => location.pathname.includes(path))) {
      setIsSubMenuOpen(true);
    }
    if (pathsToCheck1.some(path => window.location.pathname.includes(path))) {
      setOpenAudienceMenu(true);
    }
  }, [location]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
          sidebarRef.current &&
          !sidebarRef.current.contains(event.target)
      ) {
        setOpenUserDropdown(false);
        setOpenDropdown(null); // Optional: close the 3-dot dropdown too
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Compute a single container class based on state and props
  const sidebarContainerClass =
    from === "settingsMobile" || from === "accountMobile"
      ? sidebarOpen
        ? styles.sidebarSettingsMobileOpen
        : styles.sidebarSettingsMobileClosed
      : isOpen
      ? styles.sidebarOpenContainer
      : styles.sidebarClosedContainer;

  return (
    <>
      <div className={`${styles.sidebarBase} ${sidebarContainerClass}`}>

        <div className={isOpen ? styles.sidebarLogo : `${styles.sidebarLogo} ${styles.showLogoIcon}`}>
          <img src={logoIcon} alt={'logo-icon'} />
          <img src={logoText} alt={'logo-text'} />
        </div>

        <div className={isOpen ? styles.toggleButton : `${styles.toggleButton} ${styles.toggleButtonClosed}`} onClick={toggleSidebar}>
          <img src={isOpen ? closedArrow : openedArrow} alt="" width={11} height={8} />
        </div>

        {!(from === "settings" ||
            from === "account" ||
            from === "settingsMobile" ||
            from === "accountMobile") && (
            <>
              <div ref={sidebarRef} className={styles.projectDropdownMain}>
                <div onClick={() => setOpenUserDropdown(!openUserDropdown)} className={isOpen ? styles.projectDropdown : `${styles.projectDropdown} ${styles.projectDropdownClosed}`}>
                  <div>
                    {isLoadingState ? (
                        <Skeleton circle highlightColor="#aaa" height={38} width={38} containerClassName={styles.avatarSkeleton} />
                    ) : (
                        <img src={profile} alt="" className={styles.profileImage} />
                    )}
                  </div>

                  {isOpen ?
                    <>
                      <div className={styles.accountDivTitle}>
                        <div className={styles.accountDivTitleMain}>
                          {/*isLoadingState ? <Skeleton /> : title?.length > 12 ? `${title?.slice(0, 12)}...` : title*/}
                          {isLoadingState ? <Skeleton/> : localStorage.user?.length > 12 ? `${localStorage.user?.slice(0, 12)}...` : localStorage.user}
                        </div>
                        <div className={styles.accountDivSubTitle}>
                          <span>zaap.bio/{projectSlug?.length > 10 ? `${projectSlug?.slice(0, 10)}...` : projectSlug}</span>
                        </div>
                        <span className={`${styles.projectType} ${finalPro === 1 || finalPro === true ? (finalPlanType === "ultra" ? `${styles.typeUltra}` : (finalPlanType === 'partner' || finalPlanType === 'lifetime') ? ` ${styles.typePartner}` : ` ${styles.typePro}`) : ` ${styles.typeDraft}`}`} onClick={gotoUpgrade}>{planLabel}</span>
                      </div>
                      <div className={styles.chevronContainer}>
                        <img src={chevronDown} width={11} alt="" className={openUserDropdown ? `${styles.chevronArrow} ${styles.active}` : styles.chevronArrow}/>
                      </div>
                    </> : ''
                  }
                </div>
                {isOpen ?
                    <ul className={openUserDropdown ? `${styles.dropdownMenuVisible} ${styles.projectDropdownMenu}` : styles.projectDropdownMenu}
                        aria-labelledby="project-dropdown">
                      {projectsState?.map((value, index) => {
                        const isProjectEnabled = true;
                        const canChangeProject = isProjectEnabled || from === "dashboard";
                        let planType = value.plan_type ? value.plan_type : 'Free';
                        let proType = value.pro ? value.pro : '';

                        return (
                            <li key={value.id}>
                              <div className={localStorage.slug === value.slug ? styles.projectActive : styles.projectInactive}>
                                <div
                                    className={`${styles.projectDropdownItem} ${
                                        !canChangeProject ? styles.disabledProject : ""
                                    }`}
                                    onClick={(e) => {
                                      if (canChangeProject && localStorage.slug?.toLowerCase() !== value.slug?.toLowerCase()) {
                                        changeProject(
                                            e,
                                            value.id,
                                            value?.slug,
                                            value?.settings[1]?.value,
                                            value?.settings[0]?.value,
                                            isProjectEnabled
                                        );
                                        from === "dashboard" && setSelectedProjectState(true);
                                        setSelectedProjectState(true);
                                      }
                                    }}
                                >
                                  <div className={styles.projectImageContainer}>
                                    <img src={value?.settings[1]?.value} alt="" width="25"/>
                                  </div>
                                  <div className={styles.projectSlug}>@{value?.slug}</div>
                                </div>
                                <div className={styles.projectInfoType}>
                                  <span className={`${styles.projectType} ${proType === 1 || proType === true ? (planType === "ultra" ? `${styles.typeUltra}` : (planType === 'partner' || planType === 'lifetime') ? ` ${styles.typePartner}` : ` ${styles.typePro}`) : ` ${styles.typeDraft}`}`}>{planType}</span>
                                  <div
                                      onClick={(e) => {
                                        handleDropdownClick(e, index);
                                      }}
                                      className={styles.projectSmallActionButton}
                                  >
                                    <img src={dotsIcon} alt="" className={styles.dotsIcon}/>
                                  </div>
                                </div>
                              </div>

                              {openDropdown === index ?
                                  <div>
                                    <ul className={openDropdown === index ? styles.dropdownMenuVisible : styles.dropdownMenuHidden}>
                                      <li>
                                        <Link to={`/project/${value.slug}/settings`} className={styles.dropdownItem}>
                                          <span className={styles.dropdownItemText}>Project Settings</span>
                                        </Link>
                                      </li>
                                      <li>
                                        <Link
                                            to={`project/${value.slug}/duplicate`}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              setShowModal({
                                                show: true,
                                                duplicate: true,
                                                slug: value.slug,
                                              });
                                            }}
                                            className={styles.dropdownItem}
                                        >
                                          <span className={styles.dropdownItemText}>Duplicate</span>
                                        </Link>
                                      </li>
                                      <li>
                                        <Link
                                            to={`delete-projects`}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              setconfirmationModalShow({
                                                show: true,
                                                text: "Are you sure you want to delete this project?",
                                                id: value?.slug,
                                                callBackTo: "delete",
                                              });
                                            }}
                                            data-value={value?.slug}
                                            className={styles.dropdownItem}
                                        >
                                          <span className={styles.dropdownItemText} data-value={value?.slug}>
                                            Delete Project
                                          </span>
                                        </Link>
                                      </li>
                                    </ul>
                                  </div> : ''
                              }
                            </li>
                        );
                      })}
                      {sharedProjectsState?.length >= 1 && (
                          <>
                            <b className={styles.sharedProjectsTitle}>Shared Projects</b>
                            {sharedProjectsState.map((value) => {
                              const isProjectEnabled = true;
                              const canChangeProject = isProjectEnabled || from === "dashboard";
                              return (
                                  <li key={value.id}>
                                    <div
                                        className={localStorage.slug === value.project.slug ? styles.projectActive : styles.projectInactive}>
                                      <div
                                          className={`${styles.projectDropdownItem} ${
                                              !canChangeProject ? styles.disabledProject : ""
                                          }`}
                                          onClick={(e) => {
                                            if (canChangeProject && localStorage.slug?.toLowerCase() !== value.project.slug?.toLowerCase()) {
                                              changeProject(
                                                  e,
                                                  value?.project_id,
                                                  value?.project?.slug,
                                                  value?.project?.settings[1]?.value,
                                                  value?.project?.settings[0]?.value,
                                                  isProjectEnabled
                                              );
                                              from === "dashboard" && setSelectedProjectState(false);
                                              setSelectedProjectState(false);
                                            }
                                          }}
                                      >
                                        <div className={styles.projectImageContainer}>
                                          <img src={value?.project?.settings[1]?.value} alt="" width="25"/>
                                        </div>
                                        <div className={styles.projectSlug}>@{value?.project?.slug}</div>
                                      </div>
                                    </div>
                                  </li>
                              );
                            })}
                          </>
                      )}
                      <li style={{marginTop: '12px'}}>
                        <div className={styles.addNewProjectBtn} onClick={handleOpenModal}>
                          <div className={styles.addIcon}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="12"
                                viewBox="0 0 12 13"
                                fill="none"
                            >
                              <path d="M6.64084 1.64085C6.64084 1.14885 6.242 0.75 5.75 0.75C5.258 0.75 4.85915 1.14884 4.85915 1.64085V11.3592C4.85915 11.8512 5.258 12.25 5.75 12.25C6.242 12.25 6.64084 11.8512 6.64084 11.3592V1.64085Z" fill="#333333"/>
                              <path d="M0.890844 5.60916C0.398845 5.60916 0 6.008 0 6.5C0 6.992 0.398845 7.39085 0.890844 7.39085L10.6092 7.39085C11.1012 7.39085 11.5 6.992 11.5 6.5C11.5 6.008 11.1012 5.60915 10.6092 5.60915L0.890844 5.60916Z" fill="#333333"/>
                            </svg>
                          </div>
                          <div className={styles.addNewProjectText}>Add New Project</div>
                        </div>
                      </li>

                      <div className={styles.userAccountMenu}>
                        <div className={styles.userAccountMenuTitle}>Account</div>
                        <ul>
                          <li><NavLink activeClassName="active" to={{pathname:"/account-settings",state:{plan:plan}}} ><span>Account Settings</span></NavLink></li>
                          <li><a target={'_blank'} href="https://zaapai.featurebase.app/"><span>Request Feature</span></a></li>
                          <li><a target={'_blank'} href="https://www.zaap.ai/support"><span>Help</span></a></li>
                          <li><NavLink to="/logout"><span style={{color:'#FF2020'}}>Log Out</span></NavLink></li>
                        </ul>
                      </div>
                    </ul> : ''
                }
              </div>

              <PrimaryButton onClick={gotoUpgrade} className={!isOpen ? styles.smallButton : ''} showText={isOpen ? true : false } icon={announceIcon} size={'small'} color={'primary'}>Upgrade Plan</PrimaryButton>
            </>
        )}

        {(from === "settings" ||
          from === "account" ||
          from === "settingsMobile" ||
          from === "accountMobile") && (
          <div className={styles.sideBarMenus}>
            {from === "settings" || from === "settingsMobile" ? (
              <>
                <div className={styles.sideBarHeading}>Project Settings</div>
                <a
                  className={activePage === "upgrade" ? `${styles.leftMenuItem} ${styles.leftMenuItemActive}` : styles.leftMenuItem}
                  onClick={() => handleClick("upgrade")}
                >
                  <img src={starIcon} alt="" />
                  <p className={styles.menuItemText}>Upgrade</p>
                </a>
                {!projectDisabledReducer && (
                  <>
                    <a
                      className={
                        activePage === "team"
                          ? `${styles.leftMenuItem} ${styles.leftMenuItemActive}`
                          : styles.leftMenuItem
                      }
                      onClick={() => handleClick("team")}
                    >
                      <img src={userIcon} alt="" />
                      <p className={styles.menuItemText}>Team</p>
                    </a>
                    <a
                      className={
                        activePage === "payouts"
                          ? `${styles.leftMenuItem} ${styles.leftMenuItemActive}`
                          : styles.leftMenuItem
                      }
                      onClick={() => handleClick("payouts")}
                    >
                      <img src={payoutsIcon} alt="" />
                      <p className={styles.menuItemText}>Payouts</p>
                    </a>
                    <a
                      className={
                        activePage === "domains"
                          ? `${styles.leftMenuItem} ${styles.leftMenuItemActive}`
                          : styles.leftMenuItem
                      }
                      onClick={() => handleClick("domains")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                      >
                        <path
                          d="M8 3V1M14 19V21M3 8H1M19 14H21M3.91421 3.91421L2.5 2.5M18.0858 18.0858L19.5 19.5M11 16.6569L8.87868 18.7782C7.31658 20.3403 4.78392 20.3403 3.22183 18.7782C1.65973 17.2161 1.65973 14.6834 3.22183 13.1213L5.34315 11"
                          stroke="#333333"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <p className={styles.menuItemText}>Domain</p>
                    </a>
                    <a
                      className={
                        activePage === "integrations"
                          ? `${styles.leftMenuItem} ${styles.leftMenuItemActive}`
                          : styles.leftMenuItem
                      }
                      onClick={() => handleClick("integrations")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                      >
                        <path
                          d="M6.5 3.5C6.5 2.11929 7.61929 1 9 1C10.3807 1 11.5 2.11929 11.5 3.5V5H12.5C13.8978 5 14.5967 5 15.1481 5.22836C15.8831 5.53284 16.4672 6.11687 16.7716 6.85195C17 7.40326 17 8.10218 17 9.5H18.5C19.8807 9.5 21 10.6193 21 12C21 13.3807 19.8807 14.5 18.5 14.5H17V16.2C17 17.8802 17 18.7202 16.673 19.362C16.3854 19.9265 15.9265 20.3854 15.362 20.673C14.7202 21 13.8802 21 12.2 21H11.5V19.25C11.5 18.0074 10.4926 17 9.25 17C8.00736 17 7 18.0074 7 19.25V21H5.8C4.11984 21 3.27976 21 2.63803 20.673C2.07354 20.3854 1.6146 19.9265 1.32698 19.362C1 18.7202 1 17.8802 1 16.2V14.5H2.5C3.88071 14.5 5 13.3807 5 12C5 10.6193 3.88071 9.5 2.5 9.5H1C1 8.10218 1 7.40326 1.22836 6.85195C1.53284 6.11687 2.11687 5.53284 2.85195 5.22836C3.40326 5 4.10218 5 5.5 5H6.5V3.5Z"
                          stroke="#333333"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <p className={styles.menuItemText}>Integrations</p>
                    </a>
                  </>
                )}
              </>
            ) : (
              ""
            )}
            {from === "account" || from === "accountMobile" ? (
              <>
                <div className={styles.sideBarHeading}>Account Settings</div>
                <a
                  className={
                    activePage === "profile"
                      ? `${styles.leftMenuItem} ${styles.leftMenuItemActive}`
                      : styles.leftMenuItem
                  }
                  target="_blank"
                  onClick={() => handleClick("profile")}
                >
                  <img src={userIcon} alt="" />
                  <p className={styles.menuItemText}>Profile</p>
                </a>
                <a
                  className={
                    activePage === "billing"
                      ? `${styles.leftMenuItem} ${styles.leftMenuItemActive}`
                      : styles.leftMenuItem
                  }
                  onClick={() => handleClick("billing")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="18"
                    viewBox="0 0 22 18"
                    fill="none"
                  >
                    <path
                      d="M5 8V12M17 6V10M16 1C18.4487 1 19.7731 1.37476 20.4321 1.66544C20.5199 1.70415 20.5638 1.72351 20.6904 1.84437C20.7663 1.91682 20.9049 2.12939 20.9405 2.22809C21 2.39274 21 2.48274 21 2.66274V13.4111C21 14.3199 21 14.7743 20.8637 15.0079C20.7251 15.2454 20.5914 15.3559 20.3319 15.4472C20.0769 15.5369 19.562 15.438 18.5322 15.2401C17.8114 15.1017 16.9565 15 16 15C13 15 10 17 6 17C3.55129 17 2.22687 16.6252 1.56788 16.3346C1.48012 16.2958 1.43624 16.2765 1.3096 16.1556C1.23369 16.0832 1.09512 15.8706 1.05947 15.7719C1 15.6073 1 15.5173 1 15.3373L1 4.58885C1 3.68009 1 3.2257 1.13628 2.99214C1.2749 2.75456 1.40859 2.64412 1.66806 2.55281C1.92314 2.46305 2.43803 2.56198 3.46783 2.75985C4.18862 2.89834 5.04348 3 6 3C9 3 12 1 16 1ZM13.5 9C13.5 10.3807 12.3807 11.5 11 11.5C9.61929 11.5 8.5 10.3807 8.5 9C8.5 7.61929 9.61929 6.5 11 6.5C12.3807 6.5 13.5 7.61929 13.5 9Z"
                      stroke="#333333"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <p className={styles.menuItemText}>Billing</p>
                </a>
                <a
                  className={
                    activePage === "password"
                      ? `${styles.leftMenuItem} ${styles.leftMenuItemActive}`
                      : styles.leftMenuItem
                  }
                  onClick={() => handleClick("password")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M15 6.99994C15 6.48812 14.8047 5.9763 14.4142 5.58579C14.0237 5.19526 13.5118 5 13 5M13 13C16.3137 13 19 10.3137 19 7C19 3.68629 16.3137 1 13 1C9.68629 1 7 3.68629 7 7C7 7.27368 7.01832 7.54308 7.05381 7.80704C7.11218 8.24118 7.14136 8.45825 7.12172 8.59559C7.10125 8.73865 7.0752 8.81575 7.00469 8.9419C6.937 9.063 6.81771 9.18229 6.57913 9.42087L1.46863 14.5314C1.29568 14.7043 1.2092 14.7908 1.14736 14.8917C1.09253 14.9812 1.05213 15.0787 1.02763 15.1808C1 15.2959 1 15.4182 1 15.6627V17.4C1 17.9601 1 18.2401 1.10899 18.454C1.20487 18.6422 1.35785 18.7951 1.54601 18.891C1.75992 19 2.03995 19 2.6 19H5V17H7V15H9L10.5791 13.4209C10.8177 13.1823 10.937 13.063 11.0581 12.9953C11.1843 12.9248 11.2613 12.8987 11.4044 12.8783C11.5417 12.8586 11.7588 12.8878 12.193 12.9462C12.4569 12.9817 12.7263 13 13 13Z"
                      stroke="#333333"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <p className={styles.menuItemText}>Password</p>
                </a>

                <a
                  className={
                    activePage === "affiliate-program"
                      ? `${styles.leftMenuItem} ${styles.leftMenuItemActive}`
                      : styles.leftMenuItem
                  }
                  onClick={() => handleClick("affiliate-program")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M9 4.2V17M9 4.2H6.17143C5.75466 4.2 5.35496 4.03143 5.06026 3.73137C4.76556 3.43131 4.6 3.02435 4.6 2.6C4.6 2.17565 4.76556 1.76869 5.06026 1.46863C5.35496 1.16857 5.75466 1 6.17143 1C8.37143 1 9 4.2 9 4.2ZM9 4.2H11.8286C12.2453 4.2 12.645 4.03143 12.9397 3.73137C13.2344 3.43131 13.4 3.02435 13.4 2.6C13.4 2.17565 13.2344 1.76869 12.9397 1.46863C12.645 1.16857 12.2453 1 11.8286 1C9.62857 1 9 4.2 9 4.2ZM15.4 8.2V14.44C15.4 15.3361 15.4 15.7841 15.2256 16.1264C15.0722 16.4274 14.8274 16.6722 14.5264 16.8256C14.1841 17 13.7361 17 12.84 17L5.16 17C4.26392 17 3.81587 17 3.47362 16.8256C3.17256 16.6722 2.92779 16.4274 2.77439 16.1264C2.6 15.7841 2.6 15.3361 2.6 14.44V8.2M1 5.48L1 6.92C1 7.36804 1 7.59206 1.08719 7.76319C1.16389 7.91372 1.28628 8.03611 1.43681 8.1128C1.60794 8.2 1.83196 8.2 2.28 8.2L15.72 8.2C16.168 8.2 16.3921 8.2 16.5632 8.11281C16.7137 8.03611 16.8361 7.91372 16.9128 7.76319C17 7.59206 17 7.36804 17 6.92V5.48C17 5.03196 17 4.80794 16.9128 4.63681C16.8361 4.48628 16.7137 4.36389 16.5632 4.2872C16.3921 4.2 16.168 4.2 15.72 4.2L2.28 4.2C1.83196 4.2 1.60794 4.2 1.43681 4.28719C1.28628 4.36389 1.16389 4.48628 1.08719 4.63681C1 4.80794 1 5.03196 1 5.48Z"
                      stroke="#333333"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <p className={styles.menuItemText}>Affiliate Program</p>
                </a>

                <a
                  className={
                    activePage === "delete"
                      ? `${styles.leftMenuItem} ${styles.leftMenuItemActive}`
                      : styles.leftMenuItem
                  }
                  onClick={() => handleClick("delete")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M10 6V10M10 14H10.01M5.8 19H14.2C15.8802 19 16.7202 19 17.362 18.673C17.9265 18.3854 18.3854 17.9265 18.673 17.362C19 16.7202 19 15.8802 19 14.2V5.8C19 4.11984 19 3.27976 18.673 2.63803C18.3854 2.07354 17.9265 1.6146 17.362 1.32698C16.7202 1 15.8802 1 14.2 1H5.8C4.11984 1 3.27976 1 2.63803 1.32698C2.07354 1.6146 1.6146 2.07354 1.32698 2.63803C1 3.27976 1 4.11984 1 5.8V14.2C1 15.8802 1 16.7202 1.32698 17.362C1.6146 17.9265 2.07354 18.3854 2.63803 18.673C3.27976 19 4.11984 19 5.8 19Z"
                      stroke="#333333"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <p className={styles.menuItemText}>Delete Account</p>
                </a>
              </>
            ) : (
              ""
            )}
          </div>
        )}

        {!(from === "settings" ||
          from === "account" ||
          from === "settingsMobile" ||
          from === "accountMobile") && (
          <div
            ref={containerRef}
            className={`${styles.sideBarMenus} webkitScrollbar`}
            onClick={() => setOpenDropdown(null)}
          >
            {isLoadingState ? (
              <>
                <div className={styles.sideBarHeading}>Manage</div>
                <Skeleton className={styles.skeletonItem} />
                <Skeleton className={styles.skeletonItem} />
                <Skeleton className={styles.skeletonItem} />
                <hr className={styles.hiddenHr} />
                <div className={styles.sideBarHeading} style={{ marginTop: "28px", marginBottom: "10px" }}>
                  E-commerce
                </div>
                <Skeleton className={styles.skeletonItem} />
                <Skeleton className={styles.skeletonItem} />
                <hr className={styles.hiddenHr} />
                <div
                  className={styles.sideBarHeading}
                  style={{ marginTop: "28px", marginBottom: "10px" }}
                >
                  Marketing
                </div>
                <Skeleton className={styles.skeletonItem} />
                <Skeleton className={styles.skeletonItem} />
                <Skeleton className={styles.skeletonItem} />
              </>
            ) : (
              <>
                <div className={styles.sideBarHeading}>Manage</div>
                <NavLink
                  to={`/dashboard`}
                  activeClassName={styles.activeNavLink}
                  className={styles.leftMenuItem}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M10 14.0006V10.6673C10 9.56274 9.10457 8.66731 8 8.66731C6.89543 8.66731 6 9.56274 6 10.6673V14.0006M5.07041 3.76748L3.33707 5.40452C2.84421 5.87 2.59778 6.10274 2.42127 6.37826C2.26481 6.62249 2.14923 6.89061 2.07911 7.17205C2 7.48956 2 7.82852 2 8.50644V9.73398C2 11.2275 2 11.9742 2.29065 12.5446C2.54631 13.0464 2.95426 13.4543 3.45603 13.71C4.02646 14.0006 4.77319 14.0006 6.26667 14.0006H9.73333C11.2268 14.0006 11.9735 14.0006 12.544 13.71C13.0457 13.4543 13.4537 13.0464 13.7094 12.5446C14 11.9742 14 11.2275 14 9.73398V8.50644C14 7.82852 14 7.48956 13.9209 7.17205C13.8508 6.89061 13.7352 6.62249 13.5787 6.37826C13.4022 6.10274 13.1558 5.87 12.6629 5.40452L10.9296 3.76748C9.89859 2.79376 9.38309 2.3069 8.79502 2.12322C8.27734 1.96153 7.72266 1.96153 7.20498 2.12322C6.61691 2.3069 6.10141 2.79376 5.07041 3.76748Z"
                      stroke="#333333"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <p className={styles.menuItemText}>Home</p>
                </NavLink>
                {!projectDisabledReducer && (
                  <>
                    <NavLink
                      to={`${basePathState}/page-editor`}
                      activeClassName={styles.activeNavLink}
                      className={styles.leftMenuItem}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M8 14C10.6787 11.8171 10.7261 16.2383 14 12.6667M2.04411 12.2101C2.05031 11.9587 2.05341 11.8331 2.08379 11.7151C2.11072 11.6104 2.15338 11.5105 2.21029 11.4188C2.27447 11.3153 2.36296 11.2264 2.53993 11.0487L11.2417 2.30865C11.5908 1.95805 12.136 1.89931 12.5511 2.16754C13.0433 2.48553 13.4638 2.9034 13.7857 3.39431L13.8082 3.42871C13.8245 3.45358 13.8327 3.46601 13.8396 3.47721C14.0951 3.88885 14.0411 4.42196 13.7081 4.77337C13.6991 4.78293 13.6886 4.79345 13.6676 4.81451L5.01874 13.5014C4.83516 13.6858 4.74337 13.7779 4.63623 13.8439C4.54125 13.9023 4.43769 13.9452 4.32937 13.9712C4.20719 14.0005 4.07739 14.0003 3.81777 13.9999L2 13.997L2.04411 12.2101Z"
                          stroke="#333333"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <p className={styles.menuItemText}>Page</p>
                    </NavLink>

                    <div
                      ref={scrollToRef}
                      onClick={() => toggleSubMenu("audience")}
                      className={`${styles.leftMenuItem} 
                      ${styles.sidebarSubMenuMain} 
                      ${isSubMenuOpen == 'audience' ? styles.activeSubMenu : ""
                        }`}
                    >
                      <div className={styles.firstHalf}>
                        <img src={menuCommunityIcon} width="16px" height="16px" alt="" />
                        <p className={styles.menuItemText}>Profiles</p>
                      </div>
                      {isOpen ? <div className={styles.dropdownArrow}><DropdownArrow /></div> : ''}
                    </div>
                    <div className={`${styles.sidebarSubMenu} ${isSubMenuOpen == 'audience' ? styles.showSubMenu : styles.closedSubMenu
                      }`}>
                      <NavLink
                        to={`${basePathState}/profiles/email`}
                        activeClassName={styles.activeNavLink}
                        className={styles.leftSubMenuItem}
                      ><svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none">
                          <path
                            d="M12.2341 10.7711C13.2598 11.2993 14.1392 12.147 14.781 13.207C14.9081 13.4169 14.9717 13.5218 14.9937 13.6672C15.0383 13.9625 14.8413 14.3256 14.573 14.4425C14.4409 14.5 14.2924 14.5 13.9953 14.5M10.8251 7.66217C11.869 7.13036 12.5863 6.02607 12.5863 4.75C12.5863 3.47393 11.869 2.36964 10.8251 1.83783M9.41607 4.75C9.41607 6.54493 7.9967 8 6.24581 8C4.49493 8 3.07555 6.54493 3.07555 4.75C3.07555 2.95507 4.49493 1.5 6.24581 1.5C7.9967 1.5 9.41607 2.95507 9.41607 4.75ZM1.35602 13.011C2.47922 11.2822 4.25163 10.1667 6.24581 10.1667C8.24 10.1667 10.0124 11.2822 11.1356 13.011C11.3817 13.3898 11.5047 13.5791 11.4905 13.821C11.4795 14.0094 11.3591 14.24 11.2122 14.3538C11.0237 14.5 10.7644 14.5 10.2457 14.5H2.2459C1.72726 14.5 1.46793 14.5 1.27938 14.3538C1.13257 14.24 1.01213 14.0094 1.0011 13.821C0.986935 13.5791 1.10996 13.3898 1.35602 13.011Z"
                            stroke="#333333"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"></path>
                        </svg>
                        <span> Email Profiles </span>
                      </NavLink>
                      <NavLink
                        to={`${basePathState}/profiles/phone`}
                        activeClassName={styles.activeNavLink}
                        className={styles.leftSubMenuItem}
                      ><svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none">
                          <path
                            d="M12.2341 10.7711C13.2598 11.2993 14.1392 12.147 14.781 13.207C14.9081 13.4169 14.9717 13.5218 14.9937 13.6672C15.0383 13.9625 14.8413 14.3256 14.573 14.4425C14.4409 14.5 14.2924 14.5 13.9953 14.5M10.8251 7.66217C11.869 7.13036 12.5863 6.02607 12.5863 4.75C12.5863 3.47393 11.869 2.36964 10.8251 1.83783M9.41607 4.75C9.41607 6.54493 7.9967 8 6.24581 8C4.49493 8 3.07555 6.54493 3.07555 4.75C3.07555 2.95507 4.49493 1.5 6.24581 1.5C7.9967 1.5 9.41607 2.95507 9.41607 4.75ZM1.35602 13.011C2.47922 11.2822 4.25163 10.1667 6.24581 10.1667C8.24 10.1667 10.0124 11.2822 11.1356 13.011C11.3817 13.3898 11.5047 13.5791 11.4905 13.821C11.4795 14.0094 11.3591 14.24 11.2122 14.3538C11.0237 14.5 10.7644 14.5 10.2457 14.5H2.2459C1.72726 14.5 1.46793 14.5 1.27938 14.3538C1.13257 14.24 1.01213 14.0094 1.0011 13.821C0.986935 13.5791 1.10996 13.3898 1.35602 13.011Z"
                            stroke="#333333"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"></path>
                        </svg>
                        <span> SMS Profiles </span>
                      </NavLink>
                      <NavLink
                        to={`${basePathState}/profiles/segments`}
                        activeClassName={styles.activeNavLink}
                        className={styles.leftSubMenuItem}
                      >
                        <img src={menuNewsletterIcon} width="15px" height="14px" alt="" />
                        <span> Segments </span>
                      </NavLink>
                    </div>
                    <hr className={styles.hiddenHr} />
                    <div
                      className={styles.sideBarHeading}
                      style={{ marginTop: "28px", marginBottom: "10px" }}
                    >
                      E-commerce
                    </div>
                    <NavLink
                      to={`${basePathState}/products`}
                      activeClassName={styles.activeNavLink}
                      className={styles.leftMenuItem}
                      exact
                      isActive={(match, location) =>
                        match || location.pathname === `${basePathState}/createProducts`
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M1.33325 2C2.63933 1.99998 3.77912 2.81123 4.23309 3.98804M4.23309 3.98804C4.31236 4.19354 4.37072 4.4102 4.4054 4.63562L5.01967 8.62834C5.19627 9.77626 6.23099 10.5918 7.3884 10.4954L12.0615 10.1059C12.8003 10.0444 13.3906 9.46757 13.4824 8.73185C13.6263 7.57776 13.7423 6.83846 13.8921 5.59858C13.9185 5.37934 13.9318 5.26969 13.9165 5.17614C13.8778 4.94018 13.7124 4.74016 13.4879 4.65791C13.3989 4.62529 13.2875 4.61763 13.0647 4.60231L4.23309 3.98804ZM7.99992 13.3333C7.99992 13.7015 7.70144 14 7.33325 14C6.96506 14 6.66659 13.7015 6.66659 13.3333C6.66659 12.9651 6.96506 12.6666 7.33325 12.6666C7.70144 12.6666 7.99992 12.9651 7.99992 13.3333ZM13.3333 13.3333C13.3333 13.7015 13.0348 14 12.6666 14C12.2984 14 11.9999 13.7015 11.9999 13.3333C11.9999 12.9651 12.2984 12.6666 12.6666 12.6666C13.0348 12.6666 13.3333 12.9651 13.3333 13.3333Z"
                          stroke="#303A47"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <p className={styles.menuItemText}>Products</p>
                    </NavLink>

                    <NavLink
                      to={`${basePathState}/orders`}
                      activeClassName={styles.activeNavLink}
                      className={styles.leftMenuItem}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M8.00008 2V14M11.3334 5C11.0867 3.96486 10.2167 3.2 9.1812 3.2H8.00008H6.88897C5.66167 3.2 4.66675 4.27452 4.66675 5.6C4.66675 6.92548 5.66167 8 6.88897 8H8.00008L9.11119 8C10.3385 8 11.3334 9.07452 11.3334 10.4C11.3334 11.7255 10.3385 12.8 9.11119 12.8H8.00008H6.81896C5.78349 12.8 4.91344 12.0351 4.66675 11"
                          stroke="#303A47"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <p className={styles.menuItemText}>Orders</p>
                    </NavLink>

                    <NavLink
                      to={`${basePathState}/codes`}
                      activeClassName={styles.activeNavLink}
                      className={styles.leftMenuItem}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                      >
                        <path
                          d="M5.2 5.2H5.206M8.8 8.8H8.806M9.4 4.6L4.6 9.4M10.5407 2.7991C10.6643 3.09792 10.9014 3.33544 11.2 3.45944L12.2471 3.89319C12.546 4.01697 12.7834 4.2544 12.9072 4.55324C13.0309 4.85207 13.0309 5.18785 12.9072 5.48668L12.4738 6.53308C12.3499 6.83205 12.3497 7.16817 12.4741 7.46699L12.9068 8.51308C12.9682 8.66109 12.9998 8.81974 12.9998 8.97997C12.9998 9.1402 12.9683 9.29886 12.907 9.44689C12.8456 9.59492 12.7558 9.72942 12.6424 9.8427C12.5291 9.95598 12.3946 10.0458 12.2466 10.1071L11.2002 10.5405C10.9014 10.6641 10.6639 10.9012 10.5399 11.1999L10.1062 12.247C9.98239 12.5459 9.74497 12.7833 9.44614 12.9071C9.14732 13.0308 8.81156 13.0308 8.51273 12.9071L7.46637 12.4736C7.16753 12.3502 6.8319 12.3504 6.53325 12.4743L5.48614 12.9075C5.18747 13.0309 4.85201 13.0308 4.55342 12.9072C4.25484 12.7835 4.01755 12.5463 3.89369 12.2478L3.45983 11.2004C3.33628 10.9015 3.09913 10.664 2.80051 10.54L1.7534 10.1063C1.4547 9.98254 1.21735 9.74527 1.09353 9.4466C0.969705 9.14793 0.969537 8.81232 1.09306 8.51353L1.52648 7.46713C1.64995 7.16828 1.6497 6.83263 1.52578 6.53397L1.09298 5.48607C1.03162 5.33806 1.00003 5.17941 1 5.01918C0.999974 4.85895 1.03152 4.70029 1.09283 4.55226C1.15415 4.40423 1.24403 4.26973 1.35734 4.15645C1.47065 4.04317 1.60517 3.95333 1.75322 3.89206L2.79958 3.45863C3.09812 3.33518 3.33549 3.09832 3.45958 2.80003L3.89331 1.75289C4.01709 1.45405 4.25451 1.21662 4.55333 1.09284C4.85216 0.969054 5.18792 0.969054 5.48675 1.09284L6.53311 1.52627C6.83194 1.64975 7.16758 1.6495 7.46623 1.52557L8.51378 1.09351C8.81257 0.969796 9.14826 0.969821 9.44703 1.09358C9.7458 1.21734 9.98318 1.4547 10.107 1.75346L10.5408 2.80092L10.5407 2.7991ZM5.5 5.2C5.5 5.36569 5.36569 5.5 5.2 5.5C5.03431 5.5 4.9 5.36569 4.9 5.2C4.9 5.03431 5.03431 4.9 5.2 4.9C5.36569 4.9 5.5 5.03431 5.5 5.2ZM9.1 8.8C9.1 8.96569 8.96569 9.1 8.8 9.1C8.63431 9.1 8.5 8.96569 8.5 8.8C8.5 8.63431 8.63431 8.5 8.8 8.5C8.96569 8.5 9.1 8.63431 9.1 8.8Z"
                          stroke="#303A47"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <p className={styles.menuItemText}>Discount Codes</p>
                    </NavLink>
                    <hr className={styles.hiddenHr} />

                    <div
                      className={styles.sideBarHeading}
                      style={{ marginTop: "28px", marginBottom: "10px" }}
                    >
                      Marketing
                    </div>

                    <NavLink
                      to={`${basePathState}/posts`}
                      activeClassName={styles.activeNavLink}
                      className={styles.leftMenuItem}
                    >
                      <img src={menuNewsletterIcon} width="15" height="14" alt=""/>
                      <p className={styles.menuItemText}>Blog Posts</p>
                    </NavLink>

                    <NavLink
                      to={`${basePathState}/email`}
                      activeClassName={styles.activeNavLink}
                      className={styles.leftMenuItem}
                    >
                      <img src={broadcastIcon} width="15" height="14" alt=""/>
                      <p className={styles.menuItemText}>Email Marketing</p>
                    </NavLink>

                    <div
                      ref={scrollToRef}
                      onClick={() => toggleSubMenu("analytics")}
                      className={`${styles.leftMenuItem} ${styles.sidebarSubMenuMain} ${
                        isSubMenuOpen == 'analytics' ? styles.activeSubMenu : ""
                      }`}
                    >
                      <div className={styles.firstHalf}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M2.9 11.6V12.5M2.9 12.5V13.4M2.9 12.5H3.8M2.9 12.5H2M8.6 2L8.8135 3.67981C9.08286 5.79918 10.7205 7.48542 12.8311 7.81659L14 8L12.8311 8.18341C10.7205 8.51458 9.08286 10.2008 8.8135 12.3202L8.6 14L8.3865 12.3202C8.11714 10.2008 6.47946 8.51458 4.36887 8.18341L3.2 8L4.36887 7.81659C6.47946 7.48542 8.11714 5.79918 8.3865 3.67981L8.6 2ZM3.8 2L3.84476 2.27172C3.97017 3.03309 4.56691 3.62983 5.32828 3.75524L5.6 3.8L5.32828 3.84476C4.56691 3.97017 3.97017 4.56691 3.84476 5.32828L3.8 5.6L3.75524 5.32828C3.62983 4.56691 3.03309 3.97017 2.27172 3.84476L2 3.8L2.27172 3.75524C3.03309 3.62983 3.62983 3.03309 3.75524 2.27172L3.8 2Z"
                            stroke="#303A47"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <p className={styles.menuItemText}>Analytics</p>
                      </div>
                      {isOpen ? <div className={styles.dropdownArrow}><DropdownArrow /></div> : ''}
                    </div>
                    <div className={`${styles.sidebarSubMenu} ${
                      isSubMenuOpen == 'analytics' ? styles.showSubMenu : styles.closedSubMenu
                    }`}>
                      <NavLink
                        to={`${basePathState}/analytics/page`}
                        activeClassName={styles.activeNavLink}
                        className={styles.leftSubMenuItem}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M8 14C10.6787 11.8171 10.7261 16.2383 14 12.6667M2.04411 12.2101C2.05031 11.9587 2.05341 11.8331 2.08379 11.7151C2.11072 11.6104 2.15338 11.5105 2.21029 11.4188C2.27447 11.3153 2.36296 11.2264 2.53993 11.0487L11.2417 2.30865C11.5908 1.95805 12.136 1.89931 12.5511 2.16754C13.0433 2.48553 13.4638 2.9034 13.7857 3.39431L13.8082 3.42871C13.8245 3.45358 13.8327 3.46601 13.8396 3.47721C14.0951 3.88885 14.0411 4.42196 13.7081 4.77337C13.6991 4.78293 13.6886 4.79345 13.6676 4.81451L5.01874 13.5014C4.83516 13.6858 4.74337 13.7779 4.63623 13.8439C4.54125 13.9023 4.43769 13.9452 4.32937 13.9712C4.20719 14.0005 4.07739 14.0003 3.81777 13.9999L2 13.997L2.04411 12.2101Z"
                            stroke="#333333"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <span className={styles.menuItemText}> Page </span>
                      </NavLink>
                      <NavLink
                        to={`${basePathState}/analytics/posts`}
                        activeClassName={styles.activeNavLink}
                        className={styles.leftSubMenuItem}
                      >
                        <img src={menuNewsletterIcon} width="15" height="14" alt=""/>
                        <span className={styles.menuItemText}> Posts </span>
                      </NavLink>

                      <NavLink
                        to={`${basePathState}/analytics/links`}
                        activeClassName={styles.activeNavLink}
                        className={styles.leftSubMenuItem}
                      >
                        <img src={linkIcon} alt="" width={16} />
                        <span className={styles.menuItemText}> Links </span>
                      </NavLink>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        )}

        {!(from === "settings" ||
          from === "account" ||
          from === "settingsMobile" ||
          from === "accountMobile") && (
            <div className={styles.announcementBox} onClick={() => window.open('https://zaapai.featurebase.app/', '_blank')}>
              <div className={styles.announcementHead}>
                <div><span>Feature Request</span> <img src={announceIcon} alt={'icon'} /></div>
              </div>
              <div className={styles.announcementText}>
                Got an idea that would make Zaap even better?
              </div>
              <PrimaryButton size="small" color={'secondary'}>Tell Us</PrimaryButton>
            </div>
        )}

        {!(from === "settings" ||
          from === "account" ||
          from === "settingsMobile" ||
          from === "accountMobile") && (
          <NewBottomBar
            profile={profile}
            title={title}
            isLoading={isLoadingState}
            basePathState={basePathState}
            setSelectedProfile={setSelectedProfileState}
            changeProject={changeProject}
            setSelectedProject={setSelectedProjectState}
            projects={projectsState}
            sharedProjects={sharedProjectsState}
            selectedSlug={selectedSlugState}
            handleOpenModal={handleOpenModal}
            from={from}
          />
        )}

        {confirmationModalShow?.show && (
          <ConfirmationModal
            confirmationModalShow={confirmationModalShow}
            setShow={setconfirmationModalShow}
            callback={deleteProject}
          />
        )}
        <DashboardProjectsModal
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          getProjects={refreshProjects}
        />
      </div>
    </>
  );
};

export default Sidebar;