import React, {useCallback, useEffect, useState} from 'react';
import {Button, Form, ListGroup, Modal, Spinner} from 'react-bootstrap';
import {useAlert} from '../../contexts/AlertContext';
import {hideLoader, showLoader} from '../loader'; // Assuming loader utilities
// API clients
import segmentApi from '../../api/segment'; // For getting available segments
import audienceApi from '../../api/phone'; // Or appropriate API for profiles actions
import phoneApi from '../../api/phone'; // Or appropriate API for phone actions

const EditSegmentsModal = ({
                               show,
                               onHide,
                               memberId,
                               memberName,
                               memberType = 'audience', // 'profiles' or 'phone' to call correct API
                               slug,
                               // Pass available segments if already fetched, otherwise fetch inside
                               initialAvailableSegments = [],
                           }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [availableSegments, setAvailableSegments] = useState(initialAvailableSegments);
    const [checkedSegments, setCheckedSegments] = useState([]); // Array of currently assigned {segment, color} objects
    const {setAlert} = useAlert();

    const fetchMemberData = useCallback(async () => {
        if (!memberId || !slug) return;
        setIsLoading(true);
        try {
            // Fetch available segments if not provided initially
            if (initialAvailableSegments.length === 0) {
                const segmentsResponse = await segmentApi.getSegments(slug, {count: 500}); // Fetch all segments
                setAvailableSegments(segmentsResponse?.data?.data || []);
            }

            // Fetch the specific profiles current segments
            let memberDataResponse;
            if (memberType === 'audience') {
                // TODO - need endpoint
                // setCheckedSegments(memberDataResponse?.segments || []);
            } else if (memberType === 'phone') {
                // TODO - need endpoint
                // setCheckedSegments(memberDataResponse?.segments || []); // Example structure
            }
            // Temp workaround

        } catch (error) {
            console.error(`Error fetching data for ${memberType} ${memberId}:`, error);
            setAlert({type: 'error', title: 'Error', message: 'Could not load segment data.'});
        } finally {
            setIsLoading(false);
        }
    }, [memberId, slug, memberType, initialAvailableSegments]); // Add initialCheckedSegments if needed

    // Can remove if parent passes initialCheckedSegments ??
    useEffect(() => {
        if (show) {
            setCheckedSegments([]);
            fetchMemberData();
        }
    }, [show, fetchMemberData]); // Rerun if modal shown or fetch function changes

    // Effect IF the parent passes initialCheckedSegments **
    // useEffect(() => {
    //     if (show && initialCheckedSegments) {
    //         setCheckedSegments(initialCheckedSegments);
    //          // Fetch available segments if needed
    //          if (initialAvailableSegments.length === 0) {
    //              // Fetch logic for available segments here...
    //              async function fetchAvail() {
    //                  setIsLoading(true);
    //                  try {
    //                      const segmentsResponse = await segmentApi.getSegments(slug, { count: 500 });
    //                      setAvailableSegments(segmentsResponse?.data?.data || []);
    //                  } catch (e) { /* handle error */ }
    //                  finally { setIsLoading(false); }
    //              }
    //              fetchAvail();
    //          }
    //     }
    // }, [show, initialCheckedSegments, initialAvailableSegments.length, slug]);

// TODO API Call Logic
    const handleSegmentCheckChange = async (segment, isChecked) => {
        const segmentPayload = {segment: segment.segment, color: segment.color}; // Ensure consistent structure
        showLoader();
        try {
            let apiToCall;
            if (memberType === 'audience') {
                apiToCall = audienceApi.updateAudienceSegments;
            } else {
                apiToCall = phoneApi.updateContactSegments;
            }

            await apiToCall(memberId, segmentPayload, isChecked);

            setCheckedSegments(prev =>
                isChecked
                    ? [...prev.filter(s => s.segment !== segment.segment), segmentPayload] // Add/replace
                    : prev.filter(s => s.segment !== segment.segment) // Remove
            );

        } catch (error) {
            console.error("Failed to update segment:", error);
            setAlert({type: 'error', title: 'Error', message: 'Failed to update segment.'});
        } finally {
            hideLoader();
        }
    };

    const isSegmentChecked = (segment) => {
        return checkedSegments.some(cs => cs.segment === segment.segment);
    };

    return (
        <Modal show={show} onHide={onHide} centered size="md">
            <Modal.Header closeButton>
                <Modal.Title>Edit Segments for {memberName || 'Member'}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{maxHeight: '60vh', overflowY: 'auto'}}>
                {isLoading ? (
                    <div className="text-center">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                ) : (
                    availableSegments.length > 0 ? (
                        <ListGroup variant="flush">
                            {availableSegments.map((segment) => (
                                <ListGroup.Item key={segment.id || segment.segment}
                                                className="d-flex align-items-center border-0 ps-0">
                                    <Form.Check
                                        type="checkbox"
                                        id={`segment-check-${memberId}-${segment.id || segment.segment}`}
                                        checked={isSegmentChecked(segment)}
                                        onChange={(e) => handleSegmentCheckChange(segment, e.target.checked)}
                                        className="me-2 flex-shrink-0"
                                    />
                                    <span
                                        style={{
                                            display: 'inline-block',
                                            width: '12px',
                                            height: '12px',
                                            backgroundColor: segment.color || '#e0e0e0',
                                            borderRadius: '3px',
                                            marginRight: '8px',
                                            flexShrink: 0,
                                        }}
                                    ></span>
                                    <Form.Check.Label
                                        htmlFor={`segment-check-${memberId}-${segment.id || segment.segment}`}
                                        className="flex-grow-1" style={{cursor: 'pointer'}}>
                                        {segment.segment}
                                    </Form.Check.Label>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    ) : (
                        <p className="text-muted text-center">No segments available to assign.</p>
                    )
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditSegmentsModal;