import { Route, useHistory, useLocation, useParams } from "react-router-dom"
import "../../styles/app.css";
import "../../styles/style.css";
import "../../styles/media_queries.css";
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { useEffect, useState } from "react";
import { hideLoader, showLoader } from "../loader";
import NewsLetter from "../../pages/newsletter";
import Email from "../../pages/profiles/email";
import Phone from "../../pages/profiles/phone";
import Segments from "../../pages/profiles/segments";
import Products from "../../pages/products";
import Orders from "../../pages/orders";
import ModalsHandler from "../ModalsHandler";
import BlockEditor from "../page-editor/block-editor";
import SubModals from "../page-editor/block-editor/sub-modals";
import Analytics from "../../pages/analytics";
import { useSelector, useDispatch } from "react-redux"
import TwitterCallback from "../../auth/twitter-callback";
import TikTokCallBack from "../../auth/tiktok-callback";
import IntegrationModals from "../modals/integration-modals";
import getProjects from "../../api/getProjects";
import getTheme from "../../api/getTheme.";
import UpgradeModal from "../upgrade/upgrade-modal";
import { AcceptInvite } from "../../pages/accept-invite";
import StripeConnectReturn from "../../auth/stripe-connect-return";
import PaypalConnect from "../../auth/paypal-connect.jsx";
import CallbackLogin from "../../auth/callback-login";
import ManageBilling from "../../pages/manage-billing";
import { toast } from "react-toastify";
import Settings from "../settings/settings";
import Post from "../../pages/posts.jsx";
import DiscountCode from "../../pages/discount-code.jsx";
import InstagramCallBack from "../../auth/instagram-callback.jsx";
import ProductMain from "../products/product-main.jsx";
import getLanguages from "../../api/getLanguages.js";
import getFont  from "../../api/getFont.js";
import ZoomCallback from "../../auth/zoom-callback.jsx";
import SpotifyCallBack from "../../auth/spotify-callback.jsx";

const DashboardLayout = () => {
    const location = useLocation();
    const dispatch = useDispatch()
    const history = useHistory()
    const pro = useSelector(state => state?.proReducer);
    const newUser = useSelector(state => state?.newUserReducer);
    const [showCustomizeParent, setShowCustomizeParent] = useState(false);
    const [showParent, setShowParent] = useState(false);
    const [showChild, setShowChild] = useState(false);
    const [settings, setSettings] = useState([])
    const { basePath, slug, newChange } = useSelector(state => state?.slugReducer);
    const { stripeConnectReducer } = useSelector(state => state);
    const [iframeURL, setIframeURL] = useState('');
    const [confirmationLinkModal, setConfirmationLinkModal] = useState({ show: false, embed_type: null, url: null })
    const [paypalConnectShow, setpaypalConnectShow] = useState(null)
    const [callbackLogin, setcallbackLogin] = useState(null)
    const [stripeCheckout, setstripeCheckout] = useState(null)
    const [acceptInvite, setacceptInvite] = useState(null)
    const [stripeConnectReturn, setStripeConnectReturn] = useState(null);
    const [twitterCallback, settwitterCallback] = useState(null);
    const [tiktokCallback, settiktokCallback] = useState(null);
    const [spotifyCallback, setspotifyCallback] = useState(null);
    const [instagramCallback, setinstagramCallback] = useState(null);
    const [zoomCallback, setZoomCallback] = useState(null);
    const [manageBillingRoute, setmanageBillingRoute] = useState(null);
    const { customizeProfileReducer, customizeThemeReducer, allThemesReducer, allLanguagesReducer, customizeSocialsReducer, customizeDomainsReducer, customizeSeoReducer, integrationGoogleReducer, integrationFacebookReducer, integrationConvertkitReducer, integrationMailChimpReducer, integrationSendinblueReducer, integrationZapierReducer, integrationCustomCodeReducer } = useSelector(state => state)
    // const checkPath = routes.filter((val) => matchPath(location.pathname, {
    //     path: val.path,
    //     exact: true,
    //     strict: false
    //   }));
    // if (checkPath.length == 0 || (!slug && !localStorage?.slug) ) {
    //     history.push("/dashboard");
    // }
    const handleClose = () => {
        history.push(basePath + "/page-editor")
        setShowParent(false)
    }

    const handleHide = () => {
        showLoader()
        history.push(basePath + "/page-editor")
        setShowParent(false)
        setShowChild(false)
        hideLoader()

    }
    const handleCloseChild = () => {
        showLoader()
        setShowChild(false)
        setTimeout(() => {
            try {
                history.goBack()
            }
            catch {
                history.push(basePath + "/page-editor")
            }
            hideLoader()
        }, 300);

    };
    useEffect(() => {
        setpaypalConnectShow(<Route path="/paypal-connect" component={() => <PaypalConnect slug={slug} />} />)
        localStorage.removeItem("sendRequest")
        setcallbackLogin(<Route path={"/callback-login"} component={() => <CallbackLogin />} />)
        // setstripeCheckout(<Route path="/stripe-checkout" component={() => <StripeCheckout />} />)
        setacceptInvite(<Route path="/accept-invite" component={() => <AcceptInvite />} />)
        setStripeConnectReturn(<Route path="/stripe-connect-return" component={() => <StripeConnectReturn />} />)
        settwitterCallback(<Route path={"/twitter-callback"} component={() => <TwitterCallback />} />)
        settiktokCallback(<Route path={"/tiktok-callback"} component={() => <TikTokCallBack />} />)
        setspotifyCallback(<Route path={"/spotify-callback"} component={() => <SpotifyCallBack />} />)
        setinstagramCallback(<Route path={"/instagram-callback"} component={() => <InstagramCallBack />} />)
        setZoomCallback(<Route path={"/zoom-callback"} component={() => <ZoomCallback />} />)

        if (location.pathname == "/login" || location.pathname == "/register" || location.pathname == "/") {
            history.push("/dashboard")
            return
        }
        if (allThemesReducer?.length == 0 && !location.pathname.includes("stripe-checkout"))
        {
            getTheme()?.then((response) => {
                if (response)
                    dispatch({
                        type: "SET_All_THEMES", payload: response
                    })
            })
        }
        if (allLanguagesReducer?.length == 0 && !location.pathname.includes("stripe-checkout"))
            {
                getLanguages()?.then((response) => {
                    if (response)
                        dispatch({
                            type: "SET_All_LANGUAGES", payload: response
                        })
                })
            }
        //    ( location.pathname == "/register" && localStorage.getItem("from") == "register") ? history.push("/walkthrough") : null
    }, []);

    const setProfileCustomize = (temp_obj,response) => {
        dispatch({
            type: "SET_CUSTOMIZE_PROFILE", payload: {
                ...customizeProfileReducer,
                title: temp_obj?.title ? temp_obj?.title : null,
                description: temp_obj?.description ? temp_obj?.description : null,
                link: response?.slug,
                verified_check: !temp_obj?.verified_check ? 0 : temp_obj?.verified_check,
                page_language: !temp_obj?.page_language ? "en" : temp_obj?.page_language,
                image_url: temp_obj?.profile_picture,
                imageData: temp_obj?.profile_picture,
                isEdit: temp_obj?.title ? true : false,
                nsfw: parseInt(temp_obj?.nsfw ?? 0),
                favicon_upload: temp_obj?.favicon_upload ? temp_obj?.favicon_upload : null
            }
        })
    }

    const setThemeCustomize = (temp_obj) => {
        // console.log("temp_obj",temp_obj)
        if (temp_obj?.theme_color == "custom_theme") {
            dispatch({
                type: "SET_CUSTOMIZE_THEME", payload: {
                    ...customizeThemeReducer,
                    selectedTheme: temp_obj?.theme_color,
                    custom_theme: temp_obj?.custom_theme,
                    profile_style: temp_obj?.profile_style,
                    editLink: temp_obj?.banner,
                    selected: temp_obj?.banner_id,
                    verified_check: !temp_obj?.verified_check ? 0 : temp_obj?.verified_check,
                    zaap_branding: !temp_obj?.zaap_branding ? null : temp_obj?.zaap_branding,
                    link_shadow: !temp_obj?.link_shadow ? null : temp_obj?.link_shadow,
                    link_style: !temp_obj?.link_style ? null : temp_obj?.link_style,
                    font_family: !temp_obj?.font_family ? 'Default' : temp_obj?.font_family,
                    isEdit: false
                }
            })

        } else
            dispatch({
                type: "SET_CUSTOMIZE_THEME", payload: {
                    ...customizeThemeReducer,
                    theme: temp_obj?.theme_color == 'group14' ? 1 : temp_obj?.theme_color,
                    selectedTheme: temp_obj?.theme_color,
                    profile_style: temp_obj?.profile_style,
                    editLink: temp_obj?.banner,
                    selected: temp_obj?.banner_id,
                    verified_check: !temp_obj?.verified_check ? 0 : temp_obj?.verified_check,
                    zaap_branding: !temp_obj?.zaap_branding ? null : temp_obj?.zaap_branding,
                    link_shadow: !temp_obj?.link_shadow ? null : temp_obj?.link_shadow,
                    link_style: !temp_obj?.link_style ? null : temp_obj?.link_style,
                    font_family: !temp_obj?.font_family ? 'Default' : temp_obj?.font_family,
                    isEdit: temp_obj?.theme_color == 1
                }
            })
    }

    const setSocailsCustomize = (socials) => {
        dispatch({
            type: "SET_CUSTOMIZE_SOCIALS", payload: {
                ...customizeSocialsReducer, socials:socials,
                isEdit: socials?.length > 0
            }
        })
    }

    const setDomainsCustomize = (temp_obj) => {
        dispatch({
            type: "SET_CUSTOMIZE_DOMAINS", payload: {
                ...customizeDomainsReducer, domain: temp_obj?.custom_domain,
                project_id: temp_obj?.project_id,
                domain_status: temp_obj?.domain_status,
                connected:!!temp_obj?.custom_domain,
                isEdit: !!temp_obj?.custom_domain,
            }
        })
    }

    const setSeoCustomize = (seo) => {
        dispatch({
            type: "SET_CUSTOMIZE_SEO", payload: {
                ...customizeSeoReducer, ...JSON?.parse(seo),
                isEdit: JSON?.parse(seo)?.title !== "",
            }
        })
    }
    const setIntegrationGoogle = (google_analytics) => {
        dispatch({
            type: "SET_GOOGLE_INTEGRATION", payload: {
                ...integrationGoogleReducer, ...google_analytics
            }
        })
    }
    const setIntegrationFacebook = (facebook_pixel) => {
        dispatch({
            type: "SET_FACEBOOK_INTEGRATION", payload: {
                ...integrationFacebookReducer, ...facebook_pixel
            }
        })
    }
    const setIntegrationMailchimp = (mailchimp_settings) => {
        dispatch({
            type: "SET_MAILCHIMP_INTEGRATION", payload: {
                ...integrationMailChimpReducer, ...mailchimp_settings
            }
        })
    }
    const setIntegrationConvertKit = (convertkit_settings) => {
        dispatch({
            type: "SET_CONVERTKIT_INTEGRATION", payload: {
                ...integrationConvertkitReducer, ...convertkit_settings
            }
        })
    }
    const setIntegrationSendinBlue = (sendinblue_settings) => {
        dispatch({
            type: "SET_SENDINBLUE_INTEGRATION", payload: {
                ...integrationSendinblueReducer, ...sendinblue_settings
            }
        })
    }
    const setIntegrationZapier = (zapier_settings) => {
        dispatch({
            type: "SET_ZAPIER_INTEGRATION", payload: {
                ...integrationZapierReducer, ...zapier_settings
            }
        })
    }
    const setIntegrationCustomCode = (values) => {
        dispatch({
            type: "SET_CUSTOM_CODE", payload: {
                ...integrationCustomCodeReducer, ...values
            }
        })
    }
    useEffect(() => {
        if (newChange) {
            // dispatch({ type: "SET_SLUG", payload: { newChange: false } })
            return

        }
        if (slug || localStorage?.slug) {
            dispatch({ type: "RESET_PROJECT_LOADED" });
            getProjects(slug ? slug : localStorage.getItem("slug")).then((response) => {
                if (response?.status_code == 404) {
                    history.push("/_404")
                }
                else if (response?.status_code == 401) {
                    toast.error(response?.status_message)
                    history.push("/dashboard")
                }
                else {
                    dispatch({ type: "SET_NEW_USER", payload: response?.new_user });
                    dispatch({ type: "SET_ELIGIBILITY", payload: response?.eligibility });
                    // console.log("Response",response)
                    let temp_obj = {};
                    response?.settings?.map((val) => temp_obj = { ...temp_obj, [val?.key]: val?.value, project_id: val?.project_id });
                    setSettings(temp_obj)
                    dispatch({ type: "SET_PRO", payload: response?.pro });
                    dispatch({ type: "SET_PlAN_TYPE", payload: response?.plan_type });
                    dispatch({ type: "SET_STRTPE_CONNECT", payload: { ...stripeConnectReducer, paypal: response?.paypal, stripe: response?.stripe, } });
                    dispatch({ type: "SET_BLOCK_SETTING", payload: response?.blocks });
                    dispatch({ type: "SET_CUSTOMIZE_SETTING", payload: temp_obj });
                    dispatch({ type: "SET_MEET_CONNECTED", payload: {meet_token: !!response?.meet_token,zoom_token:!!response?.zoom_token } });
                    dispatch({ type: "SET_CHECKLIST", payload: response?.res?.checklist_data });
                    dispatch({ type: "SET_SEGMENTS", payload: response?.res?.segments ?? [] });
                    dispatch({ type: "SET_BILLINGS", payload: {available_pros: response?.available_pros,total_pros: response?.total_pros}});
                    dispatch({ type: "SET_USER_DATA", payload: {remove_free: response?.res?.remove_free, isEmailEnabled: response?.is_email_enabled,accept_email_disclaimer:response?.res?.accept_email_disclaimer}});
                    dispatch({ type: "SET_SELECTED_EMAIL_PLAN", payload: {total_email_limit: response?.res?.total_email_limit,email_plan_id: response?.res?.email_plan_id,remaining_emails: response?.res?.remaining_emails}});
                    setProfileCustomize(temp_obj,response?.res)
                    setThemeCustomize(temp_obj)
                    setSocailsCustomize(response?.res?.socials)
                    setDomainsCustomize({custom_domain: response?.res?.custom_domain,project_id: response?.res?.id, domain_status: response?.res?.domain_status})
                    setSeoCustomize(temp_obj?.seo)
                    setIntegrationGoogle(temp_obj?.google_analytics?.length > 0 ? {
                        analytics_id: temp_obj?.google_analytics,
                        disconnect: 1
                    } : { analytics_id: temp_obj?.google_analytics, })
                    setIntegrationFacebook(temp_obj?.facebook_pixel?.length > 0 ? {
                        pixel_id: temp_obj?.facebook_pixel,
                        disconnect: 1
                    } : { pixel_id: temp_obj?.facebook_pixel, })
                    if (temp_obj?.mailchimp_settings)
                        setIntegrationMailchimp({
                            ...JSON?.parse(temp_obj?.mailchimp_settings),
                            disconnect: 1
                        })
                    if (temp_obj?.convertkit_settings)
                        setIntegrationConvertKit({
                            ...JSON?.parse(temp_obj?.convertkit_settings),
                            disconnect: 1
                        })
                    if (temp_obj?.sendinblue_settings)
                        setIntegrationSendinBlue({
                            ...JSON?.parse(temp_obj?.sendinblue_settings),
                            disconnect: 1
                        })
                    if (temp_obj?.productSaleUrl || temp_obj?.contactFormUrl || temp_obj?.emailSignupUrl)
                        setIntegrationZapier({
                            productSaleUrl:temp_obj?.productSaleUrl ?? null,
                            contactFormUrl:temp_obj?.contactFormUrl ?? null,
                            emailSignupUrl:temp_obj?.emailSignupUrl ?? null
                        })
                    if (temp_obj?.custom_code){
                        setIntegrationCustomCode({
                            ...JSON?.parse(temp_obj?.custom_code),
                            disconnect: 1
                        })
                    }
                        dispatch({ type: "SET_PROJECT_LOADED", payload: {loaded:true}});
                }
            })                
        }
    }, [slug])
    useEffect(() => {
        if (pro) {
            getFont(slug ? slug : localStorage.getItem("slug")).then((response) => {
                if (response)
                    dispatch({
                        type: "SET_All_FONTS", payload: response
                    })
                    dispatch({
                        type: "SET_CUSTOMIZE_FONT", payload: response?.selected_font
                    })
            })
        }
    }, [slug,pro])
    useEffect(() => {
        if (location.pathname == basePath + "/page-editor/newBlockModal") {
            setShowParent(true)
            setShowChild(false)
        }
        else setShowParent(false)

    }, [location.pathname])


    useEffect(() => {
        if (location.state?.showChild) {
            setShowParent(false)
            setShowChild(true)
        }
        else if (location.state?.show && !location.state?.showChild) {
            setShowParent(true)
        }
        else if (location.pathname == basePath + "/page-editor/customizePageModal") {
            setShowCustomizeParent(true)
        }
        if (location?.state?.showCustomizeParent) {
            setShowCustomizeParent(false)
        }
        else {

            setShowCustomizeParent(true)
        }
    }, [location.state])

    // return (
    //     <>
    //         {/* <Route path={"/dashboard"} exact component={() => <Dashboard />} /> */}
    //         {/* {stripeCheckout}
    //         {acceptInvite} */}
    //         {/* <Route path="/stripe-connect-reauth" component={() => <StripeConnectReauth />} /> */}
    //         {/* {stripeConnectReturn} */}
    //         {/* {paypalConnectShow} */}
    //         {/* {callbackLogin} */}
    //         {/* <Route path={"/callback-login"} component={() => <CallbackLogin />} /> */}
    //         {/* {twitterCallback} */}
    //         {tiktokCallback}
    //         {spotifyCallback}
    //         {instagramCallback}
    //         {zoomCallback}
    //         {/* <Route path={"/account-settings"} children={<AccountSettings />} /> */}
    //         <Route path={`/manage-billings`} children={<ManageBilling />} />
    //         <Route path={basePath + "/newsletter"} children={<NewsLetter basePath={basePath} id={basePath?.split("/")[2]} />} />
    //         <Route path={basePath + "/post"} children={<Post basePath={basePath} id={basePath?.split("/")[2]} />} />
    //         <Route path={basePath + "/codes"} children={<DiscountCode basePath={basePath} id={basePath?.split("/")[2]} />} />
    //         <Route path={basePath + "/products"} children={<Products basePath={basePath} id={basePath?.split("/")[2]} />} />
    //         <Route path={"/project/:id/:pagename"} children={<ProjectComponents />} />
    //         <Route path={basePath + "/page-editor"}>
    //             <PageEditor setShowParent={setShowParent} iframeURL={iframeURL} setIframeURL={setIframeURL} setConfirmationLinkModal={setConfirmationLinkModal} confirmationLinkModal={confirmationLinkModal} />
    //             {showParent ?
    //                 <Route path={basePath + "/page-editor/:blockEditorId"}>
    //                     <ModalsHandler slug={slug} show={showParent} showChild={showChild} handleClose={handleClose} />
    //                 </Route>
    //                 :
    //                 <Route path={basePath + "/page-editor/newBlockModal"}>
    //                     <BlockEditor show={showParent} showChild={showChild} handleClose={handleClose} />
    //                 </Route>
    //             }

    //         </Route>

    //         {/* <Route path={basePath + "/settings/team-members"} children={<Teammembers />} /> */}
    //         <Route path={basePath + "/settings/"} children={<Settings />} />
    //         {/* <Route path={basePath + "/settings/connect-calendar"} children={<ConnectCalendar />} /> */}
    //         {/* <Route path={basePath + "/settings/upgrade-zaap"} children={<NewUpgradePage/>} /> */}


    //         <Route path={basePath + "/page-editor/:blockEditorId/:subPath"} children={
    //             <SubModals
    //                 show={showChild}
    //                 setShow={setShowChild}
    //                 handleHide={handleHide}
    //                 handleClose={handleCloseChild}
    //                 setIframeURL={setIframeURL}
    //                 confirmationLinkModal={confirmationLinkModal}
    //                 setConfirmationLinkModal={setConfirmationLinkModal}
    //             />} />
    //         {/* <Route
    //             path={
    //                 location.pathname == basePath + "/page-editor/customizePageModal" ? location.pathname
    //                     : basePath + "/page-editor/customizePageModal/:subPath"
    //             }
    //             children={
    //                 <CustomizePageModal show={showCustomizeParent}
    //                     setShowCustomizeParent={setShowCustomizeParent}
    //                     handleClose={handleCloseChild}
    //                     setIframeURL={setIframeURL}
    //                     settings={settings}
    //                 />}
    //         /> */}
    //         {/* <Route path={basePath + "/page-editor/customizePageModal/customizePageDomains/customDomainModal"}
    //             children={
    //                 <CustomizeDomainModal show={true} setShowCustomizeParent={setShowCustomizeParent} settings={settings?.custom_domain} />
    //             } /> */}
    //         <IntegrationModals show={true} setShowCustomizeParent={setShowCustomizeParent} settings={{
    //             google_analytics: settings?.google_analytics,
    //             facebook_pixel: settings?.facebook_pixel,
    //             mailchimp_settings: settings?.mailchimp_settings,
    //             convertkit_settings: settings?.convertkit_settings,
    //             sendinblue_settings: settings?.sendinblue_settings,

    //         }} />

    //         <UpgradeModal />
    //         {/* <Route path={"/logout"} component={() => <Logout />} /> */}
    //     </>
    // )
}
export const ProjectComponents = ({ }) => {
    const { id } = useParams();
    const dispatch = useDispatch()

    useEffect(() => {
        
// console.log("Project useEffect")
        localStorage.setItem("slug", id)
        dispatch({ type: "SET_SLUG", payload: { slug: id, basePath: "/project/" + id, newChange: false } })
    }, [id])

    return (
        <>
            <Route path={"/project/" + id + "/profiles/email"} component={() => <Email />} />
            <Route path={"/project/" + id + "/profiles/phone"} component={() => <Phone />} />
            <Route path={"/project/" + id + "/profiles/segments"} component={() => <Segments />} />
            <Route path={"/project/" + id + "/orders"} component={() => <Orders />} />
            <Route path={"/project/" + id + "/analytics"} component={() => <Analytics />} />
            {/* <Route path={"/project/" + id + "/createProducts"} component={() => <ProductMain/>} /> */}
            <Route path={"/project/" + id + "/editProduct"} component={() => <ProductMain/>} />
        </>
    )
}
export const useUpgradeModal = () => {
    const dispatch = useDispatch();

    const showUpgradeModal = (payload) => {
        dispatch({ type: "SET_UPGRADE_MODAL", payload });
    };

    return { showUpgradeModal };
};
export default DashboardLayout