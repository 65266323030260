import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import tickIcon from "../../assets/img/tick.svg";
import chevronDownIcon from "../../assets/img/modals/chevron-down.svg";

const SortArrow = ({ direction }) => {
    if (!direction) return null;
    return direction === 'asc' ? <span className="ms-1">↑</span> : <span className="ms-1">↓</span>;
};

const AudienceTableHeader = ({
                                 filter,
                                 setFilter,
                                 allSelected,
                                 onSelectAll,
                                 audienceCount,
                                 selectedCount,
                                 children,
                                 displayConfig = {}
                             }) => {
    const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
    const [segmentDropdownOpen, setSegmentDropdownOpen] = useState(false);

    // Use config for sortable columns
    const sortableColumns = displayConfig.sortableColumns || ['name', 'created_at', 'revenue'];

    const handleSort = (column) => {
        // Only sort if the column is configured as sortable
        if (!sortableColumns.includes(column)) return;

        const newOrderType = (filter.orderBy === column && filter.orderType === 'asc') ? 'desc' : 'asc';
        setFilter({
            ...filter,
            orderBy: column,
            orderType: newOrderType,
            page: 1,
        });
    };

    const handleStatusFilter = (value) => {
        setFilter({
            ...filter,
            filter: value !== "All" ? value.toLowerCase() : null,
            page: 1,
        });
        setStatusDropdownOpen(false);
    };

    const handleSegmentFilter = (value) => {
        setFilter({
            ...filter,
            filterTag: value !== "All" ? value.toLowerCase() : null,
            page: 1,
        });
        setSegmentDropdownOpen(false);
    };

    const currentStatusFilter = filter.filter ? filter.filter.charAt(0).toUpperCase() + filter.filter.slice(1) : "All";
    const currentSegmentFilter = filter.filterTag ? filter.filterTag.charAt(0).toUpperCase() + filter.filterTag.slice(1) : "All";

    // --- Header Rendering ---
    return (
        <thead className="d-md-table-header-group">
        <tr>
            {/* Checkbox / Name / Bulk Actions */}
            <th style={{ width: displayConfig.checkboxNameWidth || '30%' }} className="aud-leftborder thead-padd">
                {audienceCount > 0 && (
                    <label className="custom-checkbox checkbox me-2">
                        <input
                            type="checkbox"
                            name="select-all"
                            checked={allSelected}
                            onChange={onSelectAll}
                            disabled={audienceCount === 0}
                        />
                        <span className="checkmark"></span>
                    </label>
                )}
                {selectedCount > 0 ? (
                    children
                ) : (
                    displayConfig.nameLabel || 'Name'
                )}
            </th>

            {/* Primary ID Column (Email or Phone Number) - Conditional */}
            {displayConfig.primaryIdField && (
                <th style={{ width: displayConfig.primaryIdWidth || '25%' }} className={`thead-padd aud-text ${selectedCount > 0 ? " invisible": ''}`}>
                    {displayConfig.primaryIdLabel || 'Identifier'} {/* Configured label */}
                </th>
            )}

            {/* Status Filter Dropdown (Conditional) */}
            {displayConfig.showStatus !== false && ( // Show unless explicitly false
                <th style={{ width: displayConfig.statusWidth || '10%' }} className={`thead-padd ${selectedCount > 0 ? " invisible": ''}`}>
                    <Dropdown show={statusDropdownOpen} onToggle={() => setStatusDropdownOpen(!statusDropdownOpen)}>
                        <Dropdown.Toggle variant="link" size="sm" className="text-decoration-none text-dark p-0 d-flex align-items-center">
                            Status: <span className="fw-bold ms-1">{currentStatusFilter}</span>
                            <img src={chevronDownIcon} width="10px" alt="" className="ms-1" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {/* Use configured options and counts */}
                            {(displayConfig.statusDropdownOptions || ['All', 'Subscribers', 'Unsubscribed']).map(status => (
                                <Dropdown.Item
                                    key={status}
                                    active={currentStatusFilter === status}
                                    onClick={() => handleStatusFilter(status)}
                                    className="d-flex justify-content-between align-items-center"
                                >
                                        <span>
                                            {currentStatusFilter === status && <img src={tickIcon} alt="Selected" className="me-2" />}
                                            {status}
                                        </span>
                                    {/* Counts are passed via config */}
                                    <small className="text-muted">{displayConfig.defaultAudienceCounts ? displayConfig.defaultAudienceCounts[status] : 0}</small>
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </th>
            )}

            {/* Date Joined (Sortable - Conditional) */}
            {/* Show if sortable, defaults to true if config omitted */}
            {(sortableColumns?.includes('created_at') ?? true) && (
                <th style={{ width: displayConfig.dateJoinedWidth || '10%' }} className={`thead-padd ${selectedCount > 0 ? " invisible": ''}`} onClick={() => handleSort('created_at')}>
                         <span style={{ cursor: sortableColumns.includes('created_at') ? 'pointer': 'default' }}> {/* Cursor pointer only if sortable */}
                             Date Joined
                             {sortableColumns.includes('created_at') && <SortArrow direction={filter.orderBy === 'created_at' ? filter.orderType : null} />}
                         </span>
                </th>
            )}

            {/* Revenue (Sortable - Conditional) */}
            {/* Show if not explicitly false AND sortable */}
            {displayConfig.showRevenue !== false && (
                <th style={{ width: displayConfig.revenueWidth || '5%' }} className={`thead-padd ${selectedCount > 0 ? " invisible": ''}`} onClick={() => handleSort('revenue')}>
                         <span style={{ cursor: sortableColumns.includes('revenue') ? 'pointer': 'default' }}> {/* Cursor pointer only if sortable */}
                             Revenue
                             {sortableColumns.includes('revenue') && <SortArrow direction={filter.orderBy === 'revenue' ? filter.orderType : null} />}
                         </span>
                </th>
            )}

            {/* Segment Filter Dropdown */}
            <th style={{ width: displayConfig.segmentWidth || '15%' }} className={`thead-padd ${selectedCount > 0 ? " invisible": ''}`}>
                <Dropdown show={segmentDropdownOpen} onToggle={() => setSegmentDropdownOpen(!segmentDropdownOpen)}>
                    <Dropdown.Toggle variant="link" size="sm" className="text-decoration-none text-dark p-0 d-flex align-items-center">
                        {/* Use configured label */}
                        {displayConfig.segmentLabel || 'Segment'}: <span className="fw-bold ms-1">{currentSegmentFilter}</span>
                        <img src={chevronDownIcon} width="10px" alt="" className="ms-1" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {/* Options come from parent via 'displayConfig.availableSegments' */}
                        <Dropdown.Item
                            key="All"
                            active={currentSegmentFilter === "All"}
                            onClick={() => handleSegmentFilter("All")}
                        >
                            {currentSegmentFilter === "All" && <img src={tickIcon} alt="Selected" className="me-2" />}
                            All Segments
                        </Dropdown.Item>
                        {/* Use availableSegments passed in config */}
                        {displayConfig.availableSegments && displayConfig.availableSegments.length > 0 && displayConfig.availableSegments?.map((seg, index) => (
                            <Dropdown.Item
                                key={index}
                                active={currentSegmentFilter.toLowerCase() === (seg?.segment || seg?.tag)?.toLowerCase()}
                                onClick={() => handleSegmentFilter(seg?.segment || seg?.tag)}
                            >
                                {currentSegmentFilter.toLowerCase() === (seg?.segment || seg?.tag)?.toLowerCase() && <img src={tickIcon} alt="Selected" className="me-2" />}
                                {seg?.segment || seg?.tag}
                            </Dropdown.Item>
                        ))}
                        {(!displayConfig.availableSegments || displayConfig.availableSegments.length === 0) && <Dropdown.ItemText>No segments defined</Dropdown.ItemText>}
                    </Dropdown.Menu>
                </Dropdown>
            </th>

            {/* Actions Column Header */}
            <th style={{ width: displayConfig.actionsWidth || '5%' }} className={`th-end-u ${selectedCount > 0 ? " invisible": ''}`}></th>
        </tr>
        </thead>
    );
};

export default AudienceTableHeader;