import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { createFormDataValues } from '../../utils/utils';
import { apiUrl } from '../../utils/url';
import { hideLoader } from '../loader';
import ErrorMessage from '../errors/errorMessage';
import { useAlert } from '../../contexts/AlertContext';

function AffiliatePayoutModal({show, setShow, payoutValues, callData}) {
    const [error, setError] = useState({});
    const [values,setValues] = useState({paypal_email:null});
    const { setAlert } = useAlert();
    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    
    useEffect(()=>{
        if(payoutValues){
            setValues({...values,...payoutValues})
        }
    },[payoutValues])
    const confirmPayout = (e) => {
        e.preventDefault();
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        var formData = createFormDataValues(values)
        const api = apiUrl + `/affiliate/create-payout`;
        const setOptions = {
            method: "POST",
            headers: myHeaders,
            body:formData
        };
        fetch(api, setOptions)
        .then(async (res) => {
            const response = await res?.json();
            if (response.status_code == 200) {
                hideLoader();
                callData()
                hideModal()
                // toast.success(response?.status_message)
                setAlert({ type: 'success', title: response?.status_message });
            }else {
                setError(Object.values(response?.errors));
            }
        }).catch(() => {
            hideLoader();
          });
    }
    const hideModal = () => {
        setValues({
            paypal_email:null
        })
        setError([])
        setShow(null)
    };
  return (
    <Modal centered show={show} onHide={hideModal} className='affiliate-payout-modal' id="createLinkModal">
    <div className="modal-dialog-centered" role="document">
        <div className="modal-content">
            <div className="modal-header">
                    <span className='affiliate-payout-text'> Withdraw Earnings</span>
                    <button type="button" class="close-new font-12" onClick={hideModal}>
                        <span aria-hidden="true">
                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                        <path d="M9 1L1 9M1 1L9 9" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>Close
                        </span>   
                    </button>
            </div>
            <div className='affiliate-payout-price'>
                ${payoutValues?.amount}
            </div>
            <div className="modal-body">
                    <ErrorMessage error={error} />
                    <div className='affiliate-payout-heading'>Withdraw your earnings.</div>
                    <div className='affiliate-payout-text mt-10px'>Once approved, your payout will be sent. <br/> You’ll receive an email confirmation</div>
                    <div class="form-group mt-20px">
                        {payoutValues?.payout_type == 'cash'?
                            <>
                                <label className='productlabels mt-0' for="paypal_email">PayPal Account:</label>
                                <input type="email" value={values?.paypal_email} onChange={handleChange} id="paypal_email" name="paypal_email" class="zaapGlobal-input" placeholder="Your PayPal Email..."/>
                            </>
                            :
                            <>                           
                                <div>
                                    <span className='me-2'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.77778 0C1.69137 0 0 1.69137 0 3.77778V13.2222C0 15.3086 1.69137 17 3.77778 17H13.2222C15.3086 17 17 15.3086 17 13.2222V3.77778C17 1.69137 15.3086 0 13.2222 0H3.77778ZM13.0302 6.53033C13.3231 6.23744 13.3231 5.76256 13.0302 5.46967C12.7373 5.17678 12.2624 5.17678 11.9695 5.46967L6.99988 10.4393L5.03021 8.46967C4.73731 8.17678 4.26244 8.17678 3.96955 8.46967C3.67665 8.76256 3.67665 9.23744 3.96955 9.53033L6.46955 12.0303C6.76244 12.3232 7.23731 12.3232 7.53021 12.0303L13.0302 6.53033Z" fill="#1188FF"/>
                                        </svg>
                                    </span>
                                    <span className='affiliate-payout-text'>100% of your payout gets donated</span>
                                </div>
                                <div>
                                    <span className='me-2'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.77778 0C1.69137 0 0 1.69137 0 3.77778V13.2222C0 15.3086 1.69137 17 3.77778 17H13.2222C15.3086 17 17 15.3086 17 13.2222V3.77778C17 1.69137 15.3086 0 13.2222 0H3.77778ZM13.0302 6.53033C13.3231 6.23744 13.3231 5.76256 13.0302 5.46967C12.7373 5.17678 12.2624 5.17678 11.9695 5.46967L6.99988 10.4393L5.03021 8.46967C4.73731 8.17678 4.26244 8.17678 3.96955 8.46967C3.67665 8.76256 3.67665 9.23744 3.96955 9.53033L6.46955 12.0303C6.76244 12.3232 7.23731 12.3232 7.53021 12.0303L13.0302 6.53033Z" fill="#1188FF"/>
                                        </svg>
                                    </span>
                                    <span className='affiliate-payout-text'>We’ll email you the receipt </span>
                                </div>
                                <div>
                                    <span className='me-2'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.77778 0C1.69137 0 0 1.69137 0 3.77778V13.2222C0 15.3086 1.69137 17 3.77778 17H13.2222C15.3086 17 17 15.3086 17 13.2222V3.77778C17 1.69137 15.3086 0 13.2222 0H3.77778ZM13.0302 6.53033C13.3231 6.23744 13.3231 5.76256 13.0302 5.46967C12.7373 5.17678 12.2624 5.17678 11.9695 5.46967L6.99988 10.4393L5.03021 8.46967C4.73731 8.17678 4.26244 8.17678 3.96955 8.46967C3.67665 8.76256 3.67665 9.23744 3.96955 9.53033L6.46955 12.0303C6.76244 12.3232 7.23731 12.3232 7.53021 12.0303L13.0302 6.53033Z" fill="#1188FF"/>
                                        </svg>
                                    </span>
                                    <span className='affiliate-payout-text'>Learn more about Save the Children </span>
                                </div>
                            </>
 
                        }
                    </div>
            </div>
            <div className='modal-footer border-0 justify-content-start'>
                <button type='submit' onClick={confirmPayout} class="btn text-nowrap settings-connect-btn btn-primary btn-semi-rounded">
                    {payoutValues?.payout_type == 'cash'? 'Confirm Payout':'Yes, Donate'}
                </button>
                <button type="button" onClick={hideModal} class="btn-grey settings-button-primary">
                    {payoutValues?.payout_type == 'cash'? 'Cancel':'Not today'}
                </button>
            </div>
        </div>
    </div>
</Modal>
  )
}

export default AffiliatePayoutModal