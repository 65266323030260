import React from 'react';
import styles from "../../styles/Button.module.css";

const PrimaryButton = ({ className = '', children, onClick, size = 'medium', color = 'primary', icon ='', showText = true }) => {
    return (
        <button className={`${styles.button} ${styles[size]} ${styles[color]} ${className}`} onClick={onClick}>
            {icon ? <img src={icon} alt={'icon'} /> : ''}{showText ? children : ''}
        </button>
    );
};

export default PrimaryButton;
