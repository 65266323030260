import "../styles/community.css";
import chevronDownIcon from "../assets/img/modals/chevron-down.svg"
import chevron_down_greyIcon from "../assets/img/chevron-down-gray.svg"
import tickIcon from "../assets/img/tick.svg"
import tickCircleIcon from "../assets/img/tick-circle.svg"
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { useEffect, useState } from "react";
import * as $ from "jquery"
import NewsletterModal from "../components/newsletter/modal";
import { Route, useHistory, useLocation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import getNewsletter from "../api/getNewsletter";
import { useDispatch, useSelector } from "react-redux";
import { hideLoader } from "../components/loader";
import { newDateFormat, newTimeFormat } from "../utils/dateTimeFormatter";
import PaginationFooter from "../components/table/PaginationFooter";
import HeaderSearch from "../components/table/HeaderSearch";
import UpgradeModal from "../components/upgrade/upgrade-modal";
import NewsUpgradeModal from "../components/upgrade/news-upgrade-modal";
import dotsIcon from "../assets/img/dots.svg"
import { apiUrl, pageUrl, protocol } from "../utils/url";
import { ChevronRight } from "../utils/svg"
import Sidebar from "../components/layout/Sidebar";
import TeamModal from "../components/teammember/team_modal";
import ConfirmationModal from "../components/modals/confirmation-modal";
import EmptyState from "./empty-state";
import { useAlert } from "../contexts/AlertContext";
import ProjectModal from "../components/common/project-modal";

let params = [];
const Post = ({ id }) => {
    const [showModalasd, setShowModalasd] = useState(false)
    let location = useLocation();
    let history = useHistory();
    const dispatch = useDispatch();
    const [loading, setloading] = useState(true);
    const [showNewsletter, setShowNewsletter] = useState({asPosts:false,asBroadcasts:false});
    const { slug, basePath } = useSelector(state => state?.slugReducer);
    const isPro = useSelector(state => state?.proReducer)
    const [allNewsletter, setallNewsletter] = useState([]);
    const [showUpgradeModal, setshowUpgradeModal] = useState(false);
    const [filter, setfilter] = useState({ page: 1, orderBy: null, filter: null, orderType: "desc", count: localStorage?.posts_count ?? 10});
    const [showNewsUpgradeModal, setshowNewsUpgradeModal] = useState(false);
    const [allAudience, setallAudience] = useState([]);
    const [confirmationModalShow, setConfirmationModalShow] = useState({ show: false, text: null, deleteAll:false });
    const [isConfirmMessage, setisConfirmMessage] = useState(false)
    const { newsletterModalReducer, stripeConnectReducer } = useSelector(state => state);
    const [isEdit, setIsEdit] = useState({ is: false, id: null })
    const [selectAll, setSelectAll] = useState(false)
    const [isDuplicate, setIsDuplicate] = useState({ is: false, id: null })
    const newUser = useSelector(state => state?.newUserReducer);
    const pro = useSelector(state => state?.proReducer);
    const planType = useSelector(state => state?.plantypeReducer);
    const userData =  useSelector(state => state.userDataReducer);
    const [showEmailDisabledModal, setshowEmailDisabledModal] = useState({title: "Email Under Review.", desc:'Your use of Email is currently under review. A member of our support team will be in touch.',supportBtn: true, verifingEmail:false});
    const { setAlert } = useAlert();
    $(document).on('click', '.filter-dropdown-menu li a', function () {
        $('.filter-dropdown-menu li a').removeClass('active');
        $(this).addClass('active');
        $('.filter-dropdown-menu-selected').text($(this).attr('data-value'));
    });
    $(document).on('click', '.filter-dropdown-menu-channel li a', function () {
        $('.filter-dropdown-menu-channel li a').removeClass('active');
        $(this).addClass('active');
        $('.filter-dropdown-menu-selected-channel').text($(this).attr('data-value'));
    });

    useEffect(() => {
        if (location.pathname.includes("/newsletter/sendNewsletter")) {
            setShowNewsletter(true)

        }
        else if (location.pathname.includes("/newsletter")) {
            setShowNewsletter(false)
        }
    }, [location.pathname])

    useEffect(() => {
        if (!pro) {
            setShowModalasd(true);
        }else {
            setShowModalasd(false);
        params = [];
        if (slug) {
            for (const key in filter) {
                if (Object.hasOwnProperty.call(filter, key))
                    if (filter[key]) params.push(`${[key]}=${filter[key]}`)

            };
            if (filter?.page) callNewsletter()
        }
        }
    }, [slug, filter,pro,planType]);

    const callNewsletter = () => {
        getNewsletter(slug, params?.join("&"), "community/post").then((response) => {
            setloading(false)
            if(response?.status_message === 'Audience Limit Reached'){
                setShowModalasd(true);
            }
            else{
                setallNewsletter(response);
            }
            hideLoader()
        }).catch((err)=>{
            
            console.log('err',err);
        })
    }

    const filterToggler = (e, value) => {
        const isDesc = e.target.classList.contains("sortable-col-desc");
        setfilter({ ...filter, "orderBy": value, orderType: isDesc ? "asc" : "desc" });
        const imgElement = e.target.querySelector('img');
        if (imgElement) {
            e.target.classList.toggle('sortable-col-desc', !isDesc);
        }
    };

    const selectFilter = (e) => {
        const value = e.target.dataset?.value !== "All" ? e.target.dataset?.value?.toLowerCase() : null;
        setfilter({ ...filter, filter: value, page: 1 });

    }

    const showModal = () => {
        if (!isPro && allNewsletter?.counts?.audience > 100) {
            setshowNewsUpgradeModal(true);
            hideLoader()
            return
        }
        dispatch({
            type: "RESET_NEWSLETTER_MODAL"
        })
        history.push(basePath + "/posts/post")
        setTimeout(() => {
            setShowNewsletter({asPosts:true})
        }, 300);
    }

    const openUpgradeModal = (e) => {
        e.preventDefault();
        setshowUpgradeModal(true)
    }
    const closeUpgradeModal = () => setshowUpgradeModal(false);
    const closeNewsUpgradeModal = () => setshowNewsUpgradeModal(false);


    const duplicateBlock = (values) => {
        const api = apiUrl + "/community/newsletter/edit/" + values?.id;
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const setOptions = {
            method: "GET",
            headers: myHeaders,
        }
        dispatch({
            type: "SET_NEWSLETTER_MODAL", payload: {
                ...newsletterModalReducer,
                id: values?.id, sender_name: values?.sender_name, sender_email: values?.sender_email,
                subject: values?.subject, template_json: values?.template_json, scheduled_at: values?.scheduled_at,
                editorjs_template: values?.editorjs_template_json,cover_image: values?.cover_image,
                cover_image_data_url: values?.cover_image,
            }
        })


        setIsDuplicate({ is: true, id: values?.id })
        sessionStorage.setItem("isDuplicate", JSON.stringify(isDuplicate))

        history.push(basePath + "/posts/post")
        setTimeout(() => {
            setShowNewsletter({asPosts:true})
        }, 300);
    }
    const postAsBroadcasts = (values) => {
        if (!userData?.isEmailEnabled || (showEmailDisabledModal?.isEmailEnabled !== undefined && showEmailDisabledModal?.isEmailEnabled === false)) {
            setshowEmailDisabledModal({...showEmailDisabledModal, show:true});

        }else {
            verifyEmail();
            dispatch({
                type: "SET_NEWSLETTER_MODAL", payload: {
                    ...newsletterModalReducer,
                    id: values?.id, sender_name: values?.sender_name, sender_email: values?.sender_email,
                    subject: values?.subject, template_json: values?.template_json, scheduled_at: values?.scheduled_at,
                    editorjs_template: values?.editorjs_template_json,cover_image: values?.cover_image,
                    cover_image_data_url: values?.cover_image,
                }
            })
    
            setIsDuplicate({ is: true, id: values?.id })
            sessionStorage.setItem("isDuplicate", JSON.stringify(isDuplicate))
    
            setShowNewsletter({asBroadcasts:true})

        }
        
    }
    const verifyEmail = async () => {
        setshowEmailDisabledModal({...showEmailDisabledModal, verifingEmail: true});
        const api = apiUrl + "/community/newsletter/verify-email";
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        var requestOptions = {
            method: "GET",
            headers: myHeaders,
        };
        await fetch(api, requestOptions)
           .then(response => response.json())
           .then(result => {
                if (result.status_code === 200) {
                    setshowEmailDisabledModal({...showEmailDisabledModal,verifingEmail: false, isEmailEnabled: result?.data?.is_email_enabled});
                } else {
                    setAlert({
                        message: result.status_message,
                        type: "error"
                    });
                    hideLoader();
                }
            })
           .catch(error => {
                setAlert({
                    message: error.message,
                    type: "error"
                });
            });
    };
    const copyingText = (text) => navigator.clipboard.writeText(text).then((r => setAlert({ type: 'success', title: 'Successfully Copied'})))
    const handleCloseConfirmation = () => {
        setConfirmationModalShow({ show: false, text: null, id: null })
        setisConfirmMessage(true);
    }
    const confirmedDeleteBlock = (e) => {
        if (confirmationModalShow?.id) {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
            var requestOptions = {
            };
            var formData = new FormData();
            formData.append("from", "post");
            var api = `${apiUrl}/community/newsletter/`
            if (confirmationModalShow?.callBackTo === "delete") {
                requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formData,
                    redirect: 'follow'
                }
                api = `${apiUrl}/community/newsletter/delete/${confirmationModalShow?.id}`
            }
            else {
               
            }

            fetch(api, requestOptions)
                .then(async (res) => {

                    const response = await res?.json();
                    if (response?.status_code === 200) {
                        setAlert({ type: 'success', title: `Post Deleted Successfully` })
                        callNewsletter();
                    }
                    else {
                        setAlert({ type: 'error', title: response?.status_message })
                        hideLoader()
                    }
                    setConfirmationModalShow({ show: false, text: null, id: null, deleteAll: null  })
                    setisConfirmMessage(false)
                })
                .catch(() => {
                    setConfirmationModalShow({ show: false, text: null, id: null, deleteAll: null  })
                    setisConfirmMessage(false)
                    hideLoader()
                })
        }

    }

    const deleteAllPost = () => {
        const api = apiUrl + "/community/newsletter/delete-all/" + slug;
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        var formData = new FormData();
        formData.append("from", "post");
        const setOptions = {
            method: "POST",
            headers: myHeaders,
            body: formData,
        }
        fetch(api, setOptions)
                .then(async (res) => {
                    const response = await res?.json();
                    if (response?.status_code === 200) {
                        setAlert({ type: 'success', title: response?.status_message })
                        callNewsletter();
                    }
                    else {
                        setAlert({ type: 'error', title: response?.status_message })
                        hideLoader()
                    }
                    setConfirmationModalShow({ show: false, text: null, id: null, deleteAll: null  })
                    setisConfirmMessage(false)
                    setSelectAll(false)
                })
                .catch(() => {
                    setConfirmationModalShow({ show: false, text: null, id: null, deleteAll: null  })
                    setisConfirmMessage(false)
                    hideLoader()
                })
    }

    const editNewsletter = async (values) => {

        const api = apiUrl + "/community/newsletter/edit/" + values?.id;
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const setOptions = {
            method: "GET",
            headers: myHeaders,
        }
        dispatch({
            type: "SET_NEWSLETTER_MODAL", payload: {
                ...newsletterModalReducer,
                id: values?.id, sender_name: values?.sender_name, sender_email: values?.sender_email,
                subject: values?.subject, template_json: values?.template_json, scheduled_at: values?.scheduled_at,
                editorjs_template: values?.editorjs_template_json,
                cover_image: values?.cover_image,
                cover_image_data_url: values?.cover_image,
            }
        })
        setIsEdit({ is: true, id: values?.id })
        sessionStorage.setItem("isEdit", JSON.stringify(isEdit))
        setShowNewsletter({asPosts:true})
        }

    return (
        <>
            <div id="wrapper">
                <div class="community-main new-sidebar">
                    <Sidebar/>

                    <section class="community-section">
                    {showModalasd && <TeamModal showModal={showModalasd} heading={'Upgrade to access Posts.'} text={' Posts is a Pro Feature<br /> Upgrade to Zaap Pro to unlock this feature.'} isUltra={false}/>}

                        <div class="section-header sec-head-u">
                            <h1 class="mb-0">Posts</h1>
                            <div class="d-flex full-flex">
                                <HeaderSearch filter={filter} setfilter={setfilter} name="posts" />
                                <button className="btn btn-primary btn-semi-rounded import-btn align-items-center
                                        justify-content-center newbtn-u" id="btn-newNewsletterModal" onClick={showModal}>
                                    New Post
                                </button>
                            </div>
                        </div>
                        {!allNewsletter?.paginated?.data?.length > 0 && !showModalasd && (filter?.filter === 'All' || !filter?.filter) ?
                            <div class="section-main sec-main-u p-0 position-relative">
                                <EmptyState from={"posts"} firstText={'*Cricket Noises*'} secondText={"Start writing blog posts and get your thoughts out into the world."} buttonText={"Create Post"} clickedFunction={showModal}/>
                            </div>
                        :
                        <div class="section-main sec-main-u">
                            <div class="d-flex table-responsive" style={{overflow:"visible"}}>
                                <table className="table" border="0">
                                    <thead class="d-md-table-header-group dashview-u">
                                        <tr>
                                            <th className={`aud-leftborder thead-padd aud-text ${selectAll ? ' selected ':''} post-first-col-w`}>
                                            {allNewsletter?.paginated?.data?.length > 0 &&
                                            <label className="custom-checkbox checkbox">
                                                <input type="checkbox" checked={selectAll} name="select-all" onChange={(e) => {setSelectAll(e.target.checked)}}/>
                                                <span class="checkmark"></span>
                                            </label>
                                            }
                                            {selectAll ?
                                                <button className="delete-all-btn" onClick={() => setConfirmationModalShow({show: true, text: "Are you sure you want to delete All newsletters?", id: "", callBackTo: "delete", deleteAll: true})}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16" fill="none">
                                                        <path d="M8.77364 0.564453C9.54452 0.564453 10.1761 1.17108 10.2319 1.94053L10.2359 2.05151V3.53857H12.4293C12.8331 3.53857 13.1605 3.87146 13.1605 4.2821C13.1605 4.6634 12.8782 4.97767 12.5146 5.02062L12.4293 5.02562H12.3789L11.8418 12.6728C11.736 14.179 10.54 15.3569 9.07236 15.4313L8.92474 15.435H4.23572C2.75088 15.435 1.51038 14.3057 1.33282 12.8223L1.31862 12.6728L0.780849 5.02562H0.731137C0.327341 5.02562 0 4.69274 0 4.2821C0 3.90079 0.282235 3.58652 0.645858 3.54357L0.731137 3.53857H2.92455V2.05151C2.92455 1.26756 3.52104 0.625299 4.27767 0.568532L4.38682 0.564453H8.77364ZM10.9122 5.02562H2.24749L2.77719 12.5669C2.82639 13.2672 3.34705 13.8256 4.00996 13.9303L4.1217 13.9435L4.23572 13.948H8.92474C9.65355 13.948 10.2646 13.4038 10.3708 12.6822L10.3833 12.5669L10.9122 5.02562ZM5.11796 7.25621C5.49291 7.25621 5.80195 7.54324 5.84418 7.91303L5.84909 7.99974V10.9739C5.84909 11.3845 5.52175 11.7174 5.11796 11.7174C4.743 11.7174 4.43396 11.4304 4.39173 11.0606L4.38682 10.9739V7.99974C4.38682 7.5891 4.71416 7.25621 5.11796 7.25621ZM8.0425 7.25621C8.41745 7.25621 8.72649 7.54324 8.76873 7.91303L8.77364 7.99974V10.9739C8.77364 11.3845 8.4463 11.7174 8.0425 11.7174C7.66755 11.7174 7.35851 11.4304 7.31627 11.0606L7.31137 10.9739V7.99974C7.31137 7.5891 7.63871 7.25621 8.0425 7.25621ZM8.77364 2.05151H4.38682V3.53857H8.77364V2.05151Z" fill="#FF2020"/>
                                                    </svg>
                                                    <span>Delete All</span>
                                                </button>
                                                :
                                                'Post Title'
                                            }
                                            </th>
                                            <th className={`thead-padd d-tab-none ${selectAll ? " invisible": ''}`}>
                                                <div class="filter-dropdown dropdown-toggle noselect sort-u2 " id="filter-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <span class="">Status:</span>
                                                    <span class="filter-dropdown-menu-selected ms-2 font-weight-500">All</span>
                                                    <img src={chevronDownIcon} width="10px" alt=""
                                                        className="ms-1" />
                                                </div>

                                                <ul class="dropdown-menu filter-dropdown-menu" aria-labelledby="filter-dropdown">
                                                    <li>
                                                        <a className="dropdown-item active" data-value="All" onClick={selectFilter}>
                                                            <span class="me-5 d-flex align-items-center justify-content-betwen" data-value="All" onClick={selectFilter}>
                                                                <img src={tickIcon} alt="" />&nbsp;&nbsp;All</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a className="dropdown-item" data-value="Scheduled" onClick={selectFilter}>
                                                            <span class="me-5 d-flex align-items-center justify-content-betwen" data-value="Scheduled" onClick={selectFilter}>
                                                                <img src={tickIcon} alt="" data-value="Scheduled" onClick={selectFilter} />&nbsp;&nbsp;Scheduled</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a className="dropdown-item" data-value="Sent" onClick={selectFilter}>
                                                            <span class="me-5 d-flex align-items-center justify-content-betwen" data-value="Sent" onClick={selectFilter}>
                                                                <img src={tickIcon} alt="" data-value="Sent" onClick={selectFilter} />&nbsp;&nbsp;Published</span>
                                                        </a>
                                                    </li>
                                                </ul> 
                                            </th>
                                            <th className={`thead-padd resp d-none-u ${selectAll ? " invisible": ''}`} onClick={(e) => filterToggler(e, "date_sent")}>
                                                <span className="sort-u">
                                                    Date &nbsp;&nbsp;
                                                    <img src={chevron_down_greyIcon} alt="" />
                                                </span>
                                            </th>
                                            <th className={`thead-padd d-tab-none resp d-none-u ${selectAll ? " invisible": ''}`} onClick={(e) => filterToggler(e, "date_sent")}>
                                                <span className="sort-u">
                                                    Time&nbsp;&nbsp;
                                                    <img src={chevron_down_greyIcon} alt="" />
                                                </span>
                                            </th>
                                            <th className={`th-end-u ${selectAll ? " invisible": ''}`}></th> 
                                        </tr>
                                    </thead>
                                    {
                                        loading ?
                                    <tbody>

                                        <tr>
                                            <td className="d-md-table-cell">


                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                                <p class="mb-0 d-inline-flex align-items-center justify-content-start d-none">
                                                    <span class="d-flex align-items-start jusitfy-content-center flex-column">


                                                        <Skeleton height={40}
                                                            className="d-md-none"
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        />

                                                        <span class="d-md-none d-flex text-lightgray">
                                                            <span class="me-4">21 Sep</span>
                                                            <span class="me-4">1.2k Sends</span>
                                                            <span>556 Opens</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </td>
                                            <td className="d-md-table-cell d-none">

                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                            </td>
                                            <td className="d-md-table-cell d-none">
                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                            </td>
                                            <td className="d-md-table-cell d-none">
                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                            </td>

                                        </tr>

                                        <tr>
                                            <td className="d-md-table-cell">


                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                                <p class="mb-0 d-inline-flex align-items-center justify-content-start d-none">
                                                    <span class="d-flex align-items-start jusitfy-content-center flex-column">


                                                        <Skeleton height={40}
                                                            className="d-md-none"
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        />

                                                        <span class="d-md-none d-flex text-lightgray">
                                                            <span class="me-4">21 Sep</span>
                                                            <span class="me-4">1.2k Sends</span>
                                                            <span>556 Opens</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </td>
                                            <td className="d-md-table-cell d-none">

                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                            </td>
                                            <td className="d-md-table-cell d-none">
                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                            </td>
                                            <td className="d-md-table-cell d-none">
                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                            </td>

                                        </tr>

                                        <tr>
                                            <td className="d-md-table-cell">


                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                                <p class="mb-0 d-inline-flex align-items-center justify-content-start d-none">
                                                    <span class="d-flex align-items-start jusitfy-content-center flex-column">


                                                        <Skeleton height={40}
                                                            className="d-md-none"
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        />

                                                        <span class="d-md-none d-flex text-lightgray">
                                                            <span class="me-4">21 Sep</span>
                                                            <span class="me-4">1.2k Sends</span>
                                                            <span>556 Opens</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </td>
                                            <td className="d-md-table-cell d-none">

                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                            </td>
                                            <td className="d-md-table-cell d-none">
                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                            </td>
                                            <td className="d-md-table-cell d-none">
                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="d-md-table-cell">


                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                                <p class="mb-0 d-inline-flex align-items-center justify-content-start d-none">
                                                    <span class="d-flex align-items-start jusitfy-content-center flex-column">


                                                        <Skeleton height={40}
                                                            className="d-md-none"
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        />

                                                        <span class="d-md-none d-flex text-lightgray">
                                                            <span class="me-4">21 Sep</span>
                                                            <span class="me-4">1.2k Sends</span>
                                                            <span>556 Opens</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </td>
                                            <td className="d-md-table-cell d-none">

                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                            </td>
                                            <td className="d-md-table-cell d-none">
                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                            </td>
                                            <td className="d-md-table-cell d-none">
                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                            </td>

                                        </tr>

                                        <tr>
                                            <td className="d-md-table-cell">


                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                                <p class="mb-0 d-inline-flex align-items-center justify-content-start d-none">
                                                    <span class="d-flex align-items-start jusitfy-content-center flex-column">


                                                        <Skeleton height={40}
                                                            className="d-md-none"
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        />

                                                        <span class="d-md-none d-flex text-lightgray">
                                                            <span class="me-4">21 Sep</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </td>
                                            <td className="d-md-table-cell d-none">

                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                            </td>
                                            <td className="d-md-table-cell d-none">
                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                            </td>
                                            <td className="d-md-table-cell d-none">

                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="d-md-table-cell">


                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                                <p class="mb-0 d-inline-flex align-items-center justify-content-start d-none">
                                                    <span class="d-flex align-items-start jusitfy-content-center flex-column">


                                                        <Skeleton height={40}
                                                            className="d-md-none"
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        />

                                                        <span class="d-md-none d-flex text-lightgray">
                                                            <span class="me-4">21 Sep</span>
                                                            <span class="me-4">1.2k Sends</span>
                                                            <span>556 Opens</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </td>
                                            <td className="d-md-table-cell d-none">

                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                            </td>
                                            <td className="d-md-table-cell d-none">
                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                            </td>
                                            <td className="d-md-table-cell d-none">
                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                            </td>
                                           

                                        </tr>

                                        <tr>
                                            <td className="d-md-table-cell">


                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                                <p class="mb-0 d-inline-flex align-items-center justify-content-start d-none">
                                                    <span class="d-flex align-items-start jusitfy-content-center flex-column">


                                                        <Skeleton height={40}
                                                            className="d-md-none"
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        />

                                                        <span class="d-md-none d-flex text-lightgray">
                                                            <span class="me-4">21 Sep</span>
                                                            <span class="me-4">1.2k Sends</span>
                                                            <span>556 Opens</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </td>
                                            <td className="d-md-table-cell d-none">

                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                            </td>
                                            <td className="d-md-table-cell d-none">
                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                            </td>
                                            <td className="d-md-table-cell d-none">
                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                            </td>
                                        </tr>

                                    </tbody>
                                    :
                                    <tbody>
                                                {
                                                    allNewsletter?.paginated?.data?.length > 0 && (
                                                        allNewsletter?.paginated?.data?.map((val, index) => (
                                                            <tr key={index}>
                                                                <td>
                                                                    <p class="mb-0 d-inline-flex align-items-center justify-content-start" style={{paddingLeft:"28px"}}>
                                                                        <span class="d-flex align-items-start jusitfy-content-center flex-column">
                                                                            <span>{val?.subject}</span>
                                                                        </span>
                                                                    </p>
                                                                </td>
                                                                <td className="d-md-table-cell d-none">
                                                                    <span class="d-flex align-items-center">
                                                                        {val?.channel === 'post' ? (
                                                                            <><img src={tickCircleIcon} width="20px" alt="" />
                                                                            <span class="ms-2">{val?.status === 'Sent' ? 'Published':'Scheduled'}</span></>
                                                                        ) : 
                                                                        (
                                                                            <>
                                                                                <img src={tickCircleIcon} width="20px" alt="" />
                                                                                <span class="ms-2">{val?.status}</span>
                                                                            </>
                                                                        )
                                                                        }
                                                                    </span>
                                                                </td>
                                                                <td className="d-md-table-cell d-none">{newDateFormat(val?.scheduled_at)}</td>
                                                                <td className="d-md-table-cell d-tab-none d-none">
                                                                {newTimeFormat(val?.scheduled_at)}
                                                                </td>
                                                                <td>
                                                                    <div class="table-action-dropdown dropdown-toggle noselect d-flex align-items-center justify-content-end dropdown-subscribers" id="table-action-dropdown" data-bs-toggle="dropdown" aria-expanded="false" >
                                                                        <img src={dotsIcon} width="" alt="" />
                                                                    </div>
                                                                    <ul class="dropdown-menu" aria-labelledby="table-action-dropdown">
                                                                        <li><a class="dropdown-item" onClick={() => editNewsletter(val)}><span class="me-5">Edit Post</span></a></li>
                                                                        {val?.channel != "newsletter" ?  <li><a class="dropdown-item" onClick={() => copyingText(`${protocol + pageUrl}/${slug}/post/${val?.slug}`)} ><span class="me-5">Copy Link</span></a></li>:''}
                                                                        <li><a class="dropdown-item" onClick={() => postAsBroadcasts(val)}><span class="me-5">Send as Email </span></a></li>
                                                                        <li><a class="dropdown-item" onClick={() => duplicateBlock(val)}><span class="me-5">Duplicate </span></a></li>
                                                                        <li><a class="dropdown-item theme-active semi-bold" onClick={() =>
                                                                        setconfirmationModalShow({
                                                                            show: true, text: "Are you sure you want to delete this post?", id: val?.id, callBackTo: "delete"
                                                                        })} ><span class="me-5">Delete Post</span></a></li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    )}
                                            </tbody>
                                    }
                                </table>
                            </div>


                            <div class="section-footer d-flex align-items-center justify-content-between">
                                <PaginationFooter from={"posts"} loading={loading} filter={filter} setfilter={setfilter} total={allNewsletter?.paginated?.total} last_page={allNewsletter?.paginated?.last_page} current_page={allNewsletter?.paginated?.current_page}/>
                            </div>

                        </div>
                        }
                    </section>

                </div>

            </div>
            {showNewsletter?.asPosts && <Route path={basePath + "/post/:addPost"} children={() => <NewsletterModal showEmailDisabledModal={showEmailDisabledModal} setshowEmailDisabledModal={setshowEmailDisabledModal} from={"post"} basePath={basePath} show={showNewsletter}
                setShow={setShowNewsletter} id={id} callNewsletter={callNewsletter} edit={isEdit} setIsEdit={setIsEdit} duplicate={isDuplicate} setIsDuplicate={setIsDuplicate} />}
            />}
            {showNewsletter?.asBroadcasts && <Route path={basePath + "/newsletter/:addNewsLetter"} children={() => <NewsletterModal showEmailDisabledModal={showEmailDisabledModal} setshowEmailDisabledModal={setshowEmailDisabledModal} from={"newsletter"} basePath={basePath} show={showNewsletter}
                setShow={setShowNewsletter} id={id} callNewsletter={callNewsletter} edit={isEdit} setIsEdit={setIsEdit} duplicate={isDuplicate} setIsDuplicate={setIsDuplicate} />}
            />}
            {showEmailDisabledModal && <ProjectModal show={showEmailDisabledModal} onHide={() => setshowEmailDisabledModal({...showEmailDisabledModal, show:false})} />}
            {showUpgradeModal && <UpgradeModal isOpen={showUpgradeModal} onHide={closeUpgradeModal} />}
            {showNewsUpgradeModal && <NewsUpgradeModal isOpen={showNewsUpgradeModal} onHide={closeNewsUpgradeModal} />}
            {confirmationModalShow?.show &&
                <ConfirmationModal confirmationModalShow={confirmationModalShow} setShow={setConfirmationModalShow} setisConfirmMessage={setisConfirmMessage} callback={confirmationModalShow?.deleteAll ? deleteAllPost : confirmedDeleteBlock} />
            }
        </>
    )
}
export default Post