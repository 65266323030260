const dateTimeFormatter = (date_to_set, only_date_month, isSlash) => {
    var options = {year: 'numeric', month: 'long', day: 'numeric'};

    const date = new Date(date_to_set);
    var strArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var d = date.getDate();
    var m = strArray[date.getMonth()];
    var y = date.getFullYear();
    var t = date.toLocaleTimeString()
    if (only_date_month)
        return '' + (d <= 9 ? '0' + d : d) + '-' + m;
    if (isSlash)
        return '' + eval(date.getMonth() + 1) + '/' + (d <= 9 ? '0' + d : d) + '/' + +y;
    return '' + (d <= 9 ? '0' + d : d) + '-' + m + '-' + y + " " + t;

}
export default dateTimeFormatter
export const newDateFormat = (scheduledAt) => {

    var dateTime = new Date(scheduledAt);

    var optionsDate = {day: 'numeric', month: 'short', year: 'numeric'};
    var formattedDate = dateTime.toLocaleDateString('en-US', optionsDate);

    return formattedDate;
}
export const setDateFormatWithSuffix = (date) => {
    const formattedDate = new Date(date).toLocaleString('en-US', {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
    });

    // Split the formatted date to add the ordinal suffix
    const [month, day, year] = formattedDate.replace(',', '').split(' ');

    // Add ordinal suffix (st, nd, rd, th)
    const dayNum = parseInt(day);
    let suffix = 'th';
    // console.log("dayNum % 10",dayNum % 10)
    if (dayNum % 10 === 1 && dayNum !== 11) {
        suffix = 'st';
    } else if (dayNum % 10 === 2 && dayNum !== 12) {
        suffix = 'nd';
    } else if (dayNum % 10 === 3 && dayNum !== 13) {
        suffix = 'rd';
    }

    // Combine everything back together
    return `${dayNum}${suffix} ${month} ${year}`;
}
export const newTimeFormat = (scheduledAt) => {

    var dateTime = new Date(scheduledAt);

    var optionsTime = {hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true};
    var formattedTime = dateTime.toLocaleTimeString('en-US', optionsTime);
    return formattedTime;
}
export const secondsToMins = (value) => {
    if (value > 60) {

        const minutes = Math.floor(value / 60); // Convert back to minutes
        const seconds = value % 60; // Get remaining seconds
        return `${minutes}m ${seconds}s`;
    }
    return value + 's';
}